const prices = {
  individual: {
    pro: {
      monthly: process.env.REACT_APP_INDIVIDUAL_MONTHLY_PRO_PRICE_ID,
      yearly: process.env.REACT_APP_INDIVIDUAL_YEARLY_PRO_PRICE_ID,
    },
    proPlus: {
      monthly: process.env.REACT_APP_INDIVIDUAL_MONTHLY_PRO_PLUS_PRICE_ID,
      yearly: process.env.REACT_APP_INDIVIDUAL_YEARLY_PRO_PLUS_PRICE_ID,
    },
  },
  business: {
    pro: {
      monthly: process.env.REACT_APP_BUSINESS_MONTHLY_PRO_PRICE_ID,
      yearly: process.env.REACT_APP_BUSINESS_YEARLY_PRO_PRICE_ID,
    },
    proPlus: {
      monthly: process.env.REACT_APP_BUSINESS_MONTHLY_PRO_PLUS_PRICE_ID,
      yearly: process.env.REACT_APP_BUSINESS_YEARLY_PRO_PLUS_PRICE_ID,
    },
  },
};

export const getPriceId = (type, plan, frequency) => {
  return prices[type][plan][frequency];
};
