import React, { useEffect, useState } from "react";
import Input from "../../../../components/Input";
import {
  getCompaniesNames,
  getCompanyByUrl,
} from "../../../../../redux/api/company.api";
import { FiBriefcase, FiGlobe } from "react-icons/fi";
import Select from "react-select";
import {
  companyExistsConfirmation,
  confirmation,
} from "../../../../../utils/notification";
import { RotatingLines } from "react-loader-spinner";

const CompanyInformation = ({
  formData,
  setFormData,
  handleChange,
  handleNext,
}) => {
  const [checkCompanyLoading, setCheckCompanyLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setCheckCompanyLoading(true);
    try {
      const res = await getCompanyByUrl(formData?.website);
      if (res.status === 200) {
        await companyExistsConfirmation(
          `Company Name: ${res?.data?.name} | Website: ${res?.data?.website}`,
          "Do you want to proceed with this company?"
        ).then((response) => {
          if (response.isConfirmed) {
            setFormData({ ...formData, company: res?.data?._id });
            handleNext();
          } else {
            setFormData({
              ...formData,
              companyName: formData?.companyName,
              website: formData?.website,
            });
            handleNext();
            setCheckCompanyLoading(false);
          }
        });
      } else {
        handleNext();
      }
    } catch (error) {
      handleNext();
    }
    setCheckCompanyLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <>
        {/* <Input
          label={"Company Name"}
          type={"text"}
          name={"companyName"}
          value={formData?.companyName}
          onChange={handleChange}
          placeholder="Company Name"
        /> */}
        <div>
          <label
            htmlFor="companyName"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Company Name
          </label>
          <div className="relative mt-1.5 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <FiBriefcase
                className="h-5 w-5 text-gray-400 z-50"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              name="companyName"
              id="companyName"
              className="mb-3 outline-0 py-3 bg-gray-50 block w-full rounded-md border border-gray-100 focus:border-0 py-1.5 pl-10 text-gray-900 ring-0 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              value={formData?.companyName}
              onChange={handleChange}
              placeholder="Company Name"
              required
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="website"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Company Website
          </label>
          <div className="relative mt-1.5 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <FiGlobe
                className="h-5 w-5 text-gray-400 z-50"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              name="website"
              id="website"
              className="mb-3 outline-0 py-3 bg-gray-50 block w-full rounded-md border border-gray-100 focus:border-0 py-1.5 pl-10 text-gray-900 ring-0 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              value={formData?.website}
              onChange={handleChange}
              placeholder="Company Website"
              required
            />
          </div>
        </div>
      </>
      <div className="mt-10 flex flex-col items-center justify-center w-full">
        {/* <button
            type="button"
            className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            onClick={() => handleBack()}
          >
            Back
          </button> */}
        <button
          type="submit"
          disabled={checkCompanyLoading}
          className="rounded-full w-48 flex justify-center items-center px-10 py-2.5 font-bold text-white text-base bg-gradient-to-r from-[#D88A16] to-[#FFB800]"
        >
          {checkCompanyLoading ? (
            <RotatingLines
              strokeColor="green"
              strokeWidth="5"
              animationDuration="0.75"
              width="24"
              visible={true}
            />
          ) : (
            "Next"
          )}
        </button>
      </div>
    </form>
  );
};

export default CompanyInformation;
