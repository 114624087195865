import { API } from "./api";

export const getOptionSets = () => API.get("/optionSets");

export const createOptionSet = (newOptionSet) =>
  API.post("/optionSets", newOptionSet);

export const updateOptionSet = (id, updatedOptionSet) =>
  API.patch(`/optionSets/${id}`, updatedOptionSet);

export const deleteOptionSet = (id) => API.delete(`/optionSets/${id}`);
