import Swal from "sweetalert2";

export const success = (title, message) => {
  Swal.fire({
    title: `${title}!`,
    confirmButtonColor: "#2faf4d",
    text: `${message}`,
    icon: "success",
  });
};

export const loginSuccess = () => {
  Swal.fire({
    title: `Success!`,
    confirmButtonColor: "#2faf4d",
    text: `Login Successfully`,
    icon: "success",
  });
};

export const warning = (message) => {
  Swal.fire({
    title: "Warning!",
    confirmButtonColor: "#2faf4d",
    text: message,
    icon: "warning",
  });
};

export const failure = (message) => {
  Swal.fire({
    title: "Error!",
    confirmButtonColor: "#2faf4d",
    text: message,
    icon: "error",
  });
};

export const confirmation = (
  title = "Are you sure?",
  text = "You won't be able to undo this!"
) =>
  Swal.fire({
    title: title,
    text: text,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#2faf4d",
    cancelButtonColor: "#d33",
    confirmButtonText: "Delete",
  });

export const companyExistsConfirmation = (title, text) =>
  Swal.fire({
    title: title,
    text: text,
    icon: "success",
    showCancelButton: true,
    confirmButtonColor: "#2faf4d",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
  });
