import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import {
  activateSubscription,
  cancelSubscription,
  deletePaymentMethod,
  doNotCancelSubscription,
  getCustomerInvoices,
  getPaymentMethodsForCustomer,
  getSubscription,
  updateSubscriptionPlan,
} from "../../../redux/api/payment.api";
import { useSelector } from "react-redux";
import UpdatePaymentMethodPopup from "./UpdatePaymentMethodPopup";
import UpdateSubscriptionPlanPopup from "./UpdateSubscriptionPlanPopup";
import { getPriceId } from "../../../utils/subscriptions";
import CardIcon from "./Components/CardIcon";
import { confirmation, failure, success } from "../../../utils/notification";
import ChoosePaymentMethodPopup from "./ChoosePaymentMethodPopup";
import { RiDeleteBinLine } from "react-icons/ri";
import { CheckIcon } from "@heroicons/react/20/solid";
import bgImg from "../../../assets/images/Mask group (1).png";
import { GoPerson } from "react-icons/go";
import PlanTier from "./Components/PlanTier";

const Billing = () => {
  const auth = useSelector((state) => state.authReducer);

  const columns = [
    {
      header: "Date",
      render: (invoice) =>
        `${new Date(invoice?.created * 1000).toLocaleDateString("en-US")}`,
    },
    {
      header: "Amount Due",
      render: (invoice) => `$${(invoice?.amount_due / 100).toFixed(2)}`,
    },
    {
      header: "Status",
      render: (invoice) => {
        if (invoice?.status === "paid") {
          return (
            <div className="flex items-center">
              <CheckIcon className="h-5 w-5 text-green-500" />
              <span className="ml-2 text-green-500">Paid</span>
            </div>
          );
        } else if (invoice?.status === "open") {
          return (
            <div className="flex items-center">
              <span className="text-yellow-500">Open</span>
            </div>
          );
        } else {
          return (
            <div className="flex items-center">
              <span className="text-red-500">Unpaid</span>
            </div>
          );
        }
      },
    },
    {
      header: "Plan",
      render: (invoice) => invoice?.subscription,
    },
    {
      header: "Receipt",
      render: (invoice) => (
        <button
          type="button"
          className="rounded-lg bg-transparent px-5 py-1.5 font-bold text-sm text-gray-800 text-opacity-80 shadow-sm border border-gray-200"
          onClick={() => {
            window.open(invoice?.invoice_pdf, "_blank");
          }}
        >
          Download
        </button>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("monthly");
  const [invoices, setInvoices] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [subscribe, setSubscribe] = useState(false);
  const [newPlan, setNewPlan] = useState(null);
  const [newPlanPriceId, setNewPlanPriceId] = useState(null);
  const [newPlanFrequency, setNewPlanFrequency] = useState(null);
  const [paymentMethodPopupOpen, setPaymentMethodPopupOpen] = useState(false);
  const [updateSubscriptionPlanPopupOpen, setUpdateSubscriptionPlanPopupOpen] =
    useState(false);
  const [choosePaymentMethodPopupOpen, setChoosePaymentMethodPopupOpen] =
    useState(false);
  const [togglePlan, setTogglePlan] = useState(false);
  const [invoicePage, setInvoicePage] = useState(1);
  const [hasMoreInvoices, setHasMoreInvoices] = useState(true);

  useEffect(() => {
    if (auth?.user?.stripeCustomerId) {
      loadBillingData();
    }
  }, [auth?.user]);

  const loadBillingData = async () => {
    console.log("Loading billing data", auth?.user?.stripeSubscriptionId);
    setLoading(true);

    try {
      // Fetch invoices and payment methods
      const [initialInvoices, paymentMethodsData] = await Promise.all([
        getCustomerInvoices(auth?.user?.stripeCustomerId),
        getPaymentMethodsForCustomer(auth?.user?.stripeCustomerId),
      ]);

      setInvoices(
        initialInvoices.data.filter((invoice) => invoice.status !== "draft")
      );
      setPaymentMethods(paymentMethodsData.data);
      setHasMoreInvoices(initialInvoices.has_more);

      // Fetch subscription only if stripeSubscriptionId exists
      if (auth?.user?.stripeSubscriptionId) {
        const subscriptionData = await getSubscription(
          auth?.user?.stripeSubscriptionId
        );
        setSubscription(subscriptionData);
      } else {
        setSubscription(null); // Clear subscription if no ID
      }
    } catch (error) {
      failure("Error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreInvoices = async () => {
    const nextPage = invoicePage + 1;
    try {
      const additionalInvoices = await getCustomerInvoices(
        auth?.user?.stripeCustomerId,
        nextPage
      );
      setInvoices((prev) => [...prev, ...additionalInvoices.data]);
      setInvoicePage(nextPage);
      setHasMoreInvoices(additionalInvoices.length > 0);
    } catch (error) {
      console.error("Error loading more invoices:", error);
    }
  };

  const handleCancelSubscription = async () => {
    await cancelSubscription(auth?.user?.stripeSubscriptionId)
      .then((res) => {
        setSubscription(res);
        success("Success", "Subscription Set to Cancelled at Period End");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDoNotCancelSubscription = async () => {
    await doNotCancelSubscription(auth?.user?.stripeSubscriptionId)
      .then((res) => {
        setSubscription(res);
        success("Subscription Set to Not Cancelled");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleActivateSubscription = async () => {
    setSubscribe(true);
    if (paymentMethods.length === 0) {
      setPaymentMethodPopupOpen(true);
      return;
    }
    setChoosePaymentMethodPopupOpen(true);
  };

  const handleDeletePaymentMethod = (id) => {
    confirmation().then(async (result) => {
      if (result.isConfirmed) {
        await deletePaymentMethod(id)
          .then((res) => {
            setPaymentMethods(paymentMethods.filter((pm) => pm.id !== id));
            success("Success!", "Card Details deleted successfully!");
          })
          .catch((err) => {
            console.log(err);
            failure("Error!", "Error deleting card details");
          });
      }
    });
  };

  const handleUpdateSubscriptionPlan = async (href, newPriceId) => {
    const urlParams = new URLSearchParams(href.split("?")[1]);
    const newPlan = urlParams.get("plan");
    const type = auth?.user?.type;
    const customerId = auth?.user?.stripeCustomerId;
    if (auth?.user?.plan === "basic") {
      setNewPlanPriceId(newPriceId);
      handleActivateSubscription();
    } else {
      await updateSubscriptionPlan(customerId, newPriceId, newPlan)
        .then((res) => {
          console.log(res);
          success("Success!", "Subscription plan updated successfully!");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          failure("Error!", "Error updating subscription plan");
        });
    }
  };

  const individualYearly = [
    {
      name: "Basic",
      id: "ind-basic-y",
      href: "/register?type=individual&plan=basic",
      price: "Free",
      priceSuffix: "",
      description:
        "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
      features: [
        "25 Projects",
        "1 Industry",
        "3 Saved Maps",
        "Personalized Email support.",
      ],
      mostPopular: false,
      icon: GoPerson,
      button: "Sign Up",
    },
    {
      name: "Pro",
      id: "ind-pro-y",
      href: "/register?type=individual&plan=pro&frequency=yearly",
      price: "$399",
      priceSuffix: "/Year",
      description:
        "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
      features: [
        "50 Projects",
        "3 Industries",
        "5 Saved Maps",
        "Personalized Email support.",
        "Custom pins & project cards ",
        "Embed maps on website / landing pages",
        "One month free trial",
      ],
      mostPopular: false,
      icon: GoPerson,
      button: "Start Free Trial",
    },
    {
      name: "Pro Plus",
      id: "ind-pro-plus-y",
      href: "/register?type=individual&plan=pro-plus&frequency=yearly",
      price: "$799",
      priceSuffix: "/Year",
      description:
        "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
      features: [
        "Unlimited Projects",
        "Unlimited Industries",
        "Unlimited Saved Maps",
        "Personalized Email support.",
        "Custom pins & project cards ",
        "Embed maps on website / landing pages",
        "One month free trial",
      ],
      mostPopular: false,
      icon: GoPerson,
      button: "Start Free Trial",
    },
  ];
  const individualMonthly = [
    {
      name: "Basic",
      id: "ind-basic-m",
      href: "/register?type=individual&plan=basic",
      price: "Free",
      priceSuffix: "",
      description:
        "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
      features: [
        "25 Projects",
        "1 Industry",
        "3 Saved Maps",
        "Personalized Email support.",
      ],
      mostPopular: false,
      icon: GoPerson,
      button: "Sign Up",
    },
    {
      name: "Pro",
      id: "ind-pro-m",
      href: "/register?type=individual&plan=pro&frequency=monthly",
      price: "$39",
      priceSuffix: "/Month",
      description:
        "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
      features: [
        "50 Projects",
        "3 Industries",
        "5 Saved Maps",
        "Personalized Email support.",
        "Custom pins & project cards ",
        "Embed maps on website / landing pages",
        "One month free trial",
      ],
      mostPopular: false,
      icon: GoPerson,
      button: "Start Free Trial",
    },
    {
      name: "Pro Plus",
      id: "ind-pro-plus-m",
      href: "/register?type=individual&plan=pro-plus&frequency=monthly",
      price: "$79",
      priceSuffix: "/Month",
      description:
        "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
      features: [
        "Unlimited Projects",
        "Unlimited Industries",
        "Unlimited Saved Maps",
        "Personalized Email support.",
        "Custom pins & project cards ",
        "Embed maps on website / landing pages",
        "One month free trial",
      ],
      mostPopular: false,
      icon: GoPerson,
      button: "Start Free Trial",
    },
  ];
  const businessYearly = [
    {
      name: "Basic",
      id: "bus-basic-y",
      href: "/register?type=business&plan=basic",
      price: "Free",
      priceSuffix: "",
      description:
        "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
      features: [
        "25 Projects",
        "1 Industry",
        "1 Saved Maps",
        "1 Office Location",
        "Personalized Email support.",
        "1 User",
      ],
      mostPopular: false,
      icon: GoPerson,
      button: "Sign Up",
    },
    {
      name: "Pro",
      id: "bus-pro-y",
      href: "/register?type=business&plan=pro&frequency=yearly",
      price: "$899", // changed from $499
      priceSuffix: "/Year",
      description:
        "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
      features: [
        "50 Projects",
        "3 Industries",
        "5 Saved Maps",
        "3 Office Locations",
        "Personalized Email support.",
        // "Custom pins & project cards ",
        "Embed maps on website / landing pages",
        "5 Users",
        "One month free trial",
      ],
      mostPopular: false,
      icon: GoPerson,
      button: "Start Free Trial",
    },
    {
      name: "Pro Plus",
      id: "bus-pro-plus-y",
      href: "/register?type=business&plan=pro-plus&frequency=yearly",
      price: "$1299", // changed from $999
      priceSuffix: "/Year",
      description:
        "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
      features: [
        "Unlimited Projects",
        "Unlimited Industries",
        "Unlimited Saved Maps",
        "Unlimited Office Locations",
        "Personalized Email support.",
        "Custom pins & project cards ",
        "Embed maps on website / landing pages",
        "Unlimited Users",
        "One month free trial",
      ],
      mostPopular: false,
      icon: GoPerson,
      button: "Start Free Trial",
    },
  ];
  const businessMonthly = [
    {
      name: "Basic",
      id: "bus-basic-m",
      href: "/register?type=business&plan=basic",
      price: "Free",
      priceSuffix: "",
      description:
        "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
      features: [
        "25 Projects",
        "1 Industry",
        "1 Saved Maps",
        "1 Office Location",
        "Personalized Email support.",
        "1 User",
      ],
      mostPopular: false,
      icon: GoPerson,
      button: "Sign Up",
    },
    {
      name: "Pro",
      id: "bus-pro-m",
      href: "/register?type=business&plan=pro&frequency=monthly",
      price: "$89", // changed from $49
      priceSuffix: "/Month",
      description:
        "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
      features: [
        "50 Projects",
        "3 Industries",
        "5 Saved Maps",
        "3 Office Locations",
        "Personalized Email support.",
        // "Custom pins & project cards ",
        "Embed maps on website / landing pages",
        "5 Users",
        "One month free trial",
      ],
      mostPopular: false,
      icon: GoPerson,
      button: "Start Free Trial",
    },
    {
      name: "Pro Plus",
      id: "bus-pro-plus-m",
      href: "/register?type=business&plan=pro-plus&frequency=monthly",
      price: "$129", // changed from $99
      priceSuffix: "/Month",
      description:
        "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
      features: [
        "Unlimited Projects",
        "Unlimited Industries",
        "Unlimited Saved Maps",
        "Unlimited Office Locations",
        "Personalized Email support.",
        "Custom pins & project cards ",
        "Embed maps on website / landing pages",
        "Unlimited Users",
        "One month free trial",
      ],
      mostPopular: false,
      icon: GoPerson,
      button: "Start Free Trial",
    },
  ];

  function camelize(str) {
    return str
      ?.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      ?.replace(/\s+/g, "");
  }
  function convertToTitleCase(str) {
    return str
      ?.split("-") // Split the string by hyphens
      ?.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      ) // Capitalize the first letter of each word
      ?.join(" "); // Join the words back with spaces
  }

  return (
    <>
      <UpdatePaymentMethodPopup
        open={paymentMethodPopupOpen}
        setOpen={setPaymentMethodPopupOpen}
        setPaymentMethods={setPaymentMethods}
        getBillingData={loadBillingData}
        subscribe={subscribe}
        plan={newPlan}
        frequency={newPlanFrequency}
        priceId={newPlanPriceId}
      />
      <ChoosePaymentMethodPopup
        open={choosePaymentMethodPopupOpen}
        setOpen={setChoosePaymentMethodPopupOpen}
        paymentMethods={paymentMethods}
        getBillingData={loadBillingData}
      />
      <UpdateSubscriptionPlanPopup
        open={updateSubscriptionPlanPopupOpen}
        setOpen={setUpdateSubscriptionPlanPopupOpen}
      />
      <section className="p-5">
        <h1 className="text-3xl pb-2 font-extrabold text-gray-700">
          Plans & Billing
        </h1>
        {(auth?.user?.plan === "basic" || togglePlan) && (
          <>
            <div className="flex justify-center">
              <div className="flex -space-x-10 mb-4">
                <button
                  className={`className="mt-5 shrink-0 rounded-full px-14 lg:px-20 py-2.5 font-bold text-base " ${
                    activeTab === "yearly"
                      ? "z-40 text-white bg-gradient-to-r from-[#0BAC59] to-[#044624]"
                      : "bg-white border border-green-600 text-green-600"
                  }`}
                  onClick={() => setActiveTab("yearly")}
                >
                  Yearly
                </button>
                <button
                  className={`className="mt-5 shrink-0 rounded-full px-14 lg:px-20 py-2.5 font-bold text-base" ${
                    activeTab === "monthly"
                      ? "z-40 text-white bg-gradient-to-r from-[#0BAC59] to-[#044624]"
                      : "bg-white border border-green-600 text-green-600"
                  }`}
                  onClick={() => setActiveTab("monthly")}
                >
                  Monthly
                </button>
              </div>
            </div>
            <div className="isolate mx-auto mt-4 mb-8 grid gap-8 lg:mx-auto grid-cols-12">
              {auth?.user?.type === "individual"
                ? activeTab === "monthly"
                  ? individualMonthly.map((tier, index) => (
                      <PlanTier
                        key={index}
                        tier={tier}
                        getPriceId={getPriceId}
                        handleUpdateSubscriptionPlan={
                          handleUpdateSubscriptionPlan
                        }
                        plan={auth?.user?.plan}
                        auth={auth}
                        subscription={subscription}
                        camelize={camelize}
                        setNewPlan={setNewPlan}
                        setNewPlanFrequency={setNewPlanFrequency}
                      />
                    ))
                  : individualYearly.map((tier, index) => (
                      <PlanTier
                        key={index}
                        tier={tier}
                        getPriceId={getPriceId}
                        handleUpdateSubscriptionPlan={
                          handleUpdateSubscriptionPlan
                        }
                        plan={auth?.user?.plan}
                        auth={auth}
                        subscription={subscription}
                        camelize={camelize}
                        setNewPlan={setNewPlan}
                        setNewPlanFrequency={setNewPlanFrequency}
                      />
                    ))
                : activeTab === "monthly"
                ? businessMonthly.map((tier, index) => (
                    <PlanTier
                      key={index}
                      tier={tier}
                      getPriceId={getPriceId}
                      handleUpdateSubscriptionPlan={
                        handleUpdateSubscriptionPlan
                      }
                      plan={auth?.user?.plan}
                      auth={auth}
                      subscription={subscription}
                      camelize={camelize}
                      setNewPlan={setNewPlan}
                      setNewPlanFrequency={setNewPlanFrequency}
                    />
                  ))
                : businessYearly.map((tier, index) => (
                    <PlanTier
                      key={index}
                      tier={tier}
                      getPriceId={getPriceId}
                      handleUpdateSubscriptionPlan={
                        handleUpdateSubscriptionPlan
                      }
                      plan={auth?.user?.plan}
                      auth={auth}
                      camelize={camelize}
                      setNewPlan={setNewPlan}
                      setNewPlanFrequency={setNewPlanFrequency}
                    />
                  ))}
            </div>
          </>
        )}
        {auth?.user?.plan !== "basic" && (
          <div className="lg:mx-auto">
            <div className="gap-5 grid grid-cols-1 sm:grid-cols-12 lg:grid-cols-12 md:grid-cols-12">
              {/* Left column */}
              <div
                className={`lg:col-span-12 md:col-span-12 sm:col-span-12 ${
                  auth?.user?.plan !== "basic" ? "lg:col-span-8" : ""
                }`}
              >
                <h2 className="text-base font-extrabold text-gray-700">
                  Order History
                </h2>
                <Table pagination={false} columns={columns} data={invoices} />
                <button
                  type="button"
                  className="underline my-3 rounded-lg bg-transparent font-bold text-base text-green-700 text-opacity-80"
                  onClick={loadMoreInvoices}
                  disabled={!hasMoreInvoices}
                >
                  {hasMoreInvoices ? "Load more" : "No more invoices"}
                </button>
              </div>
              {/* Right Column  */}
              <div className="lg:col-span-4 md:col-span-12 sm:col-span-12">
                <div className="p-7 rounded-2xl md:mr-auto lg:ml-auto bg-green-700 bg-opacity-90 w-64">
                  <p className="font-semibold pb-4 text-gray-50 text-base">
                    Your Plan
                  </p>
                  <h3 className="pb-2 text-gray-50 text-2xl font-extrabold">
                    {convertToTitleCase(auth?.user?.plan)}
                  </h3>
                  {subscription ? (
                    <>
                      <p className="font-semibold text-gray-50 text-xs">
                        {subscription?.status === "canceled"
                          ? "Subscription Canceled"
                          : subscription?.cancel_at_period_end
                          ? "Your subscription is set to cancel at the end of the billing period"
                          : subscription?.status === "trialing"
                          ? `Trial Ends on ${new Date(
                              subscription?.current_period_end * 1000
                            ).toLocaleDateString("en-US")}`
                          : subscription?.status === "active" &&
                            `Renews on ${new Date(
                              subscription?.current_period_end * 1000
                            ).toLocaleDateString("en-US")}`}
                      </p>
                      {!subscription?.cancel_at_period_end ? (
                        <button
                          type="button"
                          onClick={
                            auth?.user?.isActive
                              ? handleCancelSubscription
                              : handleActivateSubscription
                          }
                          className="my-3 rounded-lg bg-transparent px-5 py-1.5 font-bold text-sm text-gray-50 text-opacity-80 shadow-sm border border-gray-50 border-opacity-40"
                        >
                          {auth?.user?.isActive
                            ? "Cancel Subscription"
                            : "Activate Subscription"}
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={handleDoNotCancelSubscription}
                          className="my-3 rounded-lg bg-transparent px-5 py-1.5 font-bold text-sm text-gray-50 text-opacity-80 shadow-sm border border-gray-50 border-opacity-40"
                        >
                          Don't Cancel
                        </button>
                      )}
                    </>
                  ) : (
                    <p className="font-semibold text-gray-50 text-xs">
                      No subscription details available.
                    </p>
                  )}
                  <button
                    type="button"
                    onClick={() => setTogglePlan(!togglePlan)}
                    className="mt-3 rounded-lg bg-transparent px-5 py-1.5 font-bold text-sm text-gray-50 text-opacity-80 shadow-sm border border-gray-50 border-opacity-40"
                  >
                    {togglePlan ? "Hide Plans" : "Update Plan"}
                  </button>
                </div>
              </div>
            </div>

            {/* Conditionally Render Payment Methods */}
            {auth?.user?.plan !== "basic" && (
              <div className="mt-20 lg:w-2/3 md:w-full sm:w-full">
                <div className="flex justify-between items-start">
                  <div>
                    <h2 className="text-base font-extrabold text-gray-700">
                      Payment Methods
                    </h2>
                  </div>
                  <button
                    type="button"
                    onClick={() => setPaymentMethodPopupOpen(true)}
                    className="lg:my-0 md:my-0 my-3 underline rounded-lg bg-transparent font-bold text-base text-green-700 text-opacity-80"
                  >
                    Add Payment Method
                  </button>
                </div>
                <ul className="mt-5">
                  {paymentMethods.map((method, i) => (
                    <li
                      className="flex items-center justify-between mb-2"
                      key={i}
                    >
                      <div className="w-1/2 flex justify-between items-center">
                        <div className="flex justify-center items-center">
                          <CardIcon brand={method?.card?.brand} />
                          <div className="ml-3 flex items-center">
                            <div>
                              <h2 className="ml-3 text-base font-bold text-gray-600 capitalize">
                                {`${method?.card?.brand} **** **** **** ${method?.card?.last4}`}
                              </h2>
                              <p className="ml-3 text-xs font-semibold text-gray-400">
                                Expiry: {method?.card?.exp_month}/
                                {method?.card?.exp_year}
                              </p>
                            </div>
                            {subscription?.default_payment_method ===
                              method.id && (
                              <p className="ml-6 text-xs font-semibold bg-green-200 text-green-700 px-2 py-1 rounded-md">
                                Default
                              </p>
                            )}
                          </div>
                        </div>
                        {paymentMethods.length > 1 && (
                          <div className="">
                            <button
                              onClick={() =>
                                handleDeletePaymentMethod(method.id)
                              }
                              className="text-xl text-red-600"
                            >
                              <RiDeleteBinLine />
                            </button>
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </section>
    </>
  );
};

export default Billing;
