import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Datepicker from "react-tailwindcss-datepicker";
import { RotatingLines } from "react-loader-spinner";
import { getDashboardData } from "../../../redux/api/dashboard.api";
import { getFilterPresets } from "../../../redux/api/filterPresets.api";
import {
  FaProjectDiagram,
  FaMapMarkedAlt,
  FaServicestack,
} from "react-icons/fa"; // Icons for cards
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [filterPresets, setFilterPresets] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mapId, setMapId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const auth = useSelector((state) => state.authReducer);
  const [dashboardData, setDashboardData] = useState(null);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top", // You can also set this to 'bottom', 'left', or 'right'
        labels: {
          font: {
            size: 16, // Increase font size
            weight: "bold", // Make it bold
          },
          color: "black", // Change font color
          padding: 15, // Increase padding between legend items
          usePointStyle: true, // Use point style
        },
      },
      // title: {
      //   display: true,
      //   text: "Map Interaction Statistics",
      //   font: {
      //     size: 18,
      //   },
      // },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    setLoading(true);
    // Check if startDate and endDate are the same
    if (
      dateRange.startDate &&
      dateRange.endDate &&
      dateRange.startDate === dateRange.endDate
    ) {
      setErrorMessage("*Date should not be the same.");
      setLoading(false);
      return; // Don't proceed with API call
    } else {
      setErrorMessage(""); // Clear error message if dates are valid
    }
    const fetchDashboardData = getDashboardData(
      mapId || "",
      dateRange.startDate || "",
      dateRange.endDate || ""
    )
      .then((res) => {
        setDashboardData(res.data);

        const data = res.data.statistics;
        const dates = data.map((item) => item._id);
        const mapLoads = data.map((item) => item.mapLoads);
        const totalInteractions = data.map((item) => item.totalInteractions);
        const avgInteractionTime = data.map((item) => item.avgInteractionTime);

        const totalMapLoads = mapLoads.reduce((a, b) => a + b, 0);
        const totalInteractionsSum = totalInteractions.reduce(
          (a, b) => a + b,
          0
        );
        const avgInteractionTimeAvg = (
          avgInteractionTime.reduce((a, b) => a + b, 0) /
          avgInteractionTime.length
        ).toFixed(2);

        setChartData({
          labels: dates,
          datasets: [
            {
              label: `Map Loads (${totalMapLoads})`,
              data: mapLoads,
              borderColor: "rgba(75, 192, 192, 1)",
              backgroundColor: "rgba(75, 192, 192, 0.6)",
              fill: false,
              tension: 0.3,
            },
            {
              label: `Total Interactions (${totalInteractionsSum})`,
              data: totalInteractions,
              borderColor: "rgba(54, 162, 235, 1)",
              backgroundColor: "rgba(54, 162, 235, 0.6)",
              fill: false,
              tension: 0.3,
            },
            {
              label: `Avg Interaction Time (s) (${avgInteractionTimeAvg}s)`,
              data: avgInteractionTime,
              borderColor: "rgba(255, 206, 86, 1)",
              backgroundColor: "rgba(255, 206, 86, 0.6)",
              fill: false,
              tension: 0.3,
            },
          ],
        });
      })
      .catch((err) => {
        console.log(err);
      });
    const fetchFilterPresets = getFilterPresets(auth?.user?._id)
      .then((res) => {
        setFilterPresets(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    Promise.all([fetchDashboardData, fetchFilterPresets]).finally(() => {
      setLoading(false);
    });
  }, [auth?.user?._id, mapId, dateRange]);

  const handleChange = (e) => {
    const presetId = e.target.value;
    setMapId(presetId);
  };
  return (
    <>
      <div className="bg-white rounded-lg shadow">
        <div className="border-b border-gray-200 rounded-t-lg bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-lg font-semibold leading-6 text-gray-900">
                Dashboard
              </h3>
            </div>
          </div>
        </div>

        <div className="px-4 py-5 sm:p-6 relative">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            {/* Card 1 - Services */}
            <div className="bg-white shadow-lg rounded-lg p-6 transition transform hover:scale-105 duration-300 hover:shadow-xl">
              <div className="flex items-center mb-4">
                <FaServicestack className="text-blue-500 text-3xl mr-3" />
                <h2 className="text-lg font-semibold text-gray-700">
                  Services
                </h2>
              </div>
              <p className="text-4xl font-bold text-blue-500">
                {dashboardData?.totalServices} /{" "}
                {dashboardData?.allowedServices === Infinity
                  ? "Unlimited"
                  : dashboardData?.allowedServices}
              </p>
              <p className="text-gray-500">Services allowed</p>
            </div>

            {/* Card 2 - Projects */}
            <div className="bg-white shadow-lg rounded-lg p-6 transition transform hover:scale-105 duration-300 hover:shadow-xl">
              <div className="flex items-center mb-4">
                <FaProjectDiagram className="text-green-500 text-3xl mr-3" />
                <h2 className="text-lg font-semibold text-gray-700">
                  Projects
                </h2>
              </div>
              <p className="text-4xl font-bold text-green-500">
                {dashboardData?.totalProjects} /{" "}
                {dashboardData?.allowedProjects}
              </p>
              <p className="text-gray-500">Projects allowed</p>
            </div>

            {/* Card 3 - Saved Maps */}
            <div className="bg-white shadow-lg rounded-lg p-6 transition transform hover:scale-105 duration-300 hover:shadow-xl">
              <div className="flex items-center mb-4">
                <FaMapMarkedAlt className="text-purple-500 text-3xl mr-3" />
                <h2 className="text-lg font-semibold text-gray-700">
                  Saved Maps
                </h2>
              </div>
              <p className="text-4xl font-bold text-purple-500">
                {dashboardData?.totalFilterPresets} /{" "}
                {dashboardData?.allowedFilterPresets}
              </p>
              <p className="text-gray-500">Maps allowed</p>
            </div>
          </div>
          <div className="w-full">
            <div className="bg-white shadow-lg rounded-lg p-6">
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="text-md font-medium leading-6 text-gray-900">
                    Map Interaction Statistics
                  </h3>
                </div>
                <div className="flex items-center gap-4">
                  <div>
                    <h3 className="text-md font-medium leading-6 text-gray-900">
                      Filter
                    </h3>
                  </div>
                  <select
                    className="w-full border border-gray-300 rounded-lg p-2"
                    onChange={handleChange}
                  >
                    <option value="">All</option>
                    {filterPresets?.map((preset) => (
                      <option key={preset._id} value={preset._id}>
                        {preset.name}
                      </option>
                    ))}
                  </select>
                  <div className="w-full">
                    <Datepicker
                      placeholder="Date Range"
                      value={dateRange}
                      onChange={(value) => {
                        setDateRange(value);
                      }}
                      inputClassName="bg-white border border-gray-300 rounded-lg dark:bg-white"
                    />
                    <div>
                      {errorMessage && (
                        <div className="text-sm text-red-500 -mb-5">
                          {errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {loading ? (
                <div className="flex justify-center h-96">
                  <RotatingLines
                    strokeColor="green"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="64"
                    visible={true}
                  />
                </div>
              ) : (
                <Line data={chartData} options={options} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
