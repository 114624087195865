import React, { useState } from "react";
import PersonalInformation from "../Components/PersonalInformation";
import PaymentDetails from "../Components/PaymentDetails";

const IndividualRegistration = ({
  // steps,
  // setSteps,
  // activeStep,
  // setActiveStep,
  formData,
  setFormData,
}) => {
  const [activeStep, setActiveStep] = useState(1);
  const [steps, setSteps] = useState([
    {
      id: 1,
      name: "Personal Information",
      status: "current",
    },
    {
      id: 2,
      name: "Payment Information",
      status: "upcoming",
    },
  ]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNext = () => {
    const current = activeStep + 1;
    setSteps((prev) =>
      prev.map((item) => {
        if (item.id > current) {
          return {
            ...item,
            status: "upcoming",
          };
        } else if (item.id === current) {
          return {
            ...item,
            status: "current",
          };
        } else {
          return {
            ...item,
            status: "complete",
          };
        }
      })
    );
    setActiveStep(current);
  };
  // const handleNext = () => {
  //   const current = activeStep + 1;
  //   setSteps((prev) =>
  //     prev.map((item) => {
  //       if (item.id > current) {
  //         return {
  //           ...item,
  //           status: "upcoming",
  //         };
  //       } else if (item.id === current) {
  //         return {
  //           ...item,
  //           status: "current",
  //         };
  //       } else {
  //         return {
  //           ...item,
  //           status: "complete",
  //         };
  //       }
  //     })
  //   );
  //   setActiveStep(current);
  // };

  return (
    <>
      {/* <nav aria-label="Progress" className="mb-6">
        <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
          {steps.map((step, i) => (
            <li key={i} className="md:flex-1">
              <a
                className={
                  step?.status === "complete"
                    ? "group flex space-x-2 border-l-4 border-green-400 py-2 pl-4 hover:border-green-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                    : step?.status === "current"
                    ? "flex space-x-2 border-l-4 border-green-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                    : step?.status === "upcoming" &&
                      "group flex space-x-2 border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                }
              >
                <span className="text-sm font-medium text-green-600 group-hover:text-green-800">
                  {step.id}
                </span>
                <span className="text-sm font-medium">{step.name}</span>
              </a>
            </li>
          ))}
        </ol>
      </nav> */}
      {/* {activeStep === 1 ? ( */}
      {/* <PersonalInformation
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
        // handleNext={handleNext}
      /> */}
      {/* ) : (
        <PaymentDetails formData={formData} />
      )} */}
      {formData?.plan === "basic" ? (
        <PersonalInformation
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
          handleNext={handleNext}
        />
      ) : (
        <>
          <nav aria-label="Progress" className="mb-6">
            <ol
              role="list"
              className="space-y-4 md:flex md:space-x-8 md:space-y-0"
            >
              {steps.map((step, i) => (
                <li key={i} className="md:flex-1">
                  <a
                    className={
                      step?.status === "complete"
                        ? "group flex space-x-2 border-l-4 border-green-400 py-2 pl-4 hover:border-green-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                        : step?.status === "current"
                        ? "flex space-x-2 border-l-4 border-green-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                        : step?.status === "upcoming" &&
                          "group flex space-x-2 border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                    }
                  >
                    <span className="text-sm font-medium text-green-600 group-hover:text-green-800">
                      {step.id}
                    </span>
                    <span className="text-sm font-medium">{step.name}</span>
                  </a>
                </li>
              ))}
            </ol>
          </nav>
          {activeStep === 1 ? (
            <PersonalInformation
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              handleNext={handleNext}
            />
          ) : activeStep === 2 ? (
            <PaymentDetails
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              handleNext={handleNext}
            />
          ) : (
            <PaymentDetails formData={formData} />
          )}
        </>
      )}
    </>
  );
};

export default IndividualRegistration;
