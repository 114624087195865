import axios from "axios";

export const baseURL = process.env.REACT_APP_API_URL;

export const API = axios.create({
  baseURL: baseURL,
});

API.interceptors.request.use((req) => {
  const token = localStorage.getItem("token");
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }
  return req;
});
