import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../../assets/images/Logo2.png";
import registerImg from "../../../../assets/images/register-bg.png";
import IndividualRegistration from "./Individual/IndividualRegistration";
import quotes from "../../../../assets/images/Group.png";
import backgroundImage from "../../../../assets/images/signupbg.png";
import { FaStar } from "react-icons/fa";
import CompanyRegistration from "./Company/CompanyRegistration";
import Slider from "react-slick";
import FreeRegistration from "./Free/FreeRegistration";

const reviews = [
  {
    id: 1,
    stars: 5,
    text: "I Increased my close rate by 15% in the first month using Pro-Mapper.",
    name: "Wes",
    location: "Denver",
  },
  {
    id: 2,
    stars: 5,
    text: "My customers love seeing my local projects. Pro-Mapper is amazing!",
    name: "Paula",
    location: "Los Angeles",
  },
  {
    id: 3,
    stars: 5,
    text: "Pro-Mapper is easy to use and provides",
    name: "Justin",
    location: "Ohio",
  },
  {
    id: 4,
    stars: 5,
    text: "I Increased my close rate using Pro-Mapper. Highly recommend it!",
    name: "Wes",
    location: "Denver",
  },
  {
    id: 5,
    stars: 5,
    text: "My customers love seeing my local projects. Pro-Mapper is amazing! ",
    name: "Paula",
    location: "Los Angeles",
  },
];
const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const type = new URLSearchParams(location.search).get("type");
  const plan = new URLSearchParams(location.search).get("plan");
  const frequency = new URLSearchParams(location.search).get("frequency");

  const initialValues = {
    _id: null,
    companyName: "",
    website: "",
    company: "",
    experience: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    termsAndConditions: false,
    type: type,
    plan: plan,
    frequency: frequency,
    stripeCustomerId: "",
  };

  const [formData, setFormData] = useState(initialValues);

  // const [steps, setSteps] = useState([
  //   {
  //     id: 1,
  //     name: "Personal Information",
  //     status: "current",
  //   },
  //   {
  //     id: 2,
  //     name: "Payment Information",
  //     status: "upcoming",
  //   },
  // ]);
  // const [activeStep, setActiveStep] = useState(1);

  // const handleNavigateToLogin = () => {
  //   if (activeStep === 2) {
  //     notifyAdminAboutIncompleteRegistration({
  //       firstName: formData?.firstName,
  //       lastName: formData?.lastName,
  //       email: formData?.email,
  //     });
  //     navigate("/");
  //   } else {
  //     navigate("/");
  //   }
  // };

  return (
    <>
      {/* <IndividualRegistration
        // steps={steps}
        // setSteps={setSteps}
        // activeStep={activeStep}
        // setActiveStep={setActiveStep}
        formData={formData}
        setFormData={setFormData}
      /> */}
      <div className="grid grid-cols-12">
        <div className="bg-[#0BAC59] h-[35rem] lg:h-screen col-span-12 lg:col-span-6 lg:rounded-none rounded-b-[5%]  sm:col-span-12 relative">
          <div className="absolute inset-0 flex items-center justify-center max-w-5xl mx-auto">
            <div className="w-full lg:w-2/3 text-white">
              <div className="flex items-center justify-center flex-col">
                <div className="lg:text-left text-center w-4/5 flex flex-col lg:items-start items-center justify-center">
                  <div className="pt-8">
                    <img
                      className="h-16 w-auto"
                      src={logo}
                      alt="Your Company"
                    />
                  </div>
                  <h1 className="uppercase my-4 text-xl lg:text-2xl font-bold">
                    Join Pro Mapper Today and Enhance Your Project Visibility
                  </h1>
                </div>
                <div
                  className="h-80 w-full block bg-cover bg-center bg-no-repeat rounded-b-[5%] bg-blend-multiply bg-[#0BAC59]"
                  style={{ backgroundImage: `url(${registerImg})` }}
                ></div>
                <div className="w-full slider-container">
                  <Slider {...settings}>
                    {reviews.map((review) => (
                      <div key={review.id} className="flex justify-center">
                        <div className="lg:block hidden w-4/5 py-2 gap-7 grid grid-cols-12">
                          <div className="lg:col-span-12 md:col-span-12 col-span-12">
                            <div className="py-3 px-3 w-full mt-3 mx-auto sm:mx-4 bg-white bg-opacity-10 rounded-lg relative border-[0.5px] border-white">
                              <div className="absolute right-0 bottom-0">
                                <img
                                  className="w-full object-cover p-3"
                                  src={quotes}
                                  alt={review.name}
                                />
                              </div>
                              <div className="flex flex-col px-4">
                                <div className="flex mt-2">
                                  {Array.from({ length: review.stars }).map(
                                    (_, index) => (
                                      <FaStar
                                        key={index}
                                        className="h-5 w-5 text-yellow-500"
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="px-4 py-4">
                                <p className="text-white text-lg">
                                  {review.text}
                                </p>
                              </div>
                              <div className="px-4 pt-2 relative">
                                <h5 className="text-white text-xl font-bold">
                                  {review.name}
                                </h5>
                                <p className="text-white text-lg">
                                  {review.location}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
              {/* <img className="w-auto h-96" src={loginImg} alt="bg img" /> */}
            </div>
          </div>
        </div>
        <div className="h-auto lg:h-screen col-span-12 lg:col-span-6 md:col-span-12  ">
          <div className=" flex items-center justify-center h-3/4 md:h-[90%] lg:h-screen">
            <div className="lg:relative lg:top-0 my-auto absolute top-1/3 bg-white border border-gray-200 shadow py-16 lg:px-10 px-5 rounded-lg w-[90%] md:w-3/5 lg:w-3/5">
              <div>
                <h2 className="text-center uppercase text-2xl lg:text-3xl font-bold leading-9 tracking-tight text-gray-900">
                  Sign Up to Pro Mapper
                </h2>
              </div>

              {/* <div className="mt-5">
                {plan === "individual" ? (
                  <IndividualRegistration
                    // steps={steps}
                    // setSteps={setSteps}
                    // activeStep={activeStep}
                    // setActiveStep={setActiveStep}
                    formData={formData}
                    setFormData={setFormData}
                  />
                ) : (
                  plan === "business" && (
                    <CompanyRegistration
                      // steps={steps}
                      // setSteps={setSteps}
                      // activeStep={activeStep}
                      // setActiveStep={setActiveStep}
                      formData={formData}
                      setFormData={setFormData}
                    />
                  )
                )}
              </div> */}
              <div className="mt-5">
                {type === "individual" ? (
                  <IndividualRegistration
                    formData={formData}
                    setFormData={setFormData}
                  />
                ) : (
                  type === "business" && (
                    <CompanyRegistration
                      formData={formData}
                      setFormData={setFormData}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
