import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { RotatingLines } from "react-loader-spinner";

const AddOption = ({
  open,
  setOpen,
  field,
  setFields,
  newOptions,
  setNewOptions,
  selectedBrand,
  setSelectedBrand,
  equipmentBrandsForModel,
}) => {
  const cancelButtonRef = useRef(null);

  const [option, setOption] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmitOption = (e) => {
    e.preventDefault();
    setLoading(true);
    if (option.trim() !== "") {
      setFields((prevFields) => {
        const newFields = [...prevFields];
        const index = newFields.findIndex((f) => f.name === field.name);
        if (newFields[index]?.module === "equipmentBrands")
          newFields[index].options.push({
            _id: option,
            name: option,
          });
        else if (newFields[index]?.module === "equipmentModels")
          newFields[index]?.options?.push({
            _id: option,
            name: option,
            brand: selectedBrand,
            type: field?.filter,
          });
        else newFields[index].options.push(option);
        return newFields;
      });

      setNewOptions([
        ...newOptions,
        {
          fieldName: field.name,
          module: field.module,
          filter: field.filter,
          optionSet: field.optionSet,
          option,
          brand: selectedBrand,
          type: field?.filter,
        },
      ]);
      setLoading(false);
    }
    setOption("");
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={setOpen}
        initialFocus={cancelButtonRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {`Add Option in ${field?.label}`}
                    </Dialog.Title>
                  </div>
                </div>
                <form>
                  <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    {field?.module === "optionSets" ||
                    field?.module === "utilities" ||
                    field?.module === "equipmentBrands" ? (
                      <>
                        <div>
                          <label
                            htmlFor="option"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Option
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            type="text"
                            name="option"
                            id="option"
                            autoComplete="off"
                            placeholder="Option"
                            value={option}
                            onChange={(e) => {
                              setOption(e.target.value);
                            }}
                            className="block w-full shadow-sm sm:text-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md"
                          />
                        </div>
                      </>
                    ) : (
                      field?.module === "equipmentModels" && (
                        <>
                          <div>
                            <label
                              htmlFor="option"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Brand
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <select
                              id="brand"
                              name="brand"
                              autoComplete="off"
                              value={selectedBrand}
                              onChange={(e) => {
                                setSelectedBrand(e.target.value);
                              }}
                              className="block w-full shadow-sm sm:text-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md"
                            >
                              <option value="">Select Brand</option>
                              {equipmentBrandsForModel?.map((brand) => (
                                <option key={brand._id} value={brand._id}>
                                  {brand.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label
                              htmlFor="option"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Option
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              type="text"
                              name="option"
                              id="option"
                              autoComplete="off"
                              placeholder="Option"
                              value={option}
                              onChange={(e) => {
                                setOption(e.target.value);
                              }}
                              className="block w-full shadow-sm sm:text-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md"
                            />
                          </div>
                        </>
                      )
                    )}
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      onClick={handleSubmitOption}
                      disabled={loading}
                      className={`${
                        loading
                          ? "bg-green-700 hover:bg-green-700 cursor-not-allowed"
                          : ""
                      }inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-24 sm:text-sm`}
                    >
                      {loading ? (
                        <div className="flex justify-center items-center">
                          <RotatingLines
                            strokeColor="white"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="20"
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Create"
                      )}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => {
                        setOpen(false);
                      }}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddOption;
