import { API } from "./api";

export const getProjects = () => API.get("/projects");

export const getProjectsByCompany = (
  companyId,
  filter = null,
  pagination = null
) =>
  API.get(`/projects/company/${companyId}`, {
    params: {
      filter: JSON.stringify(filter) || null,
      skip: pagination?.skip || null,
      limit: pagination?.limit || null,
    },
  });

export const getProjectsByBounds = (bounds) =>
  API.post("/projects/bounds", { bounds });

export const getProjectById = (id) => API.get(`/projects/id/${id}`);

export const createProject = (newProject) => API.post("/projects", newProject);

export const uploadProjectsCsv = (newData) =>
  API.post("/projects/uploadCsv", newData);

export const submitResolvedBrandConflicts = (newData) =>
  API.post("/equipmentBrands/addConflictedBrands", newData);

export const updateProject = (id, updatedProject) =>
  API.patch(`/projects/${id}`, updatedProject);

export const deleteProject = (id) => API.delete(`/projects/${id}`);
export const getProjectsCreatedByUser = (id, filter) => {
  return API.post(`/projects/createdBy/${id}`, filter);
};
export const getProjectsCreatedByCompany = (id, filter) => {
  return API.post(`/projects/createdBy/company/${id}`, filter);
};
export const getServicesByUserProjects = (id) => {
  return API.get(`/services/user/${id}/projects`);
};

export const uploadCSV = (projects) => API.post(`/projectsUploads/`, projects);
