import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { BiCloudUpload } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getEquipmentBrands } from "../../../redux/actions/equipmentBrand";
import { baseURL } from "../../../redux/api/api";
import {
  createCompany,
  getCompany,
  getServicesByCompanyId,
  updateCompany,
} from "../../../redux/api/company.api";
import {
  deleteContact,
  deleteContactFromCompany,
} from "../../../redux/api/contact.api";
import { deleteOffice } from "../../../redux/api/office.api";
import { confirmation, success, warning } from "../../../utils/notification";
import CustomDropDown from "../../components/CustomDropDown";
import AddContactPopup from "./add-contact-popup";
import AddOfficePopup from "./add-office-popup";
import AddUserPopup from "./add-user-popup";
import UpdateServicesPopup from "./IndividualProfile/Components/UpdateServicesPopup";
import UpdateWorkTypesPopup from "./IndividualProfile/Components/UpdateWorkTypesPopup";
import { RotatingLines } from "react-loader-spinner";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CompanyProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.authReducer);

  const tabs = [{ name: "Offices" }, { name: "Services" }, { name: "Users" }];
  const initialData = {
    name: "",
    entityName: "",
    logo: "",
    website: "",
    experience: "",
    description: "",
    services: [],
    brandsOffered: [],
    contacts: [],
    offices: [],
    users: [],
  };
  const { equipmentBrands } = useSelector(
    (state) => state.equipmentBrandReducer
  );

  const [services, setServices] = useState([]);
  const [editData, setEditData] = useState(null);
  const [officePopupOpen, setOfficePopupOpen] = useState(false);
  const [contactPopupOpen, setContactPopupOpen] = useState(false);
  const [userPopupOpen, setUserPopupOpen] = useState(false);
  const [updateServicesPopupOpen, setUpdateServicesPopupOpen] = useState(false);
  const [updateWorkTypesPopupOpen, setUpdateWorkTypesPopupOpen] =
    useState(false);
  const [serviceToUpdate, setServiceToUpdate] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Offices");
  const [company, setCompany] = useState(initialData);
  const [logoPreview, setLogoPreview] = useState(null); // [null, setLogoPreview]
  const [serviceQuery, setServiceQuery] = useState("");
  const [brandsOfferedQuery, setBrandsOfferedQuery] = useState("");
  const [servicesLoading, setServicesLoading] = useState(false);
  const [brandsOfferedLoading, setBrandsOfferedLoading] = useState(false);
  const [servicesSelectedValue, setServicesSelectedValue] = useState("");
  // const [brandsOfferedSelectedValue, setBrandsOfferedSelectedValue] =
  //   useState("");
  const [filteredServices, setFilteredServices] = useState([]);
  // const [filteredEquipmentBrands, setFilteredEquipmentBrands] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auth?.user) {
      getCompany(auth?.user?.company?._id).then((res) => {
        setCompany(res?.data);
        getServices();
      });
    }
  }, [auth]);

  useEffect(() => {
    dispatch(getEquipmentBrands());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEquipmentBrands({ name: brandsOfferedQuery }, null, null));
  }, [brandsOfferedQuery]);

  const getServices = () => {
    getServicesByCompanyId(auth?.user?.company?._id)
      .then((res) => {
        setServices(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "logo") {
      setCompany({ ...company, [name]: e.target.files[0] });
      setLogoPreview(URL.createObjectURL(e.target.files[0]));
      return;
    }
    setCompany({ ...company, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", company?.name);
    formData.append("entityName", company?.entityName || "");
    formData.append("logo", company?.logo || "");
    formData.append("website", company?.website || "");
    formData.append("experience", company?.experience || "");
    formData.append("description", company?.description || "");
    formData.append(
      "offices",
      JSON.stringify(company?.offices?.map((office) => office?._id))
    );
    formData.append(
      "users",
      JSON.stringify(company?.users?.map((user) => user?._id))
    );
    if (company?._id) {
      console.log("Updating company", company);
      updateCompany(company?._id, formData)
        .then((res) => {
          setLoading(false);
          console.log("Company updated successfully", res);
          success("Success", "Company updated successfully");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      createCompany(formData)
        .then((res) => {
          setLoading(false);
          navigate("/companies");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };
  const handleDeleteContact = async (id) => {
    confirmation().then((res) => {
      if (res.isConfirmed) {
        if (company?._id) {
          deleteContactFromCompany(company?._id, id).then((res) => {
            setCompany({
              ...company,
              contacts: company?.contacts?.filter(
                (contact) => contact._id !== id
              ),
            });
          });
        } else {
          deleteContact(id).then((res) => {
            setCompany({
              ...company,
              contacts: company?.contacts?.filter(
                (contact) => contact._id !== id
              ),
            });
          });
        }
      }
    });
  };

  const handleDeleteOffice = async (id) => {
    confirmation().then((res) => {
      if (res.isConfirmed) {
        deleteOffice(id, company?._id).then((res) => {
          setCompany({
            ...company,
            offices: company?.offices?.filter((office) => office._id !== id),
          });
        });
      }
    });
  };

  return (
    <>
      <AddOfficePopup
        data={editData}
        open={officePopupOpen}
        setOpen={setOfficePopupOpen}
        company={company}
        setCompany={setCompany}
        editing={company?._id}
      />
      <AddContactPopup
        data={editData}
        open={contactPopupOpen}
        setOpen={setContactPopupOpen}
        company={company}
        setCompany={setCompany}
        offices={company?.offices}
        editing={company?._id}
      />
      <UpdateServicesPopup
        open={updateServicesPopupOpen}
        setOpen={setUpdateServicesPopupOpen}
        userServices={services?.map((service) => ({
          serviceId: service?.serviceId?._id,
          workTypes: service?.workTypes?.map((workType) => workType?._id),
        }))}
        getCompanyServices={getServices}
      />
      <UpdateWorkTypesPopup
        open={updateWorkTypesPopupOpen}
        setOpen={setUpdateWorkTypesPopupOpen}
        service={serviceToUpdate}
        userServices={services?.map((service) => ({
          serviceId: service?.serviceId?._id,
          workTypes: service?.workTypes?.map((workType) => workType?._id),
        }))}
        getUserServices={getServices}
      />
      <AddUserPopup
        data={editData}
        open={userPopupOpen}
        setOpen={setUserPopupOpen}
        company={company}
        setCompany={setCompany}
        editing={company?._id}
      />
      <form onSubmit={handleSubmit}>
        <div className="bg-white rounded-lg shadow">
          <div className="border-b border-gray-200 rounded-t-lg bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-2">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Company Profile
                </h3>
              </div>
              <div className="ml-4 mt-2 flex-shrink-0">
                <button
                  type="submit"
                  disabled={loading}
                  className={`relative w-24 ${
                    loading
                      ? "bg-green-700 hover:bg-green-700 cursor-not-allowed"
                      : ""
                  } justify-center inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600`}
                >
                  {loading ? (
                    <div className="flex justify-center items-center">
                      <RotatingLines
                        strokeColor="white"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="20"
                        visible={true}
                      />
                    </div>
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-4">
                <div className="relative w-40 h-40 bg-gray-100 hover:bg-gray-200 rounded-full cursor-pointer group">
                  {company?.logo ? (
                    <>
                      <img
                        src={
                          logoPreview
                            ? logoPreview
                            : `${baseURL}s3/images/${company?.logo}`
                        }
                        alt="logo"
                        className="absolute top-0 w-40 h-40 rounded-full"
                      />
                      <input
                        type="file"
                        name="logo"
                        id="logo"
                        accept="image/*"
                        onChange={handleChange}
                        className="w-40 h-40 opacity-0 cursor-pointer"
                      />
                    </>
                  ) : (
                    <>
                      <input
                        type="file"
                        name="logo"
                        id="logo"
                        accept="image/*"
                        onChange={handleChange}
                        className="w-40 h-40 opacity-0 cursor-pointer"
                      />
                      <label
                        htmlFor="logo"
                        className="w-40 h-40 cursor-pointer flex absolute top-0 items-center justify-center"
                      >
                        <BiCloudUpload className="w-20 h-20 text-gray-400 group-hover:text-gray-500 mx-auto my-10" />
                      </label>
                    </>
                  )}
                </div>
              </div>
              {/* Input for company name */}
              <div className="col-span-8">
                <div className="mt-2">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Company Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={company?.name}
                      onChange={handleChange}
                      className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Company Name"
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label
                    htmlFor="entityName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Entity Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="entityName"
                      id="entityName"
                      value={company?.entityName}
                      onChange={handleChange}
                      className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Entity Name"
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label
                    htmlFor="entityName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Website
                  </label>
                  <div className="mt-1">
                    <input
                      type="url"
                      name="website"
                      id="website"
                      value={company?.website}
                      onChange={handleChange}
                      className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Website"
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label
                    htmlFor="entityName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Years of Experience
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="experience"
                      id="experience"
                      value={company?.experience}
                      onChange={handleChange}
                      className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Enter Years of Experience"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-span-6">
                <div className="mt-3">
                  <label
                    htmlFor="brandsOffered"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Brands Offered
                  </label>
                  <div className="mt-1">
                    <CustomDropDown
                      multiple={true}
                      query={brandsOfferedQuery}
                      setQuery={setBrandsOfferedQuery}
                      loading={brandsOfferedLoading}
                      setLoading={setBrandsOfferedLoading}
                      value={company?.brandsOffered}
                      displayValue={(brands) =>
                        brands.length > 0
                          ? brands?.map((brand) => brand.name).join(", ")
                          : ""
                      }
                      data={equipmentBrands}
                      filteredData={equipmentBrands}
                      setFilteredData={setFilteredEquipmentBrands}
                      handleSelect={(thisBrand) => {
                        if (
                          company?.brandsOffered?.find(
                            (brand) => brand._id === thisBrand._id
                          )
                        ) {
                          setCompany({
                            ...company,
                            brandsOffered: company?.brandsOffered?.filter(
                              (brand) => brand._id !== thisBrand._id
                            ),
                          });
                        } else {
                          setCompany({
                            ...company,
                            brandsOffered: [
                              ...company?.brandsOffered,
                              thisBrand,
                            ],
                          });
                        }
                      }}
                      selectText="Select Brands"
                      editing={company?._id}
                      setValue={company?.brandsOffered}
                      selectedValue={brandsOfferedSelectedValue}
                      setSelectedValue={setBrandsOfferedSelectedValue}
                      // disabled={appointment && true}
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-span-12">
                <div className="mt-3">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="description"
                      name="description"
                      rows="5"
                      defaultValue={company?.description}
                      onChange={handleChange}
                      className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Company Description"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10">
              <div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                    defaultValue={selectedTab}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      {tabs.map((tab) => (
                        <button
                          type="button"
                          key={tab.name}
                          className={classNames(
                            selectedTab === tab.name
                              ? "border-green-500 text-green-600"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                          )}
                          aria-current={tab.current ? "page" : undefined}
                          onClick={() => setSelectedTab(tab.name)}
                        >
                          {tab.name}
                        </button>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
              <div>
                {selectedTab === "Offices" && (
                  <div className="mt-6">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">
                          Offices
                        </h1>
                        {/* <p className="mt-2 text-sm text-gray-700">
                    A list of all the users in your account including their
                    name, title, email and role.
                  </p> */}
                      </div>
                      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          onClick={() => setOfficePopupOpen(true)}
                          className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          Add Office
                        </button>
                      </div>
                    </div>
                    <div className="mt-8 flow-root">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  {/* Check boxes to select rows */}
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  ></th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Phone No
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Address
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {company?.offices?.length === 0 ? (
                                  <tr>
                                    <td
                                      colSpan="6"
                                      className="py-4 pl-4 pr-3 text-2xl font-medium text-gray-900 sm:pl-6 text-center h-32"
                                    >
                                      Add your first office
                                    </td>
                                  </tr>
                                ) : (
                                  company?.offices?.map((office, i) => (
                                    <tr key={i}>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start"></td>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                        {office?.name}
                                      </td>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                        {office?.phoneNo}
                                      </td>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                        {office?.address}
                                      </td>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setEditData(office);
                                            setOfficePopupOpen(true);
                                          }}
                                        >
                                          <PencilIcon
                                            className="h-5 w-5 text-gray-400 hover:text-gray-500"
                                            aria-hidden="true"
                                          />
                                        </button>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            handleDeleteOffice(office?._id);
                                          }}
                                        >
                                          <TrashIcon
                                            className="h-5 w-5 text-gray-400 hover:text-gray-500"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* {selectedTab === "Contacts" && (
                  <div className="mt-6">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">
                          Contacts
                        </h1>
                      </div>
                      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          onClick={() => setContactPopupOpen(true)}
                          className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          Add Contact
                        </button>
                      </div>
                    </div>
                    <div className="mt-8 flow-root">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  ></th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Phone No
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Email
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Offices
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {company?.contacts?.length === 0 ? (
                                  <tr>
                                    <td
                                      colSpan="6"
                                      className="py-4 pl-4 pr-3 text-2xl font-medium text-gray-900 sm:pl-6 text-center h-32"
                                    >
                                      Add your first contact
                                    </td>
                                  </tr>
                                ) : (
                                  company?.contacts?.map((contact, i) => (
                                    <tr key={i}>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start"></td>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                        {`${contact?.firstName} ${contact?.lastName}`}
                                      </td>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                        {contact.phoneNo}
                                      </td>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                        {contact.email}
                                      </td>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                          {contact?.offices?.map(
                                            (office, i) => (
                                              <li
                                                key={i}
                                                className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                                              >
                                                <div className="w-0 flex-1 flex items-center">
                                                  <span className="ml-2 flex-1 w-0 truncate">
                                                    {office?.name}
                                                  </span>
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </td>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setEditData(contact);
                                            setContactPopupOpen(true);
                                          }}
                                        >
                                          <PencilIcon
                                            className="h-5 w-5 text-gray-400 hover:text-gray-500"
                                            aria-hidden="true"
                                          />
                                        </button>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            handleDeleteContact(contact?._id);
                                          }}
                                        >
                                          <TrashIcon
                                            className="h-5 w-5 text-gray-400 hover:text-gray-500"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}
                {selectedTab === "Services" && (
                  <div className="mt-6">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">
                          Services
                        </h1>
                      </div>
                      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          onClick={() => setUpdateServicesPopupOpen(true)}
                          className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          Edit Services
                        </button>
                      </div>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                      {services?.length === 0 ? (
                        <div className="mt-6">
                          <p className="text-gray-500 text-center">
                            No services added to this company profile
                          </p>
                        </div>
                      ) : (
                        services?.length > 0 && (
                          <ul
                            role="list"
                            className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
                          >
                            {services?.map((service, i) => (
                              <li
                                key={i}
                                className="col-span-1 flex rounded-md shadow-sm cursor-pointer border rounded-r-md border-gray-200 p-4"
                                onClick={() => {
                                  setUpdateWorkTypesPopupOpen(true);
                                  setServiceToUpdate(service);
                                }}
                              >
                                <div
                                  className={classNames(
                                    service.bgColor,
                                    "bg-green-600 flex w-12 h-12 flex-shrink-0 items-center justify-center rounded-full text-xl font-medium text-white"
                                  )}
                                >
                                  {service?.serviceId?.name?.charAt(0)}
                                </div>
                                <div className="flex flex-1 justify-between truncate bg-white">
                                  <div className="flex-1 truncate px-4 py-2 text-sm">
                                    <h2 className="font-medium text-gray-900 hover:text-gray-600">
                                      {service?.serviceId?.name}
                                    </h2>
                                    <ul className="flex flex-wrap mt-2">
                                      {service?.workTypes?.map(
                                        (workType, i) => (
                                          <li
                                            key={i}
                                            className="text-xs bg-gray-100 text-gray-600 rounded-full px-2 py-1 mr-2 mb-2"
                                          >
                                            {workType?.name}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )
                      )}
                    </div>
                  </div>
                )}
                {selectedTab === "Users" && (
                  <div className="mt-6">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">
                          Users
                        </h1>
                      </div>
                      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          onClick={() => {
                            let usersLimit;
                            switch (auth?.user?.plan) {
                              case "basic":
                                usersLimit = 1;
                                break;
                              case "pro":
                                usersLimit = 5;
                                break;
                              case "pro-plus":
                                usersLimit = Infinity;
                                break;
                              default:
                                usersLimit = 1;
                                break;
                            }
                            if (company?.users?.length >= usersLimit) {
                              warning(
                                "You have reached the maximum number of users allowed for your plan. Please upgrade your plan to add more users."
                              );
                            } else setUserPopupOpen(true);
                          }}
                          className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          Add User
                        </button>
                      </div>
                    </div>
                    <div className="mt-8 flow-root">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  {/* Check boxes to select rows */}
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  ></th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Email
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {company?.users?.length === 0 ? (
                                  <tr>
                                    <td
                                      colSpan="6"
                                      className="py-4 pl-4 pr-3 text-2xl font-medium text-gray-900 sm:pl-6 text-center h-32"
                                    >
                                      Add your first user
                                    </td>
                                  </tr>
                                ) : (
                                  company?.users?.map((user, i) => (
                                    <tr key={i}>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start"></td>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                        {`${user?.firstName} ${user?.lastName}`}
                                      </td>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                        {user?.email}
                                      </td>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start"></td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CompanyProfile;
