import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import Navbar from "../../components/Navbar";
import { Link } from "react-router-dom";
import { GoPerson } from "react-icons/go";
import bgImg from "../../../assets/images/Mask group (1).png";
import Navigation from "../../components/Navigation";
import { FaStar } from "react-icons/fa";
import quotes from "../../../assets/images/Group.png";
import Footer from "../../components/Footer";
import { FaAngleUp } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { VscBriefcase } from "react-icons/vsc";
import Slider from "react-slick";

const reviews = [
  {
    id: 1,
    stars: 5,
    text: "I Increased my close rate by 15% in the first month using Pro-Mapper. Highly recommend it!",
    name: "Wes",
    location: "Denver",
  },
  {
    id: 2,
    stars: 5,
    text: "My customers love seeing my local projects. Pro-Mapper is amazing!",
    name: "Paula",
    location: "Los Angeles",
  },
  {
    id: 3,
    stars: 5,
    text: "Pro-Mapper is easy to use and provides my potential clients with an insight into the quality of work we provide.",
    name: "Justin",
    location: "Ohio",
  },
  {
    id: 4,
    stars: 5,
    text: "I Increased my close rate by 15% in the first month using Pro-Mapper. Highly recommend it!",
    name: "Wes",
    location: "Denver",
  },
  {
    id: 5,
    stars: 5,
    text: "My customers love seeing my local projects. Pro-Mapper is amazing! My customers love seeing my local projects. Pro-Mapper is amazing! My customers love seeing my local projects. Pro-Mapper is amazing!",
    name: "Paula",
    location: "Los Angeles",
  },
];
var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const questions = [
  {
    question: "What is Pro Mapper?",
    answer:
      "Pro Mapper is a platform designed to help individuals and businesses showcase their projects dynamically using interactive maps.",
  },
  {
    question: "How does the Individual Plan work?",
    answer:
      "The Individual Plan is tailored for salespeople and freelancers who need to showcase projects from multiple companies they have worked with. It provides tools to manage and display diverse projects in one place.",
  },
  {
    question: "What benefits does the Business Plan offer?",
    answer:
      "The Business Plan is designed for business owners to highlight their own company’s projects. It includes features to enhance business visibility, customizable project pages, and dedicated support.",
  },
  {
    question: "Can I switch between plans?",
    answer:
      "Yes, you can upgrade or downgrade your plan at any time to better suit your needs.",
  },
  {
    question: "How do I upload and manage my projects?",
    answer:
      "Once you sign up, you can easily upload project details and images through our user-friendly interface. For individual plans, you can manage projects from multiple companies, while the business plan allows you to showcase your own company’s work.",
  },
];

const tiers = [
  // {
  //   name: "Individual Plans",
  //   id: "individual",
  //   href: "/pricing?type=individual",
  //   price: "$29.99",
  //   priceSuffix: "/month",
  //   description:
  //     "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
  //   features: [
  //     "Upload projects from multiple companies.",
  //     "Manage all your work in one place.",
  //     "Easy to use interface.",
  //     "Personalized support.",
  //   ],
  //   mostPopular: false,
  //   icon: GoPerson,
  // },
  {
    name: "Business Plans",
    id: "business",
    href: "/pricing?type=business",
    price: "$299.99",
    priceSuffix: "/year",
    description:
      "Ideal for business owners who want to display their own work and enhance their business visibility.",
    features: [
      "Showcase your company’s projects.",
      "Enhance your business visibility.",
      "Customizable project pages.",
      "Dedicated business support.",
    ],
    mostPopular: true,
    icon: VscBriefcase,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Plans = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div className="mx-auto max-w-[2000px] bg-white">
      <Navigation />
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <p className="uppercase mt-2 text-4xl font-bold tracking-tight text-gray-900">
            Choose the Pro Mapper Plan for You{" "} {/* Choose the Right Pro Mapper Plan for You */}
          </p>
        </div>
        <p className="mx-auto mt-3 max-w-3xl text-center text-lg text-gray-600">
          Our flexible pricing plans cater to both sales professionals and
          business owners, enabling you to showcase your projects and boost
          client engagement effectively.
        </p>
        <div className="isolate mx-auto mt-10 max-w-md gap-8 lg:mx-auto lg:max-w-md"> {/*isolate mx-auto mt-10 max-w-md grid grid-cols-1 col-span-1 lg:grid-col-span-2 gap-8 max-w-7xl */}
          {tiers.map((tier) => {
            const Icon = tier.icon;
            return (
              <div
                key={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "ring-2 ring-green-600"
                    : "ring-1 ring-gray-200",
                  "rounded-lg"
                )}
              >
                <div className="relative w-full text-center ">
                  <img
                    className="h-[27rem] absolute top-0 -z-10 w-full object-cover"
                    src={bgImg}
                    alt="Bonnie"
                  />
                  <div className="pt-4">
                    <span className=" mx-auto h-16 w-16 flex items-center justify-center rounded-full bg-green-200 bg-opacity-30">
                      <Icon className="text-4xl text-white" />
                    </span>
                    <p className="mt-2 text-xl text-white font-bold">
                      {tier.name}
                    </p>

                    <p className="px-3 mt-2 -mb-2 lg:mb-10 text-sm text-white">
                      {tier.description}
                    </p>
                  </div>
                </div>
                <div className="p-8 xl:p-10">
                  <ul className=" mt-0 space-y-3 text-sm leading-6 text-gray-600 lg:mt-5 xl:mt-5">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className="h-6 w-5 flex-none text-green-600"
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <div className="flex items-center justify-center">
                    <Link
                      to={tier.href}
                      className="mt-5 mx-auto shrink-0 rounded-full px-10 py-2.5 font-bold text-white text-base bg-gradient-to-r from-[#D88A16] to-[#FFB800]"
                    >
                      See {tier.name}
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* users reviews  */}
      </div>
      {/* users reviews  */}
      <div className="mt-10 md:px-20 px-5 lg:px-20 py-5 md:py-16 lg:py-16">
        <div className=" flex items-center justify-center">
          <div className="w-full md:w-3/5 lg:w-3/5">
            <h2 className="text-3xl md:text-4xl lg:text-4xl uppercase text-center font-bold mb-4">
              Hear from Our Happy Clients
            </h2>
            <p className="mx-auto md:w-4/5 w-full lg:w-4/5 text-gray-500  text-sm leading-relaxed text-center">
              Discover how Pro Mapper has helped businesses of all sizes
              increase their project visibility and client engagement.
            </p>
          </div>
        </div>

        <div className="mt-5 slider-container">
          <Slider {...settings}>
            {reviews.map((review) => (
              <div key={review.id} className="flex justify-center">
                <div className="mx-4 lg:col-span-4 md:col-span-6 col-span-12 h-full">
                  <div className="rounded-lg bg-gradient-to-tl from-green-600 via-transparent to-transparent p-[1px] h-full">
                    <div className="py-2 lg:py-5 px-4 w-full border border-gray-300 rounded-lg relative bg-white h-full grid grid-rows-[auto,1fr,auto]">
                      {" "}
                      {/* Grid layout */}
                      <div className="absolute right-0 bottom-0">
                        <img
                          className="w-full object-cover p-4"
                          src={quotes}
                          alt={review.name}
                        />
                      </div>
                      <div className="flex flex-col px-4">
                        <div className="flex mt-2">
                          {Array.from({ length: review.stars }).map(
                            (_, index) => (
                              <FaStar
                                key={index}
                                className="h-5 w-5 text-yellow-500"
                              />
                            )
                          )}
                        </div>
                      </div>
                      <div className="px-4 py-4 flex-grow">
                        <p className="text-lg text-gray-700">{review.text}</p>
                      </div>
                      <div className="px-4 pt-4 relative">
                        <h5 className="text-xl font-bold text-gray-900">
                          {review.name}
                        </h5>
                        <p className="text-lg text-gray-700">
                          {review.location}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {/* frequesntly asked questions  */}
      <div className="max-w-7xl mx-auto md:px-20 px-5 lg:px-20 py-3 md:py-16 lg:py-16">
        <div className="flex items-center justify-center">
          <div className="w-full md:w-3/5 lg:w-3/5">
            <h2 className="text-3xl md:text-4xl lg:text-4xl uppercase text-center font-bold mb-4">
              Frequently Asked Questions
            </h2>
          </div>
        </div>
        <div>
          {questions.map((q, index) => (
            <div
              key={index}
              className="my-5 border border-gray-100 group bg-white shadow rounded-lg py-4"
            >
              <div className="px-4 pr-3 lg:pr-6 py-2">
                <button
                  className="w-full flex justify-between items-center text-left focus:outline-none"
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="ml-2 lg:ml-4 text-xl font-bold text-gray-800">
                    {index + 1}. {q.question}
                  </span>
                  <span className="mt-0.5 ml-2 text-gray-800 text-lg">
                    {openIndex === index ? <FaAngleUp /> : <FaAngleDown />}
                  </span>
                </button>
              </div>
              <div
                className={`transition-all duration-700 ease-in-out overflow-hidden ${
                  openIndex === index ? "max-h-screen" : "max-h-0"
                }`}
              >
                <div className="px-2 lg:px-4 group-hover:bg-transparent">
                  <div className="py-1 px-4">
                    <p className="text-lg text-gray-700">{q.answer}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Plans;
