import { FunnelIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getServices } from "../../../redux/actions/service";
import Table from "../../components/Table";
import ProjectFilterPopup from "./ProjectFilterPopup";
import {
  deleteProject,
  getProjectsCreatedByCompany,
  getProjectsCreatedByUser,
  getServicesByUserProjects,
} from "../../../redux/api/project.api";
import UploadCsvPopup from "./UploadCsvPopup";
import { confirmation, failure, success } from "../../../utils/notification";
import {
  createFilterPreset,
  updateFilterPreset,
} from "../../../redux/api/filterPresets.api";
import { getServicesByCompanyId } from "../../../redux/api/company.api";
import { getServicesByUserId } from "../../../redux/api/user.api";

const Projects = () => {
  const navigate = useNavigate();

  const initialFilter = {
    date: {
      startDate: null,
      endDate: null,
    },
    service: "",
    workType: null,
    address: "",
    minCost: "",
    maxCost: "",
  };

  const columns = [
    {
      header: "Date",
      render: (row) => row?.date?.split("T")[0],
      sortable: false,
    },
    {
      header: "Service",
      render: (row) => row?.serviceData?.name,
      sortable: false,
    },
    {
      header: "Address",
      render: (row) => row?.address,
      sortable: false,
    },
    {
      header: "Actions",
      render: (row) => (
        <div className="flex items-center space-x-2">
          <button
            onClick={() => navigate(`/projects/edit/${row._id}`)}
            className="text-blue-600 hover:text-blue-900"
          >
            Edit
          </button>
          <button
            onClick={() => handleDeleteProject(row._id)}
            className="text-red-600 hover:text-red-900"
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const auth = useSelector((state) => state.authReducer);
  const [filter, setFilter] = useState(initialFilter);
  const [projectDataFilters, setProjectDataFilters] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [projectFilterPopupOpen, setProjectFilterPopupOpen] = useState(false);
  const [CSVPopupOpen, setCSVPopupOpen] = useState(false);
  const [servicesUsed, setServicesUsed] = useState([]);

  const pageCount = Math.ceil(count / itemsPerPage);

  const transformFilters = (filters) =>
    filters
      ?.map((filter) => {
        if (
          filter?.value !== null ||
          filter?.minValue !== null ||
          filter?.maxValue !== null
        ) {
          if (filter?.type === "date") {
            if (filter?.subType === "range") {
              return {
                ...filter,
                minValue: new Date(filter?.value?.startDate)
                  ?.toISOString()
                  .split("T")[0],
                maxValue: new Date(filter?.value?.endDate)
                  ?.toISOString()
                  .split("T")[0],
              };
            } else {
              return {
                ...filter,
                value: new Date(filter?.value?.startDate)
                  ?.toISOString()
                  .split("T")[0],
              };
            }
          } else if (filter?.type === "number") {
            if (filter?.subType === "range") {
              return {
                ...filter,
                minValue:
                  filter?.minValue === null ? null : Number(filter?.minValue),
                maxValue:
                  filter?.maxValue === null ? null : Number(filter?.maxValue),
              };
            } else {
              return {
                ...filter,
                value: Number(filter?.value),
              };
            }
          } else {
            return filter;
          }
        }
        return null;
      })
      ?.filter((filter) => filter !== null);

  const fetchProjects = useCallback(async () => {
    if (!auth?.user?._id) return;

    setProjectsLoading(true);
    try {
      const query = {
        service: filter?.service?._id,
        workType: filter?.workType,
        minDate: filter?.date?.startDate,
        maxDate: filter?.date?.endDate,
        minCost: filter?.minCost === "" ? null : Number(filter?.minCost),
        maxCost: filter?.maxCost === "" ? null : Number(filter?.maxCost),
        projectDataFilters: transformFilters(projectDataFilters),
        paginate: true,
        skip: itemOffset,
        limit: itemsPerPage,
      };

      let res;
      if (auth?.user?.type === "business") {
        // If user type is 'business', fetch projects by company
        res = await getProjectsCreatedByCompany(
          auth?.user?.company?._id,
          query
        );
      } else {
        // Otherwise, fetch projects created by the user
        res = await getProjectsCreatedByUser(auth?.user?._id, query);
      }

      setProjects(res.data?.projects);
      setCount(res.data?.count);
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setProjectsLoading(false);
    }
  }, [auth?.user, filter, projectDataFilters, itemOffset, itemsPerPage]);

  useEffect(() => {
    if (auth?.user?._id) {
      if (auth?.user?.type === "business") {
        getServicesByCompanyId(auth?.user?.company?._id)
          .then((res) => {
            setServicesUsed(res.data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } else {
        getServicesByUserId(auth?.user?._id)
          .then((res) => {
            setServicesUsed(res.data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }
  }, [auth?.user?._id]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const handleDeleteProject = (projectId) => {
    confirmation().then((result) => {
      if (result.isConfirmed) {
        deleteProject(projectId)
          .then(() => {
            success("Success", "Project deleted successfully");
            fetchProjects();
          })
          .catch(() => {
            failure("Error deleting project");
          });
      }
    });
  };

  const applyFilters = (updatedFilter) => {
    setFilter(updatedFilter);
    setItemOffset(0);
  };

  const createPreset = async (preset) => {
    try {
      await createFilterPreset({
        ...preset,
        filter: {
          ...filter,
          projectDataFilters: transformFilters(projectDataFilters),
        },
        user: auth?.user?._id,
      });
      success("Preset Saved Successfully");
    } catch (err) {
      failure(err?.message);
    }
  };

  const updatePreset = async (id, preset) => {
    try {
      await updateFilterPreset(id, {
        ...preset,
        filter: {
          ...filter,
          projectDataFilters: transformFilters(projectDataFilters),
        },
        user: auth?.user?._id,
      });
      success("Preset Updated Successfully");
    } catch (err) {
      failure(err?.message);
    }
  };

  return (
    <>
      <ProjectFilterPopup
        open={projectFilterPopupOpen}
        setOpen={setProjectFilterPopupOpen}
        initialFilter={initialFilter}
        filter={filter}
        setFilter={setFilter}
        projectDataFilters={projectDataFilters}
        setProjectDataFilters={setProjectDataFilters}
        applyFilters={applyFilters}
        servicesUsed={servicesUsed}
        createFilterPreset={createPreset}
        updateFilterPreset={updatePreset}
      />
      <UploadCsvPopup open={CSVPopupOpen} setOpen={setCSVPopupOpen} />
      <div className="bg-white rounded-lg shadow">
        <div className="border-b border-gray-200 rounded-t-lg bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Projects
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <button
                type="button"
                onClick={() => navigate("/projects/add")}
                className="relative inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Add New
              </button>
            </div>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <div className="flex justify-end gap-3">
            <button
              onClick={() => setProjectFilterPopupOpen(true)}
              type="button"
              className="inline-flex justify-center items-center rounded-md border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none "
            >
              <FunnelIcon
                className="h-5 w-5 text-gray-400 mr-1"
                aria-hidden="true"
              />
              Filter
              <span className="sr-only">Search</span>
            </button>
            <button
              onClick={() => setCSVPopupOpen(true)}
              type="button"
              className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
              Upload CSV
              <span className="sr-only">Search</span>
            </button>
          </div>
          <Table
            loading={projectsLoading}
            pagination={true}
            columns={columns}
            pageCount={pageCount}
            setItemOffset={setItemOffset}
            data={projects}
            dataCount={count}
            itemsPerPage={itemsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default Projects;
