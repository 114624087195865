import {
  CREATE_SERVICE,
  GET_SERVICES,
  DELETE_SERVICE,
  UPDATE_SERVICE,
} from "../types/services.types";

const serviceReducer = (services = [], action) => {
  switch (action.type) {
    case CREATE_SERVICE:
      return [action.payload, ...services];
    case GET_SERVICES:
      return action.payload.reverse();
    case DELETE_SERVICE:
      return services.filter((service) => service._id !== action.payload);
    case UPDATE_SERVICE:
      return services.map((service) =>
        service._id === action.payload._id ? action.payload : service
      );
    default:
      return services;
  }
};

export default serviceReducer;
