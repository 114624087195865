import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useEffect, useState } from "react";
import PreviewImageModal from "./PreviewImageModal";
import { RotatingLines } from "react-loader-spinner";
import { baseURL } from "../../../../redux/api/api";
import { getOfficeById } from "../../../../redux/api/office.api";
import {
  BuildingLibraryIcon,
  GlobeAltIcon,
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { BsMarkerTip, BsPinMap } from "react-icons/bs";
import { FaMarker } from "react-icons/fa";

const OfficeDetailsPopup = ({ open, setOpen, officeId }) => {
  const [loading, setLoading] = useState(true);
  const [officeDetails, setOfficeDetails] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);

  useEffect(() => {
    if (open) {
      getOfficeById(officeId)
        .then((res) => {
          console.log(res);
          setOfficeDetails(res?.data?.office);
          setCompanyDetails(res?.data?.company);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setOfficeDetails(null);
      setLoading(true);
    }
  }, [open, officeId]);

  function normalizeUrl(url) {
    // If the URL already starts with http:// or https://, return it as is
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="z-40" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-30 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-30 overflow-y-auto">
            <div className="absolute right-0 top-0 min-h-full text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white text-left shadow-xl transform transition-all sm:max-w-lg min-h-screen sm:w-96">
                  <div className="absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          Office Details
                        </Dialog.Title>
                      </div>
                    </div>
                  </div>

                  {loading ? (
                    <div className="flex justify-center items-center h-screen">
                      <RotatingLines
                        strokeColor="green"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                      />
                    </div>
                  ) : (
                    officeDetails && (
                      <div className="border-t border-gray-200 overflow-y-auto">
                        <div className="mx-4 my-3 bg-gray-50 p-5 rounded-lg">
                          <div className="flex items-center mb-3">
                            <div className="w-14 h-14 flex items-center justify-center bg-gray-200 rounded-full mr-3 text-xs">
                              {companyDetails?.logo ? (
                                <img
                                  alt={companyDetails?.name}
                                  src={`${baseURL}s3/images/${companyDetails?.logo}`}
                                  className="w-full h-full object-cover rounded-full"
                                />
                              ) : (
                                <BuildingLibraryIcon className="w-8 h-8" />
                              )}
                            </div>
                            <div>
                              <h3
                                className={`${
                                  officeDetails?.appearanceSettings?.type ===
                                  "default"
                                    ? "text-green-600"
                                    : "text-base font-semibold"
                                }`}
                                style={{
                                  color:
                                    officeDetails?.appearanceSettings?.type !==
                                    "default"
                                      ? officeDetails?.appearanceSettings
                                          ?.textColour
                                      : undefined,
                                }}
                              >
                                {companyDetails?.name}
                              </h3>
                              <p className="text-gray-500 text-sm">
                                {officeDetails?.name}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col gap-2">
                            <p className="text-gray-500 text-sm flex gap-2">
                              <span className="font-bold">
                                <GlobeAltIcon className="w-5 h-5" />
                              </span>{" "}
                              <a
                                href={normalizeUrl(companyDetails?.website)}
                                target="_blank"
                              >
                                {companyDetails?.website}
                              </a>
                            </p>
                            <p className="text-gray-500 text-sm flex gap-2">
                              <span className="font-bold">
                                <PhoneIcon className="w-5 h-5" />
                              </span>{" "}
                              <a
                                href={`tel:${officeDetails?.phoneNo}`}
                                target="_blank"
                              >
                                {officeDetails?.phoneNo}
                              </a>
                            </p>
                            <p className="text-gray-500 text-sm flex gap-2">
                              <span className="font-bold">
                                <MapPinIcon className="w-5 h-5" />
                              </span>{" "}
                              <a
                                href={`https://www.google.com/maps/search/?api=1&query=${officeDetails?.address}`}
                                target="_blank"
                              >
                                {officeDetails?.address}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default OfficeDetailsPopup;
