import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import Navbar from "../../components/Navbar";
import { Link, useLocation } from "react-router-dom";
import { GoPerson } from "react-icons/go";
import bgImg from "../../../assets/images/Mask group (1).png";
import Navigation from "../../components/Navigation";
import { FaStar } from "react-icons/fa";
import quotes from "../../../assets/images/Group.png";
import Footer from "../../components/Footer";
import { FaAngleUp } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { VscBriefcase } from "react-icons/vsc";
import { PopupWidget, PopupButton } from "react-calendly";
import Slider from "react-slick";

const reviews = [
  {
    id: 1,
    stars: 5,
    text: "I Increased my close rate by 15% in the first month using Pro-Mapper. Highly recommend it!",
    name: "Wes",
    location: "Denver",
  },
  {
    id: 2,
    stars: 5,
    text: "My customers love seeing my local projects. Pro-Mapper is amazing!",
    name: "Paula",
    location: "Los Angeles",
  },
  {
    id: 3,
    stars: 5,
    text: "Pro-Mapper is easy to use and provides my potential clients with an insight into the quality of work we provide.",
    name: "Justin",
    location: "Ohio",
  },
  {
    id: 4,
    stars: 5,
    text: "I Increased my close rate by 15% in the first month using Pro-Mapper. Highly recommend it!",
    name: "Wes",
    location: "Denver",
  },
  {
    id: 5,
    stars: 5,
    text: "My customers love seeing my local projects. Pro-Mapper is amazing! My customers love seeing my local projects. Pro-Mapper is amazing! My customers love seeing my local projects. Pro-Mapper is amazing!",
    name: "Paula",
    location: "Los Angeles",
  },
];
var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const questions = [
  {
    question: "What is Pro Mapper?",
    answer:
      "Pro Mapper is a platform designed to help individuals and businesses showcase their projects dynamically using interactive maps.",
  },
  {
    question: "How does the Individual Plan work?",
    answer:
      "The Individual Plan is tailored for salespeople and freelancers who need to showcase projects from multiple companies they have worked with. It provides tools to manage and display diverse projects in one place.",
  },
  {
    question: "What benefits does the Business Plan offer?",
    answer:
      "The Business Plan is designed for business owners to highlight their own company’s projects. It includes features to enhance business visibility, customizable project pages, and dedicated support.",
  },
  {
    question: "Can I switch between plans?",
    answer:
      "Yes, you can upgrade or downgrade your plan at any time to better suit your needs.",
  },
  {
    question: "How do I upload and manage my projects?",
    answer:
      "Once you sign up, you can easily upload project details and images through our user-friendly interface. For individual plans, you can manage projects from multiple companies, while the business plan allows you to showcase your own company’s work.",
  },
];

const individualYearly = [
  {
    name: "Basic",
    id: "ind-basic-y",
    href: "/register?type=individual&plan=basic",
    price: "Free",
    priceSuffix: "",
    description:
      "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
    features: [
      "25 Projects",
      "1 Industry",
      "3 Saved Maps",
      "Dedicated phone support",
    ],
    mostPopular: false,
    icon: GoPerson,
    button: "Sign Up",
  },
  {
    name: "Pro",
    id: "ind-pro-y",
    href: "/register?type=individual&plan=pro&frequency=yearly",
    price: "$399",
    priceSuffix: "/per year",
    description:
      "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
    features: [
      "50 Projects",
      "3 Industries",
      "5 Saved Maps",
      "Dedicated phone support",
      "Custom pins & project cards ",
      "Embed maps on website / landing pages",
      "One month free trial",
    ],
    mostPopular: false,
    icon: GoPerson,
    button: "Start Free Trial",
  },
  {
    name: "Pro Plus",
    id: "ind-pro-plus-y",
    href: "/register?type=individual&plan=pro-plus&frequency=yearly",
    price: "$799",
    priceSuffix: "/per Year",
    description:
      "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
    features: [
      "Unlimited Projects",
      "Unlimited Industries",
      "Unlimited Saved Maps",
      "Dedicated phone support",
      "Custom pins & project cards ",
      "Embed maps on website / landing pages",
      "One month free trial",
    ],
    mostPopular: false,
    icon: GoPerson,
    button: "Start Free Trial",
  },
];
const individualMonthly = [
  {
    name: "Basic",
    id: "ind-basic-m",
    href: "/register?type=individual&plan=basic",
    price: "Free",
    priceSuffix: "",
    description:
      "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
    features: [
      "25 Projects",
      "1 Industry",
      "3 Saved Maps",
      "Dedicated phone support",
    ],
    mostPopular: false,
    icon: GoPerson,
    button: "Sign Up",
  },
  {
    name: "Pro",
    id: "ind-pro-m",
    href: "/register?type=individual&plan=pro&frequency=monthly",
    price: "$39",
    priceSuffix: "/per Month",
    description:
      "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
    features: [
      "50 Projects",
      "3 Industries",
      "5 Saved Maps",
      "Dedicated phone support",
      "Custom pins & project cards ",
      "Embed maps on website / landing pages",
      "One month free trial",
    ],
    mostPopular: false,
    icon: GoPerson,
    button: "Start Free Trial",
  },
  {
    name: "Pro Plus",
    id: "ind-pro-plus-m",
    href: "/register?type=individual&plan=pro-plus&frequency=monthly",
    price: "$79",
    priceSuffix: "/per Month",
    description:
      "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
    features: [
      "Unlimited Projects",
      "Unlimited Industries",
      "Unlimited Saved Maps",
      "Dedicated phone support",
      "Custom pins & project cards ",
      "Embed maps on website / landing pages",
      "One month free trial",
    ],
    mostPopular: false,
    icon: GoPerson,
    button: "Start Free Trial",
  },
];
const businessYearly = [
  {
    name: "Basic",
    id: "bus-basic-y",
    href: "/register?type=business&plan=basic",
    price: "Free",
    priceSuffix: "",
    description:
      "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
    features: [
      "25 Projects",
      "1 Industry",
      "1 Saved Map",
      "1 Office Location",
      "Dedicated phone support",
      "1 User",
    ],
    mostPopular: false,
    icon: GoPerson,
    button: "Sign Up",
  },
  {
    name: "Business Pro",
    id: "bus-pro-y",
    href: "/register?type=business&plan=pro&frequency=yearly",
    price: "$899", // changed from $499
    priceSuffix: "/per year",
    description:
      "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
    features: [
      "50 Projects",
      "3 Industries",
      "5 Saved Maps",
      "3 Office Locations",
      "Dedicated phone support",
      // "Custom pins & project cards ",
      "Embed maps on website / landing pages",
      "5 Users",
      "One month free trial",
    ],
    mostPopular: false,
    icon: GoPerson,
    button: "Start Free Trial",
  },
  {
    name: "Business Pro Plus",
    id: "bus-pro-plus-y",
    href: "/register?type=business&plan=pro-plus&frequency=yearly",
    price: "$1299", // changed from $999
    priceSuffix: "/per Year",
    description:
      "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
    features: [
      "Unlimited Projects",
      "Unlimited Industries",
      "Unlimited Saved Maps",
      "Unlimited Office Locations",
      "Dedicated phone support",
      "Custom pins & project cards ",
      "Embed maps on website / landing pages",
      "Unlimited Users",
      "One month free trial",
    ],
    mostPopular: false,
    icon: GoPerson,
    button: "Start Free Trial",
  },
];
const businessMonthly = [
  {
    name: "Basic",
    id: "bus-basic-m",
    href: "/register?type=business&plan=basic",
    price: "Free",
    priceSuffix: "",
    description:
      "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
    features: [
      "25 Projects",
      "1 Industry",
      "1 Saved Map",
      "1 Office Location",
      "Dedicated phone support",
      "1 User",
    ],
    mostPopular: false,
    icon: GoPerson,
    button: "Sign Up",
  },
  {
    name: "Business Pro",
    id: "bus-pro-m",
    href: "/register?type=business&plan=pro&frequency=monthly",
    price: "$89", // changed from $49
    priceSuffix: "/per Month",
    description:
      "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
    features: [
      "50 Projects",
      "3 Industries",
      "5 Saved Maps",
      "3 Office Locations",
      "Dedicated phone support",
      // "Custom pins & project cards ",
      "Embed maps on website / landing pages",
      "5 Users",
      "One month free trial",
    ],
    mostPopular: false,
    icon: GoPerson,
    button: "Start Free Trial",
  },
  {
    name: "Business Pro Plus",
    id: "bus-pro-plus-m",
    href: "/register?type=business&plan=pro-plus&frequency=monthly",
    price: "$129", // changed from $99
    priceSuffix: "/per Month",
    description:
      "Aimed at salespeople who need to showcase projects from multiple companies they have worked with.",
    features: [
      "Unlimited Projects",
      "Unlimited Industries",
      "Unlimited Saved Maps",
      "Unlimited Office Locations",
      "Dedicated phone support",
      "Custom pins & project cards ",
      "Embed maps on website / landing pages",
      "Unlimited Users",
      "One month free trial",
    ],
    mostPopular: false,
    icon: GoPerson,
    button: "Start Free Trial",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PricingPlans = () => {
  const location = useLocation();

  // Function to get query parameters
  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };

  const queryParams = getQueryParams(location.search);
  const type = queryParams.get("type") || "individual";

  const [openIndex, setOpenIndex] = useState(null);
  const [activeTab, setActiveTab] = useState("yearly");

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div className="mx-auto max-w-[2000px] bg-white">
      <Navigation />
      {type === "individual" ? (
        <div className="mx-auto max-w-7xl lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <p className="uppercase mt-2 text-4xl font-bold tracking-tight text-gray-900">
              Individual plans
            </p>
          </div>
          <p className="mx-auto mt-3 max-w-3xl text-center text-lg text-gray-600">
            Our individual plans, enable you to showcase your projects boost
            client engagement effectively and increase close rates.
          </p>
          <div className="mt-5 flex flex-col items-center">
            <div className="flex -space-x-10 mb-4">
              <button
                className={`className="mt-5 shrink-0 rounded-full px-14 lg:px-20 py-2.5 font-bold text-base " ${
                  activeTab === "yearly"
                    ? "z-40 text-white bg-gradient-to-r from-[#0BAC59] to-[#044624]"
                    : "bg-white border border-green-600 text-green-600"
                }`}
                onClick={() => setActiveTab("yearly")}
              >
                Yearly
              </button>
              <button
                className={`className="mt-5 shrink-0 rounded-full px-14 lg:px-20 py-2.5 font-bold text-base" ${
                  activeTab === "monthly"
                    ? "z-40 text-white bg-gradient-to-r from-[#0BAC59] to-[#044624]"
                    : "bg-white border border-green-600 text-green-600"
                }`}
                onClick={() => setActiveTab("monthly")}
              >
                Monthly
              </button>
            </div>
            <div className="p-4 w-full">
              {activeTab === "yearly" ? (
                <div>
                  <div className="isolate mx-auto mt-10 grid gap-8 lg:mx-auto grid-cols-12">
                    {individualYearly.map((tier) => {
                      const Icon = tier.icon;
                      return (
                        <div
                          key={tier.id}
                          className={classNames(
                            tier.mostPopular
                              ? "ring-2 ring-green-600"
                              : "ring-1 ring-gray-200",
                            "rounded-lg lg:col-span-4 col-span-12"
                          )}
                        >
                          <div className="relative w-full text-center ">
                            <img
                              className="h-[28rem] absolute top-0 -z-10 w-full object-cover"
                              src={bgImg}
                              alt="Bonnie"
                            />
                            <div className="pt-4">
                              <span className=" mx-auto h-16 w-16 flex items-center justify-center rounded-full bg-green-200 bg-opacity-30">
                                <Icon className="text-4xl text-white" />
                              </span>
                              <p className="mt-2 text-xl text-white font-bold">
                                {tier.name}
                              </p>

                              <p className="px-3 mt-2 -mb-2 lg:mb-10 text-sm text-white">
                                {tier.description}
                              </p>
                              <Link
                                to={tier.href}
                                aria-describedby={tier.id}
                                className={classNames(
                                  tier.mostPopular
                                    ? "shadow-sm hover:bg-green-100"
                                    : "text-green-600 hover:ring-green-300",
                                  "lg:mx-0 mx-8 block lg:inline my-5 border-2 border-green-600 border-opacity-60 border-dashed rounded py-5 px-16 text-center text-3xl lg:text-5xl text-green-600 bg-[#ECFFF5] font-bold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                )}
                              >
                                {tier.price}
                                <span className="text-lg font-light text-gray-700">
                                  {tier.priceSuffix}{" "}
                                </span>
                              </Link>
                            </div>
                          </div>
                          <div className="h-2/5 lg:h-3/5 flex flex-col items-between justify-between p-8 xl:p-10">
                            <ul className="-mt-5 space-y-3 text-sm leading-6 text-gray-600 lg:mt-5 xl:mt-5">
                              {tier.features.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                  <CheckIcon
                                    className="h-6 w-5 flex-none text-green-600"
                                    aria-hidden="true"
                                  />
                                  {feature}
                                </li>
                              ))}
                            </ul>
                            <div className="flex items-center justify-center">
                              <Link
                                to={tier.href}
                                className="mt-5 mx-auto shrink-0 rounded-full px-10 py-2.5 font-bold text-white text-base bg-gradient-to-r from-[#D88A16] to-[#FFB800]"
                              >
                                {tier.button}
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="isolate mx-auto mt-10 grid gap-8 lg:mx-auto grid-cols-12">
                    {individualMonthly.map((tier) => {
                      const Icon = tier.icon;
                      return (
                        <div
                          key={tier.id}
                          className={classNames(
                            tier.mostPopular
                              ? "ring-2 ring-green-600"
                              : "ring-1 ring-gray-200",
                            "rounded-lg lg:col-span-4 col-span-12"
                          )}
                        >
                          <div className="relative w-full text-center ">
                            <img
                              className="h-[28rem] absolute top-0 -z-10 w-full object-cover"
                              src={bgImg}
                              alt="Bonnie"
                            />
                            <div className="pt-4">
                              <span className=" mx-auto h-16 w-16 flex items-center justify-center rounded-full bg-green-200 bg-opacity-30">
                                <Icon className="text-4xl text-white" />
                              </span>
                              <p className="mt-2 text-xl text-white font-bold">
                                {tier.name}
                              </p>

                              <p className="px-3 mt-2 -mb-2 lg:mb-10 text-sm text-white">
                                {tier.description}
                              </p>
                              <Link
                                to={tier.href}
                                aria-describedby={tier.id}
                                className={classNames(
                                  tier.mostPopular
                                    ? "shadow-sm hover:bg-green-100"
                                    : "text-green-600 hover:ring-green-300",
                                  "lg:mx-0 mx-8 block lg:inline my-5 border-2 border-green-600 border-opacity-60 border-dashed rounded py-5 px-16 text-center text-3xl lg:text-5xl text-green-600 bg-[#ECFFF5] font-bold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                )}
                              >
                                {tier.price}
                                <span className="text-lg font-light text-gray-700">
                                  {tier.priceSuffix}{" "}
                                </span>
                              </Link>
                            </div>
                          </div>
                          <div className="h-2/5 lg:h-3/5 flex flex-col items-between justify-between p-8 xl:p-10">
                            <ul className="-mt-5 space-y-3 text-sm leading-6 text-gray-600 lg:mt-5 xl:mt-5">
                              {tier.features.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                  <CheckIcon
                                    className="h-6 w-5 flex-none text-green-600"
                                    aria-hidden="true"
                                  />
                                  {feature}
                                </li>
                              ))}
                            </ul>
                            <div className="flex items-center justify-center">
                              <Link
                                to={tier.href}
                                className="mt-5 mx-auto shrink-0 rounded-full px-10 py-2.5 font-bold text-white text-base bg-gradient-to-r from-[#D88A16] to-[#FFB800]"
                              >
                                {tier.button}
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="mx-auto max-w-7xl lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <p className="uppercase mt-2 text-4xl font-bold tracking-tight text-gray-900">
              Business plans
            </p>
          </div>
          <p className="mx-auto mt-3 max-w-3xl text-center text-lg text-gray-600">
            Our business plans, enable you to showcase your projects, lower CPC
            boost client engagement, increase conversion rate, improve SEO and
            increase close rates.
          </p>
          <div className="mt-5 flex flex-col items-center">
            <div className="flex -space-x-10 mb-4">
              <button
                className={`className="mt-5 shrink-0 rounded-full px-14 lg:px-20 py-2.5 font-bold text-base " ${
                  activeTab === "yearly"
                    ? "z-40 text-white bg-gradient-to-r from-[#0BAC59] to-[#044624]"
                    : "bg-white border border-green-600 text-green-600"
                }`}
                onClick={() => setActiveTab("yearly")}
              >
                Yearly
              </button>
              <button
                className={`className="mt-5 shrink-0 rounded-full px-14 lg:px-20 py-2.5 font-bold text-base" ${
                  activeTab === "monthly"
                    ? "z-40 text-white bg-gradient-to-r from-[#0BAC59] to-[#044624]"
                    : "bg-white border border-green-600 text-green-600"
                }`}
                onClick={() => setActiveTab("monthly")}
              >
                Monthly
              </button>
            </div>
            <div className="p-4 w-full">
              {activeTab === "yearly" ? (
                <div>
                  <div className="isolate mx-auto mt-10 grid gap-8 lg:mx-auto grid-cols-12">
                    {businessYearly.map((tier) => {
                      const Icon = tier.icon;
                      return (
                        <div
                          key={tier.id}
                          className={classNames(
                            tier.mostPopular
                              ? "ring-2 ring-green-600"
                              : "ring-1 ring-gray-200",
                            "rounded-lg col-span-12 lg:col-span-4"
                          )}
                        >
                          <div className="relative w-full text-center ">
                            <img
                              className="h-[28rem] absolute top-0 -z-10 w-full object-cover"
                              src={bgImg}
                              alt="Bonnie"
                            />
                            <div className="pt-4">
                              <span className=" mx-auto h-16 w-16 flex items-center justify-center rounded-full bg-green-200 bg-opacity-30">
                                <Icon className="text-4xl text-white" />
                              </span>
                              <p className="my-2 lg:mb-14 mb-6 text-xl text-white font-bold">
                                {tier.name}
                              </p>

                              {/* <p className="px-3 mt-2 -mb-2 lg:mb-10 text-sm text-white">
                                {tier.description}
                              </p> */}
                              <Link
                                to={tier.href}
                                aria-describedby={tier.id}
                                className={classNames(
                                  tier.mostPopular
                                    ? "shadow-sm hover:bg-green-100"
                                    : "text-green-600 hover:ring-green-300",
                                  "lg:mx-0 mx-8 block lg:inline my-5 border-2 border-green-600 border-opacity-60 border-dashed rounded py-5 px-16 text-center text-3xl lg:text-5xl text-green-600 bg-[#ECFFF5] font-bold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                )}
                              >
                                {tier.price}
                                <span className="text-lg font-light text-gray-700">
                                  {tier.priceSuffix}{" "}
                                </span>
                              </Link>
                            </div>
                          </div>
                          <div className="h-2/5 lg:h-3/5 flex flex-col items-between justify-between p-8 xl:p-10">
                            <ul className="-mt-5 space-y-3 text-sm leading-6 text-gray-600 lg:mt-5 xl:mt-5">
                              {tier.features.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                  <CheckIcon
                                    className="h-6 w-5 flex-none text-green-600"
                                    aria-hidden="true"
                                  />
                                  {feature}
                                </li>
                              ))}
                            </ul>
                            <div className="flex items-center justify-center">
                              <Link
                                to={tier.href}
                                className="mt-5 mx-auto shrink-0 rounded-full px-10 py-2.5 font-bold text-white text-base bg-gradient-to-r from-[#D88A16] to-[#FFB800]"
                              >
                                {tier.button}
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="isolate mx-auto mt-10 grid gap-8 lg:mx-auto grid-cols-12">
                    {businessMonthly.map((tier) => {
                      const Icon = tier.icon;
                      return (
                        <div
                          key={tier.id}
                          className={classNames(
                            tier.mostPopular
                              ? "ring-2 ring-green-600"
                              : "ring-1 ring-gray-200",
                            "rounded-lg col-span-12 lg:col-span-4"
                          )}
                        >
                          <div className="relative w-full text-center ">
                            <img
                              className="h-[28rem] absolute top-0 -z-10 w-full object-cover"
                              src={bgImg}
                              alt="Bonnie"
                            />
                            <div className="pt-4">
                              <span className=" mx-auto h-16 w-16 flex items-center justify-center rounded-full bg-green-200 bg-opacity-30">
                                <Icon className="text-4xl text-white" />
                              </span>
                              <p className="mt-2 lg:mb-14 mb-6 text-xl text-white font-bold">
                                {tier.name}
                              </p>

                              {/* <p className="px-3 mt-2 -mb-2 lg:mb-10 text-sm text-white">
                                {tier.description}
                              </p> */}
                              <Link
                                to={tier.href}
                                aria-describedby={tier.id}
                                className={classNames(
                                  tier.mostPopular
                                    ? "shadow-sm hover:bg-green-100"
                                    : "text-green-600 hover:ring-green-300",
                                  "lg:mx-0 mx-8 block lg:inline my-5 border-2 border-green-600 border-opacity-60 border-dashed rounded py-5 px-16 text-center text-3xl lg:text-5xl text-green-600 bg-[#ECFFF5] font-bold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                )}
                              >
                                {tier.price}
                                <span className="text-lg font-light text-gray-700">
                                  {tier.priceSuffix}{" "}
                                </span>
                              </Link>
                            </div>
                          </div>
                          <div className="h-2/5 lg:h-3/5 flex flex-col items-between justify-between  p-8 xl:p-10">
                            <ul className="-mt-5 space-y-3 text-sm leading-6 text-gray-600 lg:mt-5 xl:mt-5">
                              {tier.features.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                  <CheckIcon
                                    className="h-6 w-5 flex-none text-green-600"
                                    aria-hidden="true"
                                  />
                                  {feature}
                                </li>
                              ))}
                            </ul>
                            <div className="flex items-center justify-center">
                              <Link
                                to={tier.href}
                                className="mt-5 mx-auto shrink-0 rounded-full px-10 py-2.5 font-bold text-white text-base bg-gradient-to-r from-[#D88A16] to-[#FFB800]"
                              >
                                {tier.button}
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* Have questions  */}
      <div>
        <div className="mt-5 mx-auto max-w-4xl text-center">
          <p className="uppercase mt-2 text-4xl font-bold tracking-tight text-gray-900">
            Have Questions
          </p>
        </div>
        <p className="mx-auto mt-3 max-w-3xl text-center text-lg text-gray-600">
          Email us at{" "}
          <a className="font-bold" href="mailto:info@pro-mapper.com">
            info@pro-mapper.com
          </a>
          <br />
          or
        </p>

        <div className="flex items-center justify-center">
          {/* <button className="mt-5 mx-auto shrink-0 rounded-full px-10 py-2.5 font-bold text-white text-base bg-gradient-to-r from-[#D88A16] to-[#FFB800]">
            Book a Demo
          </button> */}
          <PopupButton
            url="https://calendly.com/pro-mapper-info/30min"
            /*
             * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
             * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
             */
            rootElement={document.getElementById("root")}
            text="Book a Demo"
            className="rounded-full px-10 py-2.5 font-bold text-white text-base bg-gradient-to-r from-[#D88A16] to-[#FFB800] text-nowrap"
            pageSettings={{
              backgroundColor: "ffffff",
              hideGdprBanner: true,
              hideLandingPageDetails: true,
              primaryColor: "0da853",
              textColor: "0da853",
            }}
          />
        </div>
      </div>

      {/* users reviews  */}

      <div className="mt-10 md:px-20 px-5 lg:px-20 py-5 md:py-16 lg:py-16">
        <div className=" flex items-center justify-center">
          <div className="w-full md:w-3/5 lg:w-3/5">
            <h2 className="text-3xl md:text-4xl lg:text-4xl uppercase text-center font-bold mb-4">
              Hear from Our Happy Clients
            </h2>
            <p className="mx-auto md:w-4/5 w-full lg:w-4/5 text-gray-500  text-sm leading-relaxed text-center">
              Discover how Pro Mapper has helped businesses of all sizes
              increase their project visibility and client engagement.
            </p>
          </div>
        </div>

        <div className="mt-5 slider-container">
          <Slider {...settings}>
            {reviews.map((review) => (
              <div key={review.id} className="flex justify-center">
                <div className="mx-4 lg:col-span-4 md:col-span-6 col-span-12 h-full">
                  <div className="rounded-lg bg-gradient-to-tl from-green-600 via-transparent to-transparent p-[1px] h-full">
                    <div className="py-2 lg:py-5 px-4 w-full border border-gray-300 rounded-lg relative bg-white h-full grid grid-rows-[auto,1fr,auto]">
                      {" "}
                      {/* Grid layout */}
                      <div className="absolute right-0 bottom-0">
                        <img
                          className="w-full object-cover p-4"
                          src={quotes}
                          alt={review.name}
                        />
                      </div>
                      <div className="flex flex-col px-4">
                        <div className="flex mt-2">
                          {Array.from({ length: review.stars }).map(
                            (_, index) => (
                              <FaStar
                                key={index}
                                className="h-5 w-5 text-yellow-500"
                              />
                            )
                          )}
                        </div>
                      </div>
                      <div className="px-4 py-4 flex-grow">
                        <p className="text-lg text-gray-700">{review.text}</p>
                      </div>
                      <div className="px-4 pt-4 relative">
                        <h5 className="text-xl font-bold text-gray-900">
                          {review.name}
                        </h5>
                        <p className="text-lg text-gray-700">
                          {review.location}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {/* frequesntly asked questions  */}
      <div className="max-w-7xl mx-auto md:px-20 px-5 lg:px-20 py-3 md:py-16 lg:py-16">
        <div className="flex items-center justify-center">
          <div className="w-full md:w-3/5 lg:w-3/5">
            <h2 className="text-3xl md:text-4xl lg:text-4xl uppercase text-center font-bold mb-4">
              Frequently Asked Questions
            </h2>
          </div>
        </div>
        <div>
          {questions.map((q, index) => (
            <div
              key={index}
              className="my-5 border border-gray-100 group bg-white shadow rounded-lg py-4"
            >
              <div className="px-4 pr-3 lg:pr-6 py-2">
                <button
                  className="w-full flex justify-between items-center text-left focus:outline-none"
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="ml-2 lg:ml-4 text-xl font-bold text-gray-800">
                    {index + 1}. {q.question}
                  </span>
                  <span className="mt-0.5 ml-2 text-gray-800 text-lg">
                    {openIndex === index ? <FaAngleUp /> : <FaAngleDown />}
                  </span>
                </button>
              </div>
              <div
                className={`transition-all duration-700 ease-in-out overflow-hidden ${
                  openIndex === index ? "max-h-screen" : "max-h-0"
                }`}
              >
                <div className="px-2 lg:px-4 group-hover:bg-transparent">
                  <div className="py-1 px-4">
                    <p className="text-lg text-gray-700">{q.answer}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PricingPlans;
