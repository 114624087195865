import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Select from "react-select";
import Datepicker from "react-tailwindcss-datepicker";
import ViewFilterFields from "../../components/ViewFilterFields";
import { getAllFilters } from "../../../redux/api/user.api";
import { getServicesByUserProjects } from "../../../redux/api/project.api";

const ProjectFilterPopup = ({
  filterPreset,
  open,
  setOpen,
  initialFilter,
  filter,
  setFilter,
  projectDataFilters,
  setProjectDataFilters,
  applyFilters,
  createFilterPreset,
  updateFilterPreset,
  servicesUsed,
  mapCenter,
  mapZoom,
}) => {
  const scaleClass = open ? "scale-100" : "scale-0";
  const [name, setName] = useState("");
  const [enableMapLocation, setEnableMapLocation] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedWorkType, setSelectedWorkType] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [isNameValid, setIsNameValid] = useState(true);
  const [hasAttemptedSave, setHasAttemptedSave] = useState(false);

  useEffect(() => {
    if (filterPreset && servicesUsed?.length > 0) {
      const foundService = servicesUsed?.find(
        (service) => service?._id === filterPreset?.filter?.service
      );
      const foundWorkType = foundService?.workTypes?.find(
        (workType) => workType?._id === filterPreset?.filter?.workType
      );
      setSelectedService({
        value: foundService?._id,
        label: foundService?.name,
        workTypes: foundService?.workTypes,
      });
      setSelectedWorkType({
        value: foundWorkType?._id,
        label: foundWorkType?.name,
      });
      setName(filterPreset?.name);
      setFilter({
        service: foundService,
        workType: foundWorkType?._id,
        date: {
          startDate: filterPreset?.filter?.minDate,
          endDate: filterPreset?.filter?.maxDate,
        },
        minCost: filterPreset?.filter?.minCost,
        maxCost: filterPreset?.filter?.maxCost,
      });
    }
  }, [filterPreset, servicesUsed]);

  useEffect(() => {
    // If filterPreset is null, Only check for filter.service._id
    // If filterPreset is not null, check for filterPreset.filter.projectDataFilters.length

    if (filter?.service?._id && !filterPreset) {
      getAllFilters(filter?.service?._id)
        .then((res) => {
          setFilterData(res.data);
          let projectDataFiltersArray = [];
          if (filterPreset?.filter?.projectDataFilters?.length > 0) {
            projectDataFiltersArray = res.data?.map((filter) => ({
              type: filter?.type,
              subType: filter?.subType,
              name: filter?.name,
              value: filterPreset?.filter?.projectDataFilters?.find(
                (projectDataFilter) => projectDataFilter?.name === filter?.name
              )?.value,
              minValue: filterPreset?.filter?.projectDataFilters?.find(
                (projectDataFilter) => projectDataFilter?.name === filter?.name
              )?.minValue,
              maxValue: filterPreset?.filter?.projectDataFilters?.find(
                (projectDataFilter) => projectDataFilter?.name === filter?.name
              )?.maxValue,
            }));
          } else {
            projectDataFiltersArray = res.data?.map((filter) => ({
              type: filter?.type,
              subType: filter?.subType,
              name: filter?.name,
              value: null,
              minValue: null,
              maxValue: null,
            }));
          }

          setProjectDataFilters(projectDataFiltersArray);

          // if (filterPreset) {
          //   getProjectsFromDatabase({
          //     ...filterPreset?.filter,
          //     projectDataFilters: projectDataFiltersArray,
          //   });
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (
      filter?.service?._id &&
      filterPreset?.filter?.projectDataFilters?.length > 0
    ) {
      getAllFilters(filter?.service?._id)
        .then((res) => {
          setFilterData(res.data);
          let projectDataFiltersArray = [];
          if (filterPreset?.filter?.projectDataFilters?.length > 0) {
            projectDataFiltersArray = res.data?.map((filter) => ({
              type: filter?.type,
              subType: filter?.subType,
              name: filter?.name,
              value: filterPreset?.filter?.projectDataFilters?.find(
                (projectDataFilter) => projectDataFilter?.name === filter?.name
              )?.value,
              minValue: filterPreset?.filter?.projectDataFilters?.find(
                (projectDataFilter) => projectDataFilter?.name === filter?.name
              )?.minValue,
              maxValue: filterPreset?.filter?.projectDataFilters?.find(
                (projectDataFilter) => projectDataFilter?.name === filter?.name
              )?.maxValue,
            }));
          } else {
            projectDataFiltersArray = res.data?.map((filter) => ({
              type: filter?.type,
              subType: filter?.subType,
              name: filter?.name,
              value: null,
              minValue: null,
              maxValue: null,
            }));
          }

          setProjectDataFilters(projectDataFiltersArray);

          // if (filterPreset) {
          //   getProjectsFromDatabase({
          //     ...filterPreset?.filter,
          //     projectDataFilters: projectDataFiltersArray,
          //   });
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [filter?.service?._id, filterPreset?.filter?.projectDataFilters]);

  function resetProjectFields() {
    setFilter(initialFilter);
    setSelectedService(null);
    setSelectedWorkType(null);
    setFilterData([]);
    setProjectDataFilters([]);
    setEnableMapLocation(false);
  }

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (hasAttemptedSave) {
      setIsNameValid(true); // Reset validation state on typing if save was attempted
    }
  };
  const handleLocationChange = (e) => {
    const { checked } = e.target;
    setEnableMapLocation(checked);
    if (!checked) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        mapCenter: { lat: null, lng: null },
        mapZoom: null,
      }));
    }
  };
  const handleClose = (e) => {
    setOpen(false);
    setIsNameValid(true);
    setHasAttemptedSave(false);
    setName("");
    setEnableMapLocation(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="" onClose={handleClose}>
        <Transition.Child
          enter="ease-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 transform scale-100"
          leaveTo="opacity-0 transform scale-0"
        >
          <div className="z-30 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30">
          <div className="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opacity-0 transform scale-0"
              enterTo="opacity-100 transform scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 transform scale-100"
              leaveTo="opacity-0 transform scale-0"
            >
              <Dialog.Panel
                className={`ml-0 lg:ml-48 md:ml-48 w-4/6 lg:w-3/6 ${scaleClass} fixed mx-auto rounded-lg bg-white pt-5 pb-4 text-left shadow-xl transition-all  h-auto`}
              >
                <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className=" rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className=" px-4 pb-4">
                  <div className=" sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Filter Projects
                    </Dialog.Title>
                  </div>
                </div>
                <div className="px-5 flex justify-between items-center col-span-12">
                  <h3 className="text-base ">Filters</h3>
                  <button
                    className=" bg-gray-100 hover:bg-opacity-90 text-dark text-sm py-2 px-5 rounded"
                    onClick={() => {
                      resetProjectFields();
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div className=" h-80 overflow-y-scroll">
                  <div className="p-5">
                    <div className="mt-3 gap-4 grid grid-cols-1 sm:grid-cols-12 lg:grid-cols-12 md:grid-cols-12">
                      <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 ">
                        <div className="mx-1">
                          <Select
                            options={servicesUsed?.map((service) => ({
                              value: service.serviceId._id,
                              label: service.serviceId.name,
                              workTypes: service.workTypes,
                            }))}
                            value={selectedService}
                            onChange={(selectedOption) => {
                              setSelectedService(selectedOption);
                              setSelectedWorkType(null);
                              setFilter({
                                ...filter,
                                service: {
                                  _id: selectedOption.value,
                                  name: selectedOption.label,
                                  workTypes: selectedOption.workTypes,
                                },
                                workType: null,
                              });
                            }}
                            placeholder="Select Service"
                          />
                        </div>
                      </div>
                      <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 ">
                        <div className="mx-1">
                          {" "}
                          <Select
                            options={(selectedService?.workTypes || []).map(
                              (workType) => ({
                                value: workType._id,
                                label: workType.name,
                              })
                            )}
                            value={selectedWorkType}
                            onChange={(selectedOption) => {
                              setSelectedWorkType(selectedOption);
                              setFilter({
                                ...filter,
                                workType: selectedOption?.value,
                              });
                            }}
                            placeholder="Select Project Type"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 gap-4 grid grid-cols-1 sm:grid-cols-12 lg:grid-cols-12 md:grid-cols-12">
                      <div className="col-span-12 ">
                        <div className="mx-1">
                          <Datepicker
                            placeholder="Date Range"
                            value={filter?.date}
                            onChange={(value) => {
                              setFilter({
                                ...filter,
                                date: value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-span-12 ">
                        <div className="mx-1">
                          <div className="flex justify-between items-center gap-4">
                            <input
                              type="number"
                              placeholder="Min Cost"
                              className="block w-full rounded-md border-gray-300 focus:border-green-600 focus:ring-green-600 sm:text-sm py-2.5 px-3"
                              value={filter?.minCost}
                              onChange={(e) => {
                                setFilter({
                                  ...filter,
                                  minCost: e.target.value,
                                });
                              }}
                            />
                            <input
                              type="number"
                              placeholder="Max Cost"
                              className="block w-full rounded-md border-gray-300 focus:border-green-600 focus:ring-green-600 sm:text-sm py-2.5 px-3"
                              value={filter?.maxCost}
                              onChange={(e) => {
                                setFilter({
                                  ...filter,
                                  maxCost: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <ViewFilterFields
                      filterData={filterData}
                      filter={filter}
                      setFilter={setFilter}
                      projectDataFilters={projectDataFilters}
                      setProjectDataFilters={setProjectDataFilters}
                    />
                  </div>
                </div>
                <div className="px-5 mt-3">
                  <div className="flex justify-between items-center space-x-4">
                    <button
                      className="w-full bg-green-600 hover:bg-green-700 text-white font-semibold py-2.5 px-3 rounded-md"
                      onClick={() => {
                        applyFilters();
                        setOpen(false);
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ProjectFilterPopup;
