import React, { useState } from "react";
import Table from "../../components/Table";
import visaImg from "../../../assets/images/visa.png";
import { createSupport } from "../../../redux/api/support.api";
import { useSelector } from "react-redux";
import { success } from "../../../utils/notification";
import { RotatingLines } from "react-loader-spinner";

const initialData = {
  subject: "",
  body: "",
};

const Support = () => {
  const auth = useSelector((state) => state.authReducer);
  const [support, setSupport] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSupport({ ...support, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    createSupport({
      email: auth?.user?.email,
      subject: support?.subject,
      body: support?.body,
    })
      .then((res) => {
        success(
          "Ticket Created",
          "Your ticket has been created successfully. We'll get back to you soon."
        );
        setLoading(false);
        setSupport(initialData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="lg:mx-auto md:px-1 lg:px-4">
        <div className="grid grid-cols-1 sm:grid-cols-12 lg:grid-cols-12 md:grid-cols-12">
          {/* Left column */}
          <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 ">
            <div className="bg-white rounded-lg shadow mb-4">
              <form onSubmit={handleSubmit}>
                <div className="border-b border-gray-200 rounded-t-lg bg-white px-4 py-5 sm:px-6">
                  <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                    <div className="ml-4 mt-2">
                      <h3 className="text-base font-semibold leading-6 text-gray-900">
                        Support
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <div className="grid sm:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 gap-6">
                    <div className=" lg:col-span-6 md:col-span-6 sm:col-span-12 ">
                      <div className="py-3">
                        <div className="space-y-1 px-4 ">
                          <div className="">
                            <label
                              htmlFor="subject"
                              className="mt-3 block text-sm font-medium text-gray-700"
                            >
                              Subject
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="subject"
                                id="subject"
                                value={support?.subject}
                                onChange={handleChange}
                                className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder="Type here..."
                              />
                            </div>
                          </div>
                          <div className="">
                            <label
                              htmlFor="body"
                              className="mt-3 block text-sm font-medium text-gray-700"
                            >
                              Description
                            </label>
                            <div className="mt-1">
                              <textarea
                                rows={6}
                                name="body"
                                id="body"
                                value={support?.body}
                                onChange={handleChange}
                                className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder="Type here..."
                              />
                            </div>
                          </div>
                          <div className="ml-auto text-right pt-4">
                            <button
                              type="submit"
                              disabled={loading}
                              className={`${
                                loading
                                  ? "bg-green-700 hover:bg-green-700 cursor-not-allowed"
                                  : ""
                              } relative inline-flex items-center justify-center rounded-md bg-green-600 px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 w-24`}
                            >
                              {loading ? (
                                <RotatingLines
                                  strokeColor="white"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  width="20"
                                  visible={true}
                                />
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* Right */}
          <div className="lg:col-span-4 md:col-span-12 sm:col-span-12 "></div>
        </div>
      </div>
    </>
  );
};

export default Support;
