import { Fragment, useEffect, useState } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3BottomLeftIcon,
  BellIcon,
  CreditCardIcon,
  MapIcon,
  MapPinIcon,
  Cog6ToothIcon,
  QuestionMarkCircleIcon,
  RectangleGroupIcon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import ProjectsMapIcon from "../assets/images/Projects-Maps.png";
import SavedMapIcon from "../assets/images/Saved-Maps.png";
import MapSettings from "../assets/images/Map-Settings.png";
import Logo from "../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../redux/api/api";
import { FaRegCircleUser } from "react-icons/fa6";

const initialData = {
  profileImage: "",
  firstName: "",
  lastName: "",
  title: "",
  email: "",
  phoneNo: "",
};
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CompanyLayout = ({ children, page }) => {
  const auth = useSelector((state) => state.authReducer);
  const [user, setUser] = useState(initialData);

  useEffect(() => {
    if (auth?.user) {
      setUser(auth?.user);
    }
  }, [auth]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigation =
    auth?.user?.plan === "basic"
      ? [
          {
            name: "Dashboard",
            to: "/dashboard",
            heroicon: UserCircleIcon,
            current: page === "Dashboard",
          },
          {
            name: "Profile",
            to: `${
              auth?.user?.role === "company" ? "/company-profile" : "/profile"
            }`,
            heroicon: UserCircleIcon,
            current: page === "Dashboard",
          },
          {
            name: "Projects",
            to: "/projects",
            heroicon: RectangleGroupIcon,
            current: page === "Projects",
          },
          {
            name: "Projects Map",
            to: "/projects/map",
            customicon: ProjectsMapIcon,
            current: page === "Projects",
          },
          {
            name: "Saved Maps",
            to: "/filterpresets",
            customicon: SavedMapIcon,
            current: page === "filterpresets",
          },
          {
            name: "Leads",
            to: "/partners",
            heroicon: MapIcon,
            current: page === "Partners",
          },
          {
            name: "Billing",
            to: "/billing",
            heroicon: CreditCardIcon,
            current: page === "Billing",
          },
          {
            name: "Support",
            to: "/support",
            heroicon: QuestionMarkCircleIcon,
            current: page === "Support",
          },
        ]
      : auth?.user?.plan === "pro"
      ? [
          {
            name: "Dashboard",
            to: "/dashboard",
            heroicon: RectangleGroupIcon,
            current: page === "Dashboard",
          },
          {
            name: "Profile",
            to: `${
              auth?.user?.role === "company" ? "/company-profile" : "/profile"
            }`,
            heroicon: UserCircleIcon,
            current: page === "Dashboard",
          },
          {
            name: "Projects",
            to: "/projects",
            heroicon: RectangleGroupIcon,
            current: page === "Projects",
          },
          {
            name: "Projects Map",
            to: "/projects/map",
            customicon: ProjectsMapIcon,
            current: page === "Projects",
          },
          {
            name: "Saved Maps",
            to: "/filterpresets",
            customicon: SavedMapIcon,
            current: page === "filterpresets",
          },
          // {
          //   name: "Appearance Settings",
          //   to: "/appearance-settings",
          //   customicon: MapSettings,
          //   current: page === "appearance-settings",
          // },
          {
            name: "Leads",
            to: "/partners",
            heroicon: MapIcon,
            current: page === "Partners",
          },
          {
            name: "Billing",
            to: "/billing",
            heroicon: CreditCardIcon,
            current: page === "Billing",
          },
          {
            name: "Support",
            to: "/support",
            heroicon: QuestionMarkCircleIcon,
            current: page === "Support",
          },
        ]
      : auth?.user?.plan === "pro-plus"
      ? [
          {
            name: "Dashboard",
            to: "/dashboard",
            heroicon: RectangleGroupIcon,
            current: page === "Dashboard",
          },
          {
            name: "Profile",
            to: `${
              auth?.user?.role === "company" ? "/company-profile" : "/profile"
            }`,
            heroicon: UserCircleIcon,
            current: page === "Dashboard",
          },
          {
            name: "Projects",
            to: "/projects",
            heroicon: RectangleGroupIcon,
            current: page === "Projects",
          },
          {
            name: "Projects Map",
            to: "/projects/map",
            customicon: ProjectsMapIcon,
            current: page === "Projects",
          },
          {
            name: "Saved Maps",
            to: "/filterpresets",
            customicon: SavedMapIcon,
            current: page === "filterpresets",
          },
          {
            name: "Appearance Settings",
            to: "/appearance-settings",
            customicon: MapSettings,
            current: page === "appearance-settings",
          },
          {
            name: "Leads",
            to: "/partners",
            heroicon: MapIcon,
            current: page === "Partners",
          },
          {
            name: "Billing",
            to: "/billing",
            heroicon: CreditCardIcon,
            current: page === "Billing",
          },
          {
            name: "Support",
            to: "/support",
            heroicon: QuestionMarkCircleIcon,
            current: page === "Support",
          },
        ]
      : [
          {
            name: "Profile",
            to: `${
              auth?.user?.role === "company" ? "/company-profile" : "/profile"
            }`,
            heroicon: UserCircleIcon,
            current: page === "Dashboard",
          },
          {
            name: "Projects",
            to: "/projects",
            heroicon: RectangleGroupIcon,
            current: page === "Projects",
          },
          {
            name: "Projects Map",
            to: "/projects/map",
            customicon: ProjectsMapIcon,
            current: page === "Projects",
          },
          {
            name: "Leads",
            to: "/partners",
            heroicon: MapIcon,
            current: page === "Partners",
          },
          {
            name: "Billing",
            to: "/billing",
            heroicon: CreditCardIcon,
            current: page === "Billing",
          },
          {
            name: "Support",
            to: "/support",
            heroicon: QuestionMarkCircleIcon,
            current: page === "Support",
          },
        ];

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    {/* <img className="w-full" src={Logo} alt="Your Company" /> */}
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.to}
                          className={classNames(
                            item.current
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          {/* <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          /> */}
                          {item.heroicon ? (
                            <item.heroicon
                              className={classNames(
                                item.current
                                  ? "text-gray-500"
                                  : "text-gray-400 group-hover:text-gray-500",
                                "mr-4 flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                          ) : (
                            <img
                              src={item.customicon}
                              className={classNames(
                                item.current
                                  ? "text-gray-500"
                                  : "text-gray-400 group-hover:text-gray-500",
                                "mr-4 flex-shrink-0 h-6 w-6"
                              )}
                              alt=""
                            />
                          )}
                          {item.name}
                        </Link>
                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-48 md:flex-col z-50">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
            <div className="flex flex-shrink-0 items-center px-4">
              <img className="w-full" src={Logo} alt="Your Company" />
            </div>
            <div className="mt-5 flex flex-grow flex-col">
              <nav className="flex-1 space-y-1 px-2 pb-4">
                {navigation.map((item) =>
                  !item.children ? (
                    <Link
                      key={item.name}
                      to={item.to}
                      className={classNames(
                        item.current
                          ? "bg-gray-100 text-gray-900"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      )}
                    >
                      {item.heroicon ? (
                        <item.heroicon
                          className={classNames(
                            item.current
                              ? "text-gray-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "mr-4 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                      ) : (
                        <img
                          src={item.customicon}
                          className={classNames(
                            item.current
                              ? "text-gray-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "mr-4 flex-shrink-0 h-6 w-6"
                          )}
                          alt=""
                        />
                      )}

                      {item.name}
                    </Link>
                  ) : (
                    <Disclosure as="div" key={item.name} className="space-y-1">
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={classNames(
                              item.current
                                ? "bg-gray-100 text-gray-900"
                                : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                              "group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                            )}
                          >
                            {/* <item.icon
                              className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            /> */}
                            {item.heroicon ? (
                              <item.heroicon
                                className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                            ) : (
                              <img
                                src={item.customicon}
                                className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                alt=""
                              />
                            )}
                            <span className="flex-1">{item.name}</span>
                            <svg
                              className={classNames(
                                open
                                  ? "text-gray-400 rotate-90"
                                  : "text-gray-300",
                                "ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400"
                              )}
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                            >
                              <path
                                d="M6 6L14 10L6 14V6Z"
                                fill="currentColor"
                              />
                            </svg>
                          </Disclosure.Button>
                          <Disclosure.Panel className="space-y-1">
                            {item.children.map((subItem) => (
                              <Link
                                key={subItem.name}
                                to={subItem.to}
                                className="group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                              >
                                {subItem.name}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )
                )}
              </nav>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-48">
          <div className="sticky top-0 flex h-16 flex-shrink-0 bg-white shadow z-30">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4">
              <div className="flex flex-1 items-center">
                <h1 className="text-lg font-bold">
                  {auth?.user?.company?.name}
                </h1>
                {/* <form className="flex w-full md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                      <MagnifyingGlassIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      id="search-field"
                      className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                      placeholder="Search"
                      type="search"
                      name="search"
                    />
                  </div>
                </form> */}
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                <button
                  type="button"
                  className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      {user?.profileImage ? (
                        <img
                          className="object-cover h-8 w-8 rounded-full"
                          src={`${baseURL}s3/images/${user.profileImage}`}
                          alt=""
                        />
                      ) : (
                        <FaRegCircleUser className="h-8 w-8 rounded-full" />
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              dispatch(logout(navigate));
                            }}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 w-100"
                            )}
                          >
                            Sign Out
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <div className="p-4 bg-gray-50 min-h-screen">{children}</div>
        </div>
      </div>
    </>
  );
};

export default CompanyLayout;
