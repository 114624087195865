import { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import { BsHeart } from "react-icons/bs";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { BiUser } from "react-icons/bi";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/Logo 1.png";

const navigation = [
  { name: "About", href: "/about" },
  { name: "How It Works", href: "/" },
  { name: "Contact", href: "/contact" },
];

export default function Navigation() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <div className="max-w-[2000px] px-2 md:px-5 lg:px-5 py-5">
      <div className="px-1 md:px-8 lg:px-8 ">
        <nav
          className="flex items-center justify-between border-b-2 border-gray-200 pb-5"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <Link to="/" className="">
              <img className="h-16" src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="gap-2 flex lg:hidden">
            {/* <Bars3Icon className="h-6 w-6" aria-hidden="true" /> */}
            <Link
              to="/login"
              className="rounded-full px-4 py-2 font-bold text-gray-800 border-2 border-gray-800 text-sm"
            >
              Login
            </Link>
            <Link
              to="/pricing?type=business"
              className="shrink-0 rounded-full px-4 py-2 font-bold text-white text-base bg-gradient-to-r from-[#D88A16] to-[#FFB800]"
            >
              Sign Up for Free
            </Link>
          </div>
          <div className="hidden lg:flex  items-center lg:gap-x-3">
            {/* {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className="px-5 py-1 text-lg leading-6 text-green-600 font-semibold"
              >
                {item.name}
              </Link>
            ))} */}
            <Link
              to="/login"
              className="shrink-0 rounded-full px-10 py-2 font-bold text-gray-800 border-2 border-gray-800 text-base"
            >
              Login
            </Link>

            <Link
              to="/pricing?type=business"
              className="shrink-0 rounded-full px-10 py-2.5 font-bold text-white text-base bg-gradient-to-r from-[#D88A16] to-[#FFB800]"
            >
              Sign Up for Free
            </Link>
          </div>
        </nav>
        <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <Dialog.Panel
            focus="true"
            className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden"
          >
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Pro Mapper</span>
                <img
                  className="h-8"
                  src="https://tailwindui.com/img/logos/mark.svg?color=green&shade=600"
                  alt=""
                />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </div>
    </div>
  );
}
