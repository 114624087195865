import jwtDecode from "jwt-decode";
import { failure, warning, loginSuccess } from "../../utils/notification";
import { signIn, signUp, getCurrentUserById } from "../api/auth.api";
import { getCompany } from "../api/company.api";
import { LOGIN, REGISTER, SET_USER, LOGOUT } from "../types/auth.types";

export const login = (formData, navigate, setLoading) => async (dispatch) => {
  try {
    const { data, status } = await signIn(formData);
    if (status === 200) {
      dispatch({ type: LOGIN, payload: data });
      loginSuccess();
      if (data?.result?.role === "company") {
        navigate(`/company-profile`);
      } else {
        navigate(`/profile`);
      }
      setLoading(false);
    } else {
      warning(data.message);
      setLoading(false);
    }
  } catch (error) {
    failure(error.message);
    setLoading(false);
  }
};

export const getCurrentUser = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");
    if (token) {
      // Decode token to get user data
      const decodedToken = jwtDecode(token);
      const { data, status } = await getCurrentUserById(decodedToken?.id);
      if (status === 200) {
        dispatch({ type: SET_USER, payload: data });
      } else {
        localStorage.removeItem("token");
      }
    }
  } catch (error) {
    localStorage.removeItem("token");
    failure(error.message);
  }
};

export const register = (formData, navigate) => async (dispatch) => {
  try {
    const { data, status } = await signUp(formData);
    if (status === 200) {
      dispatch({ type: REGISTER, payload: data });
      // loginSuccess();
      // navigate(`/profile`);
      return { success: true, data: data };
    } else {
      warning(data.message);
      return { success: false, message: data.message };
    }
  } catch (error) {
    console.log(error);
    failure(error.response.data.error);
    return { success: false, message: error.response.data.error };
  }
};

export const logout = (navigate) => async (dispatch) => {
  dispatch({ type: LOGOUT });
  navigate(`/`);
};
