import React, { useEffect, useState } from "react";
import {
  deleteFilterPreset,
  getFilterPresets,
  getFilterPresetsByCompany,
  updateAllowMapPan,
  updateAllowMapZoom,
  updateEnableMapLocation,
  updateShowProjectCost,
} from "../../../../redux/api/filterPresets.api";
import { useSelector } from "react-redux";
import { IoMdCheckboxOutline } from "react-icons/io";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AiOutlineCheck } from "react-icons/ai";
import { GoPencil } from "react-icons/go";
import { BsTrash3 } from "react-icons/bs";
import { confirmation, success } from "../../../../utils/notification";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/Table";
import { RotatingLines } from "react-loader-spinner";

function FilterPresets() {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authReducer);
  const [presets, setPresets] = useState([]);
  const [copiedStatus, setCopiedStatus] = useState({});
  const [copiedEmbedCodeStatus, setCopiedEmbedCodeStatus] = useState({});
  const [enableMapLocationLoading, setEnableMapLocationLoading] =
    useState(null);
  const [showCostLoading, setShowCostLoading] = useState(null);
  const [allowMapZoomLoading, setAllowMapZoomLoading] = useState(null);
  const [allowMapPanLoading, setAllowMapPanLoading] = useState(null);
  const [appearanceSettingsOptions, setAppearanceSettingsOptions] = useState(
    []
  );
  const [appearanceSettings, setAppearanceSettings] = useState(null);
  const [loading, setLoading] = useState(false);

  const columns =
    auth?.user?.plan !== "basic"
      ? [
          {
            header: "Name",
            render: (preset) => preset?.name,
            sortable: false,
          },
          {
            header: "Map Location saved",
            render: (preset) => (
              <div className="my-2">
                {enableMapLocationLoading === preset._id ? (
                  <RotatingLines height={20} width={20} color="#4fa94d" />
                ) : (
                  <input
                    type="checkbox"
                    checked={preset.enableMapLocation}
                    onChange={() => {
                      setEnableMapLocationLoading(preset._id);
                      updateEnableMapLocation(
                        preset._id,
                        !preset.enableMapLocation
                      )
                        .then((res) => {
                          setPresets((prevPresets) =>
                            prevPresets.map((item) =>
                              item._id === preset._id
                                ? {
                                    ...item,
                                    enableMapLocation:
                                      res?.data?.enableMapLocation,
                                  }
                                : item
                            )
                          );
                          setEnableMapLocationLoading(null);
                        })
                        .catch((error) => {
                          console.error("Error updating preset:", error);
                        });
                    }}
                    id={`enableMapLocation-${preset._id}`}
                    className="form-checkbox h-4 w-4 text-green-500 rounded"
                  />
                )}
              </div>
            ),
            sortable: false,
          },
          {
            header: "Show Cost",
            render: (preset) => (
              <div className="my-2">
                {showCostLoading === preset._id ? (
                  <RotatingLines height={20} width={20} color="#4fa94d" />
                ) : (
                  <input
                    type="checkbox"
                    checked={preset.showProjectCost}
                    onChange={() => {
                      setShowCostLoading(preset._id);
                      updateShowProjectCost(preset._id, !preset.showProjectCost)
                        .then((res) => {
                          setPresets((prevPresets) =>
                            prevPresets.map((item) =>
                              item._id === preset._id
                                ? {
                                    ...item,
                                    showProjectCost: res?.data?.showProjectCost,
                                  }
                                : item
                            )
                          );
                          setShowCostLoading(null);
                        })
                        .catch((error) => {
                          console.error("Error updating preset:", error);
                        });
                    }}
                    id={`showCost-${preset._id}`}
                    className="form-checkbox h-4 w-4 text-green-500 rounded"
                  />
                )}
              </div>
            ),
            sortable: false,
          },
          {
            header: "Allow Zoom",
            render: (preset) => (
              <div className="my-2">
                {allowMapZoomLoading === preset._id ? (
                  <RotatingLines height={20} width={20} color="#4fa94d" />
                ) : (
                  <input
                    type="checkbox"
                    checked={preset.allowMapZoom}
                    onChange={() => {
                      setAllowMapZoomLoading(preset._id);
                      updateAllowMapZoom(preset._id, !preset.allowMapZoom)
                        .then((res) => {
                          setPresets((prevPresets) =>
                            prevPresets.map((item) =>
                              item._id === preset._id
                                ? {
                                    ...item,
                                    allowMapZoom: res?.data?.allowMapZoom,
                                  }
                                : item
                            )
                          );
                          setAllowMapZoomLoading(null);
                        })
                        .catch((error) => {
                          console.error("Error updating preset:", error);
                        });
                    }}
                    id={`allowMapZoom-${preset._id}`}
                    className="form-checkbox h-4 w-4 text-green-500 rounded"
                  />
                )}
              </div>
            ),
            sortable: false,
          },
          {
            header: "Allow Pan",
            render: (preset) => (
              <div className="my-2">
                {allowMapPanLoading === preset._id ? (
                  <RotatingLines height={20} width={20} color="#4fa94d" />
                ) : (
                  <input
                    type="checkbox"
                    checked={preset.allowMapPan}
                    onChange={() => {
                      setAllowMapPanLoading(preset._id);
                      updateAllowMapPan(preset._id, !preset.allowMapPan)
                        .then((res) => {
                          setPresets((prevPresets) =>
                            prevPresets.map((item) =>
                              item._id === preset._id
                                ? {
                                    ...item,
                                    allowMapPan: res?.data?.allowMapPan,
                                  }
                                : item
                            )
                          );
                          setAllowMapPanLoading(null);
                        })
                        .catch((error) => {
                          console.error("Error updating preset:", error);
                        });
                    }}
                    id={`allowMapPan-${preset._id}`}
                    className="form-checkbox h-4 w-4 text-green-500 rounded"
                  />
                )}
              </div>
            ),
            sortable: false,
          },
          {
            header: "Share Preset",
            render: (preset) => {
              const userType = auth?.user?.type;
              const url =
                userType === "individual"
                  ? `${process.env.REACT_APP_PM_URL}/${auth?.user?._id}/projects?filter=${preset?._id}`
                  : `${process.env.REACT_APP_PM_URL}/company/${auth?.user?.company?._id}/projects?filter=${preset?._id}`;

              return (
                <CopyToClipboard
                  text={url}
                  onCopy={() => handleCopy(preset._id)}
                >
                  <button
                    type="button"
                    className={`mt-2 inline-flex justify-center items-center rounded-md border border-gray-300 bg-white px-5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none ${
                      copiedStatus[preset._id]
                        ? "bg-green-200 text-green-600"
                        : ""
                    }`}
                  >
                    {copiedStatus[preset._id] ? (
                      <>
                        <span>Copied</span>
                        <AiOutlineCheck className="ml-1" />
                      </>
                    ) : (
                      "Copy url"
                    )}
                  </button>
                </CopyToClipboard>
              );
            },
            sortable: false,
          },
          {
            header: "Copy Embed Code",
            render: (preset) => {
              const userType = auth?.user?.type;
              const url =
                userType === "individual"
                  ? `${process.env.REACT_APP_PM_URL}/${auth?.user?._id}/projects?filter=${preset?._id}`
                  : `${process.env.REACT_APP_PM_URL}/company/${auth?.user?.company?._id}/projects?filter=${preset?._id}`;

              return (
                <CopyToClipboard
                  text={`<iframe src="${url}" width="100%" height="600px"></iframe>`}
                  onCopy={() => handleCopyEmbedCode(preset._id)}
                >
                  <button
                    type="button"
                    className={`mt-2 inline-flex justify-center items-center rounded-md border border-gray-300 bg-white px-5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none ${
                      copiedEmbedCodeStatus[preset._id]
                        ? "bg-green-200 text-green-600"
                        : ""
                    }`}
                  >
                    {copiedEmbedCodeStatus[preset._id] ? (
                      <>
                        <span>Copied</span>
                        <AiOutlineCheck className="ml-1" />
                      </>
                    ) : (
                      "Copy Embed Code"
                    )}
                  </button>
                </CopyToClipboard>
              );
            },
          },
          {
            header: "Actions",
            render: (preset) => (
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => handleDeletePreset(preset?._id)}
                  className="ml-3 text-xl h-8 w-8 flex items-center justify-center bg-red-100 text-red-700 py-0.5 px-2 rounded-md"
                >
                  <BsTrash3 />
                </button>
              </div>
            ),
            sortable: false,
          },
        ]
      : [
          {
            header: "Name",
            render: (preset) => preset?.name,
            sortable: false,
          },
          {
            header: "Map Location saved",
            render: (preset) => (
              <div className="my-2">
                {preset.enableMapLocation ? (
                  <h1 className="flex items-center justify-between text-gray-600 font-semibold text-base">
                    <IoMdCheckboxOutline className="text-green-500 text-lg" />
                  </h1>
                ) : (
                  <h1 className="flex items-center justify-between text-gray-600 font-semibold text-base">
                    <MdOutlineDoNotDisturbAlt className="text-red-500 text-lg" />
                  </h1>
                )}
              </div>
            ),
            sortable: false,
          },
          {
            header: "Share Preset",
            render: (preset) => {
              const userRole = auth?.user?.role;
              const url =
                userRole === "user"
                  ? `${process.env.REACT_APP_PM_URL}/${auth?.user?._id}/projects?filter=${preset?._id}`
                  : `${process.env.REACT_APP_PM_URL}/company/${auth?.user?._id}/projects?filter=${preset?._id}`;

              return (
                <CopyToClipboard
                  text={url}
                  onCopy={() => handleCopy(preset._id)}
                >
                  <button
                    type="button"
                    className={`mt-2 inline-flex justify-center items-center rounded-md border border-gray-300 bg-white px-5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none ${
                      copiedStatus[preset._id]
                        ? "bg-green-200 text-green-600"
                        : ""
                    }`}
                  >
                    {copiedStatus[preset._id] ? (
                      <>
                        <span>Copied</span>
                        <AiOutlineCheck className="ml-1" />
                      </>
                    ) : (
                      "Copy url"
                    )}
                  </button>
                </CopyToClipboard>
              );
            },
            sortable: false,
          },
          {
            header: "Actions",
            render: (preset) => (
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => handleDeletePreset(preset?._id)}
                  className="ml-3 text-xl h-8 w-8 flex items-center justify-center bg-red-100 text-red-700 py-0.5 px-2 rounded-md"
                >
                  <BsTrash3 />
                </button>
              </div>
            ),
            sortable: false,
          },
        ];

  useEffect(() => {
    if (auth?.user?.type === "individual") {
      getFilterPresets(auth?.user?._id)
        .then((res) => {
          console.log("presets data", res?.data);
          setPresets(res?.data);
          setCopiedStatus({});
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      getFilterPresetsByCompany(auth?.user?.company?._id)
        .then((res) => {
          console.log("presets data", res?.data);
          setPresets(res?.data);
          setCopiedStatus({});
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, []);

  const handleCopy = (presetId) => {
    setCopiedStatus((prevStatus) => ({
      ...prevStatus,
      [presetId]: true,
    }));
    setTimeout(() => {
      setCopiedStatus((prevStatus) => ({
        ...prevStatus,
        [presetId]: false,
      }));
    }, 4000);
  };

  const handleCopyEmbedCode = (presetId) => {
    setCopiedEmbedCodeStatus((prevStatus) => ({
      ...prevStatus,
      [presetId]: true,
    }));
    setTimeout(() => {
      setCopiedEmbedCodeStatus((prevStatus) => ({
        ...prevStatus,
        [presetId]: false,
      }));
    }, 4000);
  };

  const handleDeletePreset = async (id) => {
    confirmation().then(async (result) => {
      if (result.isConfirmed) {
        await deleteFilterPreset(id)
          .then((res) => {
            setPresets(presets.filter((vehicle) => vehicle._id !== id));
            success("Success!", "Preset deleted successfully!");
          })
          .catch((error) => {
            console.error("Error deleting preset:", error);
          });
      }
    });
  };

  return (
    <>
      <div className="bg-white rounded-lg shadow">
        <div className="border-b border-gray-200 rounded-t-lg bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Saved Maps
              </h3>
            </div>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <Table
            // pagination={true}
            columns={columns}
            // pageCount={pageCount}
            // setItemOffset={setItemOffset}
            data={presets}
            // dataCount={count}
            // itemsPerPage={itemsPerPage}
          />
        </div>
      </div>
    </>
  );
}

export default FilterPresets;
