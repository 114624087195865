import { combineReducers } from "redux";
import authReducer from "./auth";
import serviceReducer from "./service";
// import equipmentTypeReducer from "./equipmentType";
// import equipmentModelReducer from "./equipmentModel";
import equipmentBrandReducer from "./equipmentBrand";
// import workTypeReducer from "./workType";
// import companyReducer from "./company";
// import userReducer from "./user";
// import contactReducer from "./contact";
// import officeReducer from "./office";
import projectReducer from "./project";
import optionSetReducer from "./optionSet";
// import roleReducer from "./role";
// import utilityReducer from "./utilities";

const rootReducer = combineReducers({
  authReducer,
  serviceReducer,
  // equipmentTypeReducer,
  // equipmentModelReducer,
  equipmentBrandReducer,
  // workTypeReducer,
  // companyReducer,f
  // userReducer,
  // contactReducer,
  // officeReducer,
  projectReducer,
  optionSetReducer,
  // roleReducer,
  // utilityReducer,
});

export default rootReducer;
