import {
  GET_EQUIPMENTBRANDS,
  CREATE_EQUIPMENTBRAND,
  UPDATE_EQUIPMENTBRAND,
  DELETE_EQUIPMENTBRAND,
} from "../types/equipmentBrands.types";

const initialState = {
  equipmentBrands: [],
  count: 0,
  loading: false,
  error: null,
};

const equipmentBrandReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_EQUIPMENTBRAND:
      return {
        ...state,
        equipmentBrands: [action.payload, ...state.equipmentBrands],
      };
    case GET_EQUIPMENTBRANDS:
      return {
        ...state,
        loading: false,
        equipmentBrands: action.payload.equipmentBrands,
        count: action.payload.count,
      };
    case DELETE_EQUIPMENTBRAND:
      return {
        ...state,
        equipmentBrands: state.equipmentBrands.filter(
          (equipmentBrand) => equipmentBrand._id !== action.payload
        ),
      };
    case UPDATE_EQUIPMENTBRAND:
      return {
        ...state,
        equipmentBrands: state.equipmentBrands.map((equipmentBrand) =>
          equipmentBrand._id === action.payload._id
            ? action.payload
            : equipmentBrand
        ),
      };
    default:
      return state;
  }
};

export default equipmentBrandReducer;
