import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import CardIcon from "./Components/CardIcon";
import { createSubscription } from "../../../redux/api/payment.api";
import { useSelector } from "react-redux";
import { getPriceId } from "../../../utils/subscriptions";
import { success } from "../../../utils/notification";

const ChoosePaymentMethodPopup = ({
  open,
  setOpen,
  paymentMethods,
  getBillingData,
}) => {
  const auth = useSelector((state) => state.authReducer);

  const subscribeUsingPaymentMethod = (paymentMethodId) => {
    createSubscription({
      user: auth?.user?._id,
      customerId: auth?.user?.stripeCustomerId,
      plan: "individual",
      priceId: getPriceId("individual", "monthly"),
      paymentMethodId: paymentMethodId,
      frequency: "monthly",
    })
      .then((res) => {
        success("Success", "You have successfully subscribed!");
        getBillingData(res?.stripeCustomerId, res?.stripeSubscriptionId);
        setOpen(false);
      })
      .catch((err) => {
        console.log("Error creating subscription: ", err);
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Choose Payment Method
                    </Dialog.Title>
                  </div>
                </div>
                <ul className="mt-5 divide-y divide-gray-200">
                  {paymentMethods.map((method, i) => (
                    <li
                      className="flex items-center justify-between my-2 cursor-pointer hover:bg-gray-100 p-3 transition-all duration-200 ease-in-out"
                      onClick={() => subscribeUsingPaymentMethod(method.id)}
                      key={i}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex justify-center items-center">
                          <CardIcon brand={method?.card?.brand} />

                          {/* <img
                          className="h-11 p-2 rounded bg-white  border border-gray-400 border-opacity-40"
                          src={getCardLogo(method?.card?.brand)}
                        /> */}
                          <div className="ml-3">
                            <h2 className="ml-3 text-base font-bold text-gray-600 capitalize">
                              {`${method?.card?.brand} **** **** **** ${method?.card?.last4}`}
                            </h2>
                            <p className="ml-3 text-xs font-semibold text-gray-400">
                              Expiry: {method?.card?.exp_month}/
                              {method?.card?.exp_year}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ChoosePaymentMethodPopup;
