import { API } from "./api";

export const attachPaymentMethod = (data) => {
  return new Promise((resolve, reject) => {
    API.post("/payments/attach-payment-method", data)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const deletePaymentMethod = (paymentMethodId) => {
  return new Promise((resolve, reject) => {
    API.delete(`/payments/delete-payment-method/${paymentMethodId}`)
      .then((res) => {
        return resolve(paymentMethodId);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const createSubscription = (data) => {
  return new Promise((resolve, reject) => {
    API.post("/payments/create-subscription", data)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const activateSubscription = (subscriptionId) => {
  return new Promise((resolve, reject) => {
    API.put(`/payments/subscription/activate/${subscriptionId}`)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const cancelSubscription = (subscriptionId) => {
  return new Promise((resolve, reject) => {
    API.put(`/payments/subscription/cancel/${subscriptionId}`)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const doNotCancelSubscription = (subscriptionId) => {
  return new Promise((resolve, reject) => {
    API.put(`/payments/subscription/do-not-cancel/${subscriptionId}`)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const createPaymentIntent = (data) => {
  return new Promise((resolve, reject) => {
    API.post("/payments/create-payment-intent", data)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const confirmPaymentIntent = (data) => {
  return new Promise((resolve, reject) => {
    API.post("/payments/confirm-payment-intent", data)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const getCustomerInvoices = (customer, page) => {
  return new Promise((resolve, reject) => {
    API.get(`/payments/${customer}/invoices`, {
      params: { page },
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const getSubscription = (subscription) => {
  return new Promise((resolve, reject) => {
    API.get(`/payments/subscription/${subscription}`)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const getPaymentMethodsForCustomer = (customer) => {
  return new Promise((resolve, reject) => {
    API.get(`/payments/${customer}/payment-methods`)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

// export const updateSubscriptionPlan = (customer,subscriptionId, newPlanId) => {
//   return new Promise((resolve, reject) => {
//     API.post(`/payments/customer/${customer}/subscription/update`, { subscriptionId, newPlanId })
//       .then((res) => {
//         return resolve(res.data);
//       })
//       .catch((err) => {
//         return reject(err);
//       });
//   });
// };
export const updateSubscriptionPlan = (customer, newPriceId, newPlan) => {
  return new Promise((resolve, reject) => {
    API.post(`/payments/customer/${customer}/subscription/update`, {
      newPriceId,
      newPlan,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};
