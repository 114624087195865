import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../../../../redux/actions/service";
import {
  updateUserServices,
  updateUserWorkTypes,
} from "../../../../../redux/api/user.api";
import { updateCompanyWorkTypes } from "../../../../../redux/api/company.api";
import { RotatingLines } from "react-loader-spinner";

const UpdateWorkTypesPopup = ({
  open,
  setOpen,
  service,
  userServices,
  getUserServices,
}) => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.authReducer);
  const services = useSelector((state) => state.serviceReducer);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedWorkTypes, setSelectedWorkTypes] = useState(
    service?.serviceId?.workTypes
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  useEffect(() => {
    setSelectedWorkTypes(service?.workTypes?.map((workType) => workType._id));
  }, [service?.workTypes]);

  const handleWorkTypeCheckboxChange = (workTypeId) => {
    setSelectedWorkTypes((prevSelectedWorkTypes) => {
      // Check if the workTypeId is already in the array
      const isSelected = prevSelectedWorkTypes.includes(workTypeId);

      // If it's selected, remove it; otherwise, add it
      return isSelected
        ? prevSelectedWorkTypes.filter((id) => id !== workTypeId)
        : [...prevSelectedWorkTypes, workTypeId];
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (auth?.user?.role === "company") {
      await updateCompanyWorkTypes(
        auth?.user?.company?._id,
        service.serviceId._id,
        selectedWorkTypes
      )
        .then((res) => {
          getUserServices();
          setLoading(false);
          setOpen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await updateUserWorkTypes(
        auth?.user?._id,
        service.serviceId._id,
        selectedWorkTypes
      )
        .then((res) => {
          getUserServices();
          setLoading(false);
          setOpen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Edit Project Types for {service?.serviceId?.name}
                    </Dialog.Title>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="space-y-1 px-4 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <input
                      type="text"
                      placeholder="Search services..."
                      className="w-full p-2 mb-4 border rounded-md static"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {/* Search field to search for services list */}
                    <div className="space-y-1 sm:gap-4 sm:space-y-0 sm:py-5 h-48 overflow-y-scroll">
                      {service?.serviceId?.workTypes?.length > 0 &&
                        service?.serviceId?.workTypes
                          ?.filter((service) =>
                            service.name
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          ?.map((workType, index) => (
                            <label
                              key={index}
                              className="flex items-center text-base"
                            >
                              <input
                                type="checkbox"
                                checked={selectedWorkTypes?.some(
                                  (s) => s === workType._id
                                )}
                                onChange={() =>
                                  handleWorkTypeCheckboxChange(workType._id)
                                }
                                className="text-green-600 focus:ring-green-600 border-green-600 mr-2 h-3 w-3"
                                name="selectedServices"
                              />
                              <span>{workType.name}</span>
                            </label>
                          ))}
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      disabled={loading}
                      className={`${
                        loading
                          ? "bg-green-700 hover:bg-green-700 cursor-not-allowed"
                          : ""
                      }inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-24 sm:text-sm`}
                    >
                      {loading ? (
                        <div className="flex justify-center items-center">
                          <RotatingLines
                            strokeColor="white"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="20"
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Update"
                      )}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UpdateWorkTypesPopup;
