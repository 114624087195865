import { Autocomplete } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getOptionSets } from "../../../redux/actions/optionSet";
import {
  createProject,
  getProjectById,
  updateProject,
} from "../../../redux/api/project.api";
import { getServices } from "../../../redux/actions/service";
import { getEquipmentModelsByBrand } from "../../../redux/api/equipmentModel.api";
import {
  getCompaniesAssociatedToUser,
  getCompany,
  getServicesByCompanyId,
} from "../../../redux/api/company.api";
import {
  getServiceFieldOptions,
  getServiceFields,
} from "../../../redux/api/service.api";
import {
  confirmation,
  failure,
  success,
  warning,
} from "../../../utils/notification";
import ViewFields from "../../components/ViewFields";
import { getServicesByUserId } from "../../../redux/api/user.api";
import Select from "react-select";
import { FaPlus } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { baseURL } from "../../../redux/api/api";
import { RotatingLines } from "react-loader-spinner";

const AddProject = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const initialData = {
    date: "",
    service: "",
    address: "",
    coordinates: [],
    state: "",
    company: "",
    workType: "",
    cost: "",
    createdBy: "",
    images: [],
  };
  const auth = useSelector((state) => state.authReducer);
  const services = useSelector((state) => state.serviceReducer);
  const optionSets = useSelector((state) => state.optionSetReducer);
  const [loading, setLoading] = useState(false);
  const [userServices, setUserServices] = useState([]);
  const [project, setProject] = useState(initialData);
  const [fields, setFields] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [company, setCompany] = useState({});
  const [serviceQuery, setServiceQuery] = useState("");
  const [serviceSelectedValue, setServiceSelectedValue] = useState(null);
  const [companySelectedValue, setCompanySelectedValue] = useState(null);
  const [autoComplete, setAutoComplete] = useState(null);
  const [newOptions, setNewOptions] = useState([]);
  const [fieldsLoaded, setFieldsLoaded] = useState(false);
  const [costLabel, setCostLabel] = useState("Cost");
  const [companies, setCompanies] = useState([]);
  const [selectedWorktype, setSelectedWorktype] = useState(null);
  const [imagesToRemove, setImagesToRemove] = useState([]);

  const [addOptionPopupOpen, setAddOptionPopupOpen] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [equipmentBrandsForModel, setEquipmentBrandsForModel] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const onLoadAutoComplete = (autoComplete) => {
    setAutoComplete(autoComplete);
  };

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const p = autoComplete.getPlace();
      const latlng = [
        p?.geometry?.location?.lng(),
        p?.geometry?.location?.lat(),
      ];
      setProject({
        ...project,
        address: p.formatted_address,
        coordinates: latlng,
        state: p.address_components.find(
          (c) => c.types[0] === "administrative_area_level_1"
        )?.short_name,
      });
      // infoWin?.close();
    }
  };

  useEffect(() => {
    if (auth?.user?._id && auth?.user?.role === "user") {
      getServicesByUserId(auth?.user?._id)
        .then((res) => {
          setUserServices(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

      getCompaniesAssociatedToUser(auth?.user?._id)
        .then((comp) => {
          setCompanies(comp.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (auth?.user?._id && auth?.user?.role === "company") {
      getServicesByCompanyId(auth?.user?.company?._id)
        .then((res) => {
          setUserServices(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [auth?.user?._id]);

  useEffect(() => {
    if (!services.length) dispatch(getServices());
    if (!optionSets.length) dispatch(getOptionSets());
  }, [dispatch, services.length, optionSets.length]);

  useEffect(() => {
    if (id !== undefined && services?.length > 0 && optionSets?.length > 0) {
      getProjectById(id).then((res) => {
        setProject({
          ...project,
          date: res.data.date?.split("T")[0],
          service: {
            _id: res.data?.service?._id,
            name: res.data?.service?.name,
            workTypes: res.data?.service?.workTypes,
          },
          workType: res.data?.workType,
          address: res.data?.address,
          coordinates: res.data?.coordinates,
          cost: res.data?.cost,
          company: res.data?.company?._id,
          images: res.data?.images,
        });
        setProjectData(transformProjectData(res.data.projectData));
        // getServiceFields(res.data.service?._id, id).then((res) => {
        //   setFields(res.data);
        // });

        setServiceSelectedValue({
          value: res.data.service?._id,
          label: res.data.service?.name,
          workTypes: res.data.service?.workTypes,
        });
        setSelectedWorktype({
          value: res.data.workType,
          label: res?.data?.service?.workTypes?.find(
            (wt) => wt._id === res.data.workType
          )?.name,
        });
        setCompanySelectedValue({
          value: res.data.company?._id,
          label: res.data.company?.name,
        });
      });
    }
  }, [id, services, optionSets]);

  useEffect(() => {
    if (project?.workType !== "") {
      setCostLabel(
        project?.service?.workTypes?.find((wt) => wt._id === project?.workType)
          ?.costLabel
      );
    } else {
      setCostLabel("Cost");
    }
  }, [project?.workType]);

  useEffect(() => {
    if (project?.service && project?.workType) {
      handleServiceSelect(project?.service, project?.workType);
    }
  }, [project?.service, project?.workType]);

  const fetchModule = async (module, filter = null, optionSet = null) => {
    let options = [];
    if (module === "optionSets") {
      options = optionSets?.find((os) => os._id === optionSet)?.options;
    } else {
      const { data } = await getServiceFieldOptions(module, filter);
      options = data;
    }
    return options;
  };

  function transformProjectData(data) {
    // Iterate over each property in the object
    for (const key in data) {
      const property = data[key];
      // Check if the property is an object and has both 'unit' and 'value' keys
      if (
        property &&
        typeof property === "object" &&
        "unit" in property &&
        "value" in property
      ) {
        // Create new properties at the top level for unit and value
        data[`${key}_unit`] = property.unit;
        data[`${key}_value`] = property.value;

        // Delete the original property object
        delete data[key];
      }
    }
    return data;
  }

  function filterServiceWorkTypes(service, userServices) {
    if (service && userServices.length > 0) {
      // Find the matching service in userServices
      const matchingUserService = userServices.find((userService) =>
        id
          ? userService?.serviceId === service?._id
          : userService?.serviceId?._id === service?._id
      );

      if (!matchingUserService) {
        // If no matching service is found, return an empty array or the original service workTypes
        return [];
      }

      // Filter the workTypes in the service object
      const filteredWorkTypes = service.workTypes.filter((workType) =>
        matchingUserService.workTypes.some(
          (userWorkType) => userWorkType._id === workType._id
        )
      );

      return filteredWorkTypes;
    }
  }

  const handleImagesChange = (e) => {
    const files = e.target.files;

    // Check if the number of already selected images plus new ones exceeds 10
    if (project.images.length + files.length > 10) {
      alert("You can only upload up to 10 images!");
      return;
    }

    const newImages = [...project.images];

    const loadImage = (file) => {
      console.log("File: ", file);
      return new Promise((resolve, reject) => {
        // Check if the file size exceeds 1 MB
        if (file.size > 1048576) {
          reject(new Error("Image size must be less than 1 MB!"));
          return;
        }

        const reader = new FileReader();

        reader.onloadend = () => {
          resolve({
            file: file,
            dataURL: reader.result,
          });
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    };

    const loadImages = async () => {
      try {
        const imagePromises = Array.from(files).map((file) => loadImage(file));
        const loadedImages = await Promise.all(imagePromises);
        newImages.push(...loadedImages);
        setProject({ ...project, images: newImages });
      } catch (error) {
        alert(error.message); // Display error message to the user
      }
    };

    loadImages();
  };

  const handleRemoveImage = (index) => {
    const imageToRemove = project.images[index];

    const newImages = [...project.images];
    newImages.splice(index, 1);

    setProject({ ...project, images: newImages });
    if (imageToRemove?.key)
      setImagesToRemove((prev) => [...prev, imageToRemove]);
  };

  const handleServiceSelect = async (service, workType) => {
    try {
      if (service === "" || workType === "") {
        setFields([]);
        setFieldsLoaded(false);
        return;
      }
      let fields = [];
      let fieldsArr = [];

      let i = services.findIndex((s) => s._id === service._id);
      fields.push(
        services[i]?.fields
          ?.filter((field) => field?.workTypes?.includes(workType))
          ?.map((field) => {
            if (
              field.type === "select" &&
              field?.module !== "equipmentModels"
            ) {
              fieldsArr.push(
                fetchModule(field.module, field?.filter, field?.optionSet)
              );
              return {
                ...field,
                options: [],
              };
            } else if (field.type === "measurement") {
              fieldsArr.push(
                fetchModule("optionSets", field?.filter, field?.optionSet)
              );
              return {
                ...field,
                options: [],
              };
            } else {
              return field;
            }
          })
      );

      const allOptions = await Promise.all(fieldsArr);

      var optionsArrCount = 0;
      // const allOptionsForMeasurement = await Promise.all(fieldsArr);
      // var optionsArrMeasurement = 0;

      fields[0].map((field) => {
        if (field.type === "select" && field?.module !== "equipmentModels") {
          field.options = allOptions[optionsArrCount];
          optionsArrCount++;
        } else if (field.type === "measurement") {
          field.options = allOptions[optionsArrCount];
          optionsArrCount++;
        } else {
          field.options = [];
        }
        field.query = "";
        return field;
      });

      setFields(fields[0]);
      setFieldsLoaded(true);
      setProject({ ...project, date: project.date?.split("T")[0] });
      // fields[0] is an array of all fields.
      // set project data to an object with attributes as field names and values as empty strings
      if (id === undefined) {
        let data = {};
        fields[0].map((field) => {
          data[field.name] = "";
          return field;
        });
        setProjectData(data);
      }
    } catch (error) {
      console.log("Error in handleServiceSelect: ", error);
    }
  };

  useEffect(() => {
    if (fieldsLoaded && id !== undefined) {
      // Check which fields are equipmentBrands and then get the equipmentModels for each brand
      fields.forEach((field) => {
        if (field?.module === "equipmentBrands") {
          getEquipmentModelsForBrand(projectData[field.name], field);
        }
      });
    }
  }, [fieldsLoaded]);

  const getEquipmentModelsForBrand = async (brandSelected, currentField) => {
    if (
      newOptions?.filter(
        (option) =>
          option?.module === "equipmentModels" &&
          option?.brand === brandSelected
      )?.length > 0
    ) {
      const modelOptions = newOptions?.filter(
        (option) =>
          option?.module === "equipmentModels" &&
          option?.brand === brandSelected
      );
      if (modelOptions?.length > 0) {
        setFields((prevFields) => {
          const newFields = [...prevFields];
          const fieldIndex = newFields.findIndex(
            (field) =>
              field?.type === "select" &&
              field?.module === "equipmentModels" &&
              field?.filter === currentField?.filter
          );
          newFields[fieldIndex] = {
            ...newFields[fieldIndex],
            options: modelOptions?.map((model) => ({
              _id: model?.option,
              name: model?.option,
            })),
          };
          return newFields;
        });
      } else {
        warning("No Equipment Models Found, Please Add New");
        const modelField = fields?.find(
          (field) =>
            field?.type === "select" &&
            field?.module === "equipmentModels" &&
            field?.filter === currentField?.filter
        );
        setEquipmentBrandsForModel(currentField?.options);
        setSelectedBrand(brandSelected);
        setSelectedField(modelField);
        setAddOptionPopupOpen(true);
      }
    } else {
      try {
        const res = await getEquipmentModelsByBrand(brandSelected);
        if (res?.data?.length > 0) {
          setFields((prevFields) => {
            const newFields = [...prevFields];
            const fieldIndex = newFields.findIndex(
              (field) =>
                field?.type === "select" &&
                field?.module === "equipmentModels" &&
                field?.filter === currentField?.filter
            );
            newFields[fieldIndex] = {
              ...newFields[fieldIndex],
              options: res?.data?.map((model) => ({
                _id: model?._id,
                name: model?.name,
              })),
            };
            return newFields;
          });
        } else {
          warning("No Equipment Models Found, Please Add New");
          const modelField = fields?.find(
            (field) =>
              field?.type === "select" &&
              field?.module === "equipmentModels" &&
              field?.filter === currentField?.filter
          );
          setEquipmentBrandsForModel(currentField?.options);
          setSelectedBrand(brandSelected);
          setSelectedField(modelField);
          setAddOptionPopupOpen(true);
        }
      } catch (error) {
        warning("No Equipment Models Found, Please Add New");
        const modelField = fields?.find(
          (field) =>
            field?.type === "select" &&
            field?.module === "equipmentModels" &&
            field?.filter === currentField?.filter
        );
        setEquipmentBrandsForModel(currentField?.options);
        setSelectedBrand(brandSelected);
        setSelectedField(modelField);
        setAddOptionPopupOpen(true);
        setFields((prevFields) => {
          const newFields = [...prevFields];
          const fieldIndex = newFields.findIndex(
            (field) =>
              field?.type === "select" &&
              field?.module === "equipmentModels" &&
              field?.filter === currentField?.filter
          );
          newFields[fieldIndex] = {
            ...newFields[fieldIndex],
            options: [],
          };
          return newFields;
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const updatedProjectData = { ...projectData };
    fields.forEach((field) => {
      if (field.type === "measurement") {
        updatedProjectData[field.name] = {
          unit: projectData[`${field.name}_unit`],
          value: projectData[`${field.name}_value`],
        };
        delete updatedProjectData[`${field.name}_unit`];
        delete updatedProjectData[`${field.name}_value`];
      }
    });
    if (project?.date === "") {
      setLoading(false);
      failure("Please enter date");
      return;
    }
    if (project?.service === "") {
      setLoading(false);
      failure("Please select service");
      return;
    }
    if (project?.address === "") {
      setLoading(false);
      failure("Please enter address");
      return;
    }
    const data = new FormData();
    data.append("date", project?.date);
    data.append("service", project?.service?._id);
    data.append("address", project?.address);
    data.append("coordinates", JSON.stringify(project?.coordinates));
    data.append("state", project?.state);
    data.append("workType", project?.workType);
    data.append("cost", project?.cost);
    data.append(
      "company",
      auth?.user?.type === "individual"
        ? project?.company
        : auth?.user?.company?._id
    );
    data.append("userType", auth?.user?.type);
    data.append("createdBy", auth?.user?._id);
    data.append("projectData", JSON.stringify(updatedProjectData));
    data.append("newOptions", JSON.stringify(newOptions));
    project?.images.forEach((img) => {
      data.append("images", img?.file);
    });
    data.append("imagesToRemove", JSON.stringify(imagesToRemove));
    if (id !== undefined) {
      await updateProject(id, data)
        .then((res) => {
          setLoading(false);
          success("Success", "Project updated successfully");
          navigate("/projects");
        })
        .catch((err) => {
          setLoading(false);
          failure("Error", "Failed to update project");
        });
    } else {
      await createProject(data)
        .then((res) => {
          setLoading(false);
          success("Success", "Project created successfully");
          navigate("/projects");
        })
        .catch((err) => {
          setLoading(false);
          failure(err?.response?.data?.message);
          console.log("error", err);
        });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="bg-white rounded-lg shadow">
          <div className="border-b border-gray-200 rounded-t-lg bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-2">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Add Project
                </h3>
              </div>
            </div>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <div className="grid grid-cols-12 lg:gap-6 md:gap-y-3 md:gap-x-6 gap-3">
              {/* Input for company name */}
              <div className="md:col-span-6 col-span-12 lg:col-span-4">
                <div className="">
                  <label
                    htmlFor="name"
                    className=" block text-sm font-medium text-gray-700"
                  >
                    Service <span className="text-red-600">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      id="service"
                      name="service"
                      isMulti={false} // Change to true if you want to allow multiple selections
                      className="bg-gray-50 block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-gray-300 rounded-md"
                      value={serviceSelectedValue}
                      onChange={(selectedOption) => {
                        setProject({
                          ...project,
                          service: {
                            _id: selectedOption.value,
                            name: selectedOption.label,
                            workTypes: selectedOption.workTypes,
                          },
                        });
                        setServiceSelectedValue(selectedOption);
                        setSelectedWorktype(null);
                      }}
                      options={services
                        ?.filter((service) =>
                          userServices?.some(
                            (s) => s.serviceId?._id === service._id
                          )
                        )
                        ?.map((service) => ({
                          value: service._id,
                          label: service.name,
                          workTypes: service.workTypes,
                        }))}
                      placeholder="Select Service"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="md:col-span-6 col-span-12 lg:col-span-4">
                <div className="">
                  <label
                    htmlFor="workType"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Project Type <span className="text-red-600">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      id="workType"
                      name="workType"
                      className=" bg-gray-50 block w-full shadow-sm focus:ring-green-600 focus:border-green-600 !important sm:text-sm border-gray-300 rounded-md"
                      value={selectedWorktype}
                      onChange={(selectedOption) => {
                        setProject({
                          ...project,
                          workType: selectedOption.value,
                        });
                        setSelectedWorktype(selectedOption);
                      }}
                      options={filterServiceWorkTypes(
                        project?.service,
                        userServices
                      )?.map((workType, index) => ({
                        value: workType._id,
                        label: workType.name,
                      }))}
                      placeholder="Select Project Type"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="md:col-span-6 col-span-12 lg:col-span-4">
                <div className="">
                  <label
                    htmlFor="date"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Date Project Completed{" "}
                    <span className="text-red-600">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="date"
                      name="date"
                      id="date"
                      className=" bg-gray-50 block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-gray-300 rounded-md"
                      value={project?.date}
                      onChange={(e) =>
                        setProject({ ...project, date: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="md:col-span-6 col-span-12 lg:col-span-4">
                <div className="">
                  <label
                    htmlFor="cost"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {costLabel} <span className="text-red-600">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="number"
                      name="cost"
                      id="cost"
                      className=" bg-gray-50 block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-gray-300 rounded-md"
                      value={project?.cost}
                      onChange={(e) =>
                        setProject({ ...project, cost: e.target.value })
                      }
                      placeholder="Cost"
                      required
                    />
                  </div>
                </div>
              </div>
              {auth?.user?.role === "user" && (
                <div className="md:col-span-6 col-span-12 lg:col-span-4">
                  <div className="">
                    <label
                      htmlFor="company"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Company <span className="text-red-600">*</span>
                    </label>
                    <div className="mt-1">
                      <Select
                        id="company"
                        name="company"
                        className="bg-gray-50 block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-gray-300 rounded-md"
                        value={companySelectedValue}
                        onChange={(selectedOption) => {
                          setProject({
                            ...project,
                            company: selectedOption.value,
                          });
                          setCompanySelectedValue(selectedOption);
                        }}
                        options={companies.map((company) => ({
                          value: company._id,
                          label: company.name,
                        }))}
                        placeholder="Select Company"
                        required
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="md:col-span-6 col-span-12 lg:col-span-4">
                <div className="">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address <span className="text-red-600">*</span>
                  </label>
                  <div className="mt-1">
                    <Autocomplete
                      onLoad={onLoadAutoComplete}
                      onPlaceChanged={onPlaceChanged}
                    >
                      <input
                        type="text"
                        defaultValue={project?.address}
                        onChange={(e) =>
                          setProject({ ...project, address: e.target.value })
                        }
                        placeholder="Address"
                        className="bg-gray-50 block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-gray-300 rounded-md"
                        required
                      />
                    </Autocomplete>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ViewFields
            newOptions={newOptions}
            setNewOptions={setNewOptions}
            fields={fields}
            setFields={setFields}
            projectData={projectData}
            setProjectData={setProjectData}
            fieldsLoaded={fieldsLoaded}
            getEquipmentModelsForBrand={getEquipmentModelsForBrand}
            selectedBrand={selectedBrand}
            setSelectedBrand={setSelectedBrand}
            equipmentBrandsForModel={equipmentBrandsForModel}
            setEquipmentBrandsForModel={setEquipmentBrandsForModel}
            selectedField={selectedField}
            setSelectedField={setSelectedField}
            addOptionPopupOpen={addOptionPopupOpen}
            setAddOptionPopupOpen={setAddOptionPopupOpen}
          />
          <div className="px-4 py-5 sm:p-6">
            <div className="grid grid-cols-12 lg:gap-6 md:gap-y-3 md:gap-x-6 gap-3">
              <div className=" col-span-12">
                <div className="">
                  <label
                    htmlFor="name"
                    className=" block text-base font-medium text-gray-700"
                  >
                    Images{" "}
                    <span className="text-gray-400 text-sm">
                      (Maximum 10 images are allowed. Size of each image should
                      not be more than 1 MB.)
                    </span>
                  </label>

                  <div className="p-5 my-4 rounded border-gray-300 border grid grid-cols-12 lg:gap-3 md:gap-y-3 md:gap-x-6 gap-3">
                    <div className="md:col-span-3 col-span-4 lg:col-span-2">
                      <div className="border-2 border-dashed border-gray-200 flex items-center h-28 justify-center">
                        <input
                          id="images"
                          name="images"
                          type="file"
                          className="sr-only"
                          onChange={handleImagesChange}
                          multiple
                        />
                        <label
                          htmlFor="images"
                          className="text-white cursor-pointer flex items-center justify-center w-12 h-12 bg-green-600 rounded-full hover:bg-green-700 focus:outline-none focus:ring focus:border-green-300"
                        >
                          <FaPlus />
                        </label>
                      </div>
                    </div>
                    {project.images.map((image, index) => (
                      <div
                        key={index}
                        className="md:col-span-3 col-span-4 lg:col-span-2"
                      >
                        <div className="relative group">
                          <div
                            onClick={() => handleRemoveImage(index)}
                            className="absolute top-2 right-2 bg-red-600 rounded-full h-5 w-5 text-white flex items-center justify-center opacity-0 transition-opacity group-hover:opacity-100 cursor-pointer"
                          >
                            <MdClose />
                          </div>
                          <img
                            className="w-full h-28 border border-gray-300 rounded"
                            src={
                              image.dataURL
                                ? image.dataURL
                                : `${baseURL}s3/images/${image.key}`
                            }
                            alt="projectImg"
                          />
                          {/* <div className="absolute bottom-2 left-2 text-white font-semibold">
                            {image.file.name}
                          </div> */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="border-t border-gray-200 flex justify-end px-4 py-5 sm:px-6">
              <button
                type="submit"
                disabled={loading}
                className={`${
                  loading
                    ? "bg-green-700 hover:bg-green-700 cursor-not-allowed"
                    : ""
                } w-24 relative inline-flex justify-center items-center rounded-md bg-green-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600`}
              >
                {loading ? (
                  <div className="flex justify-center items-center">
                    <RotatingLines
                      strokeColor="white"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="20"
                      visible={true}
                    />
                  </div>
                ) : id ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddProject;
