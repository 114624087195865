import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAdminAccess } from "../../../redux/api/auth.api";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "../../../redux/actions/auth";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const { id } = useParams(); // Get the ID from the URL path
  const location = useLocation(); // Get the location object
  const navigate = useNavigate(); // For redirection
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    // Ensure that both ID and token are present
    if (!id || !token) {
      setError("Missing ID or token");
      return;
    }

    const authenticateAdmin = async () => {
      try {
        getAdminAccess(id, token)
          .then(({ data, status }) => {
            if (status === 200) {
              dispatch({ type: "LOGIN", payload: data });
              dispatch(getCurrentUser());
              // Redirect to the admin dashboard
              if (data?.result?.role === "company") {
                navigate(`/company-profile`);
              } else {
                navigate(`/profile`);
              }
            } else {
              setError("Unauthorized access");
            }
          })
          .catch((err) => {
            console.error(err);
            setError("Error occurred during authentication");
          });
      } catch (err) {
        console.error(err);
        setError("Error occurred during authentication");
      } finally {
        setIsLoading(false);
      }
    };

    authenticateAdmin();
  }, [id, token, navigate]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  return <div>AdminLogin</div>;
};

export default AdminLogin;
