import { API } from "./api";

export const getContacts = () => API.get("/contacts");

export const createContact = (newContact) => {
  return new Promise((resolve, reject) => {
    API.post("/contacts", newContact)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const createContactInCompany = (companyId, contact) => {
  return new Promise((resolve, reject) => {
    API.post(`/contacts/${companyId}`, contact)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const updateContact = (id, updatedContact) =>
  API.patch(`/contacts/${id}`, updatedContact);

export const deleteContact = (id) => API.delete(`/contacts/${id}`);
export const deleteContactFromCompany = (companyId, id) =>
  API.delete(`/contacts/${companyId}/${id}`);
