import { API } from "./api";

export const createFilterPreset = (preset) =>
  API.post("/filterPresets/", preset);

export const updateFilterPreset = (id, preset) =>
  API.put(`/filterPresets/${id}`, preset);

export const updateEnableMapLocation = (id, enableMapLocation) =>
  API.put(`/filterPresets/${id}/enableMapLocation`, { enableMapLocation });

export const updateShowProjectCost = (id, showProjectCost) =>
  API.put(`/filterPresets/${id}/showProjectCost`, { showProjectCost });

export const updateAllowMapZoom = (id, allowMapZoom) =>
  API.put(`/filterPresets/${id}/allowMapZoom`, { allowMapZoom });

export const updateAllowMapPan = (id, allowMapPan) =>
  API.put(`/filterPresets/${id}/allowMapPan`, { allowMapPan });

export const getFilterPresets = (id) => API.get(`/filterPresets/user/${id}`);
export const getFilterPresetsByCompany = (id) =>
  API.get(`/filterPresets/company/${id}`);

export const getFilterPresetById = (filterId) =>
  API.get(`/filterPresets/${filterId}`);

export const deleteFilterPreset = (id) => API.delete(`/filterPresets/${id}`);
