import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  attachPaymentMethod,
  createSubscription,
} from "../../../../../redux/api/payment.api";
import { useNavigate } from "react-router-dom";
import { failure, success } from "../../../../../utils/notification";
import { getPriceId } from "../../../../../utils/subscriptions";
import { RotatingLines } from "react-loader-spinner";

const PaymentDetails = ({ formData }) => {
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);

  const convertHyphenToCamelCase = (str) => {
    return str.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      return;
    }

    try {
      stripe
        .createPaymentMethod({
          type: "card",
          billing_details: {
            name: `${formData?.firstName} ${formData?.lastName}`,
          },
          card: elements.getElement(CardElement),
        })
        .then(async ({ paymentMethod }) => {
          await attachPaymentMethod({
            paymentMethodId: paymentMethod.id,
            customerId: formData?.stripeCustomerId,
          })
            .then(async (res) => {
              if (formData?.plan !== "leads-only") {
                await createSubscription({
                  user: formData?._id,
                  customerId: formData?.stripeCustomerId,
                  plan: formData?.plan,
                  frequency: formData?.frequency,
                  priceId: getPriceId(
                    formData?.type,
                    convertHyphenToCamelCase(formData?.plan),
                    formData?.frequency
                  ),
                  paymentMethodId: paymentMethod.id,
                })
                  .then(async (res) => {
                    success("Success", "You have successfully registered!");
                    navigate("/?registration=success");
                    setLoading(false);
                  })
                  .catch((err) => {
                    console.log("Error creating subscription: ", err);
                    failure(
                      "Error",
                      "An error occurred while creating subscription. You can try again later by logging in and going to the billing page."
                    );
                    setLoading(false);
                  });
              } else {
                success("Success", "You have successfully registered!");
                navigate("/?registration=success");
                setLoading(false);
              }
            })
            .catch((err) => {
              console.log("Error attaching payment method: ", err);
              failure(
                "Error",
                "An error occurred while creating subscription. You can try again later by logging in and going to the billing page."
              );
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log("Error creating payment method: ", err);
          failure(
            "Error",
            "An error occurred while creating subscription. You can try again later by logging in and going to the billing page."
          );
          setLoading(false);
        });
    } catch (error) {
      console.log("error", error);
      failure(
        "Error",
        "An error occurred while creating subscription. You can try again later by logging in and going to the billing page."
      );
      setLoading(false);
    }
  };

  const paymentElementOptions = {
    layout: {
      type: "tabs",
      defaultCollapsed: false,
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      <>
        <CardElement id="payment-element" options={paymentElementOptions} />
        {/* <button id="submit"></button> */}
      </>
      <div className="flex space-x-20 mt-5">
        {/* <button
          type="button"
          className="flex w-full justify-center rounded-md border border-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-green-600 shadow-sm hover:bg-green-600 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
          onClick={() => handleBack()}
        >
          Back
        </button> */}
        <button
          type="submit"
          disabled={loading}
          className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
        >
          {loading ? (
            <RotatingLines
              strokeColor="green"
              strokeWidth="5"
              animationDuration="0.75"
              width="24"
              visible={true}
            />
          ) : (
            "Register"
          )}
        </button>
      </div>
    </form>
  );
};

export default PaymentDetails;
