import { useEffect, useState } from "react";
import TermsAndConditions from "../TermsAndConditions";
import { Link } from "react-router-dom";
import { FiMail } from "react-icons/fi";
import { IoPersonOutline } from "react-icons/io5";
import { MdLockOutline } from "react-icons/md";
import { RotatingLines } from "react-loader-spinner";

const PersonalInformation = ({
  formData,
  setFormData,
  handleChange,
  handleNext,
}) => {
  const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    if (form.checkValidity()) {
      setTermsAndConditionsOpen(true);
      setLoading(false);
      // handleNext();
    } else {
      form.reportValidity();
      setLoading(false);
    }
  };
  return (
    <>
      <TermsAndConditions
        open={termsAndConditionsOpen}
        setOpen={setTermsAndConditionsOpen}
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
        handleNext={handleNext}
      />
      <form onSubmit={handleSubmit}>
        <div>
          <label
            htmlFor="firstName"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            First Name
          </label>
          <div className="relative mt-1.5 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <IoPersonOutline
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="firstName"
              name="firstName"
              id="firstName"
              className="mb-3 outline-0 py-3 bg-gray-50 block w-full rounded-md border border-gray-100 py-1.5 pl-10 text-gray-900 ring-0 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              value={formData?.firstName}
              onChange={handleChange}
              placeholder="First Name"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="lastName"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Last Name
          </label>
          <div className="relative mt-1.5 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <IoPersonOutline
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="lastName"
              name="lastName"
              id="lastName"
              className="mb-3 outline-0 py-3 bg-gray-50 block w-full rounded-md border border-gray-100 py-1.5 pl-10 text-gray-900 ring-0 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              value={formData?.lastName}
              onChange={handleChange}
              placeholder="Last Name"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Email
          </label>
          <div className="relative mt-1.5 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <FiMail className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="email"
              name="email"
              id="email"
              className="mb-3 outline-0 bg-gray-50 block w-full rounded-md border border-gray-100 focus:border-0 py-1.5 pl-10 text-gray-900 ring-0 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              value={formData?.email}
              onChange={handleChange}
              placeholder="Email"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Password
          </label>
          <div className="relative mt-1.5 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MdLockOutline
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="password"
              name="password"
              id="password"
              className="mb-3 outline-0 py-3 bg-gray-50 block w-full rounded-md border border-gray-100 focus:border-0 py-1.5 pl-10 text-gray-900 ring-0 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              value={formData?.password}
              onChange={handleChange}
              placeholder="Password"
            />
          </div>
        </div>
        <div className="mt-10 flex flex-col items-center justify-center w-full">
          <button
            type="submit"
            disabled={loading}
            className="rounded-full px-10 py-2.5 w-48 font-bold text-white flex justify-center items-center text-base bg-gradient-to-r from-[#D88A16] to-[#FFB800]"
          >
            {formData?.plan === "basic" ? (
              loading ? (
                <RotatingLines
                  strokeColor="green"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="24"
                  visible={true}
                />
              ) : (
                "Register"
              )
            ) : loading ? (
              <RotatingLines
                strokeColor="green"
                strokeWidth="5"
                animationDuration="0.75"
                width="24"
                visible={true}
              />
            ) : (
              "Next"
            )}
          </button>
          <p className="text-base text-gray-500 text-center mt-2.5">
            Already have an account?{" "}
            <Link to="/login" className="text-green-600 font-bold">
              Login Here
            </Link>
          </p>
        </div>
      </form>
    </>
  );
};

export default PersonalInformation;
