import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useState } from "react";
import { uploadCSV } from "../../../redux/api/project.api";
import { failure, success } from "../../../utils/notification";

const UploadCsvPopup = ({ open, setOpen }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("csvFile", selectedFile);
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ": " + pair[1]);
    // }
    uploadCSV(formData)
      .then((res) => {
        setSelectedFile(null);
        success(
          "Csv File Uploaded",
          "Thank you. Please allow 24 -48 hours for your projects to be uploaded"
        );
        setOpen(false);
      })
      .catch((err) => {
        failure("Oops", err?.response?.data?.message);
      });
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="z-50" onClose={setOpen}>
        <Transition.Child
          enter="ease-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 transform scale-100"
          leaveTo="opacity-0 transform scale-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opacity-0 transform scale-0"
              enterTo="opacity-100 transform scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 transform scale-100"
              leaveTo="opacity-0 transform scale-0"
            >
              <Dialog.Panel className="mt-12 lg:ml-60 md:ml-60 ml-0 z-50  fixed mx-auto rounded-lg bg-white pt-5 pb-4 text-left shadow-xl transition-all h-auto">
                <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="">
                  <div className=" sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="px-3 pb-5 text-xl font-medium border-b-2 border-gray-200 leading-6 text-gray-700"
                    >
                      Add CSV
                    </Dialog.Title>
                    <div className="">
                      <form onSubmit={handleSubmit} method="POST">
                        <div className="">
                          <div className="px-7 py-4">
                            <div className="py-3 md:py-4 lg:py-4">
                              <div className="py-0.5 md:py-1.5 lg:py-1.5 items-center grid grid-cols-1 sm:grid-cols-12 lg:grid-cols-12 md:grid-cols-12">
                                <div className="lg:col-span-4 md:col-span-4 sm:col-span-12 ">
                                  <label
                                    htmlFor="CSV"
                                    className="block text-base font-semibold leading-6 text-gray-800"
                                  >
                                    Add CSV
                                  </label>
                                </div>
                                <div className="lg:col-span-8 md:col-span-8 sm:col-span-12 ">
                                  <div className="mt-2">
                                    <input
                                      type="file"
                                      name="CSV"
                                      id="CSV"
                                      className="text-sm font-medium bg-white bg-opacity-80 px-3 block w-full rounded outline-0 py-1.5 text-gray-600 placeholder:text-gray-300 border border-gray-300 focus:border-2 focus:border-yellowColor sm:leading-6 focus:ring-transparent"
                                      onChange={handleChange}
                                      required
                                      accept=".csv"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border-t-2 border-gray-200 px-7 pt-3 flex items-center justify-end">
                          <button
                            type="submit"
                            className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                          >
                            Upload
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UploadCsvPopup;
