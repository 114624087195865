import { CheckIcon } from "@heroicons/react/24/outline";
import React from "react";

const PlanTier = ({
  tier,
  getPriceId,
  handleUpdateSubscriptionPlan,
  plan,
  auth,
  camelize,
  setNewPlan,
  setNewPlanFrequency,
}) => {
  function disableLowerPlans(targetPlan) {
    const plans = ["basic", "pro", "pro-plus"];

    const currentPlanIndex = plans.indexOf(auth?.user?.plan?.toLowerCase());
    const targetPlanIndex = plans.indexOf(targetPlan.toLowerCase());

    // Return true if the target plan is lower than the current plan
    return targetPlanIndex < currentPlanIndex;
  }

  function getPlanFromhref(href) {
    const urlParams = new URLSearchParams(href.split("?")[1]);
    return urlParams.get("plan");
  }

  function getFrequencyFromhref(href) {
    const urlParams = new URLSearchParams(href.split("?")[1]);
    return urlParams.get("frequency");
  }

  return (
    <div
      key={tier.id}
      className={`ring-1 ring-gray-200 rounded-lg lg:col-span-4 col-span-12 ${
        plan === getPlanFromhref(tier.href)
          ? "ring-2 ring-green-600 bg-green-100"
          : ""
      }`}
    >
      <div className="flex justify-between items-center px-6 py-4 bg-green-600 rounded-t-lg">
        <p className="mt-2 text-xl text-white font-bold ">{tier.name}</p>
        <div>
          <div className="text-white font-bold flex items-end">
            <p>{tier.price}</p>
            <p className="text-sm font-normal">{tier.priceSuffix}</p>
          </div>
        </div>
      </div>

      <div className="h-4/5 flex flex-col items-between justify-between px-6 py-2 xl:px-6 xl:pt-2">
        <ul className="text-sm leading-6 text-gray-600 my-2">
          {tier.features.map((feature) => (
            <li key={feature} className="flex gap-x-3 my-1">
              <CheckIcon
                className="h-6 w-5 flex-none text-green-600"
                aria-hidden="true"
              />
              {feature}
            </li>
          ))}
        </ul>
        <div className="flex items-center justify-center">
          <button
            className="w-full mt-3 mb-2 mx-auto shrink-0 rounded-full px-10 py-2.5 font-bold text-white text-base bg-gradient-to-r from-[#D88A16] to-[#FFB800]"
            disabled={
              plan === getPlanFromhref(tier.href) ||
              disableLowerPlans(getPlanFromhref(tier.href))
            }
            onClick={() => {
              setNewPlan(getPlanFromhref(tier.href));
              setNewPlanFrequency(getFrequencyFromhref(tier.href));
              handleUpdateSubscriptionPlan(
                tier.href,
                getPriceId(
                  auth?.user?.type,
                  camelize(tier.name),
                  getFrequencyFromhref(tier.href)
                )
              );
            }}
          >
            {plan === getPlanFromhref(tier.href)
              ? "Current Plan"
              : "Update Plan"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanTier;
