import React from "react";
import { BiBuilding } from "react-icons/bi";
import { baseURL } from "../../../../../redux/api/api";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { removeCompanyFromUser } from "../../../../../redux/api/user.api";
import { confirmation } from "../../../../../utils/notification";

const CompanyCard = ({ company, getCompanies }) => {
  const auth = useSelector((state) => state.authReducer);

  const handleRemoveCompany = async (companyId) => {
    confirmation("Are you sure?", "You won't be able to undo this").then(
      async (result) => {
        if (result.isConfirmed) {
          await removeCompanyFromUser(auth?.user?._id, companyId)
            .then((res) => {
              getCompanies();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    );
  };

  return (
    <div className="w-full bg-white p-6 rounded-md shadow-md relative group">
      <button
        className="absolute top-2 right-2 hidden group-hover:inline-block"
        onClick={() => {
          handleRemoveCompany(company._id);
        }}
      >
        <TrashIcon className="h-5 w-5 text-red-400" />
      </button>
      <div className="mb-4">
        {company?.logo ? (
          <img
            src={`${baseURL}s3/images/${company?.logo}`}
            alt="Company Logo"
            className="w-16 h-16 mx-auto"
          />
        ) : (
          // Dummy company logo
          <div className="rounded-full bg-gray-50 w-16 h-16 flex justify-center items-center mx-auto">
            <BiBuilding className="w-10 h-10 mx-auto text-gray-400" />
          </div>
        )}
      </div>

      <h2 className="text-xl font-semibold text-gray-800 mb-2 text-center">
        {company?.name}
      </h2>

      <p className="text-blue-500 text-center mb-2">
        <a
          href={`${company?.website}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {company?.website}
        </a>
      </p>
    </div>
  );
};

export default CompanyCard;
