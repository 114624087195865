import {
  CREATE_OPTIONSET,
  GET_OPTIONSETS,
  UPDATE_OPTIONSET,
  DELETE_OPTIONSET,
} from "../types/optionSets.types";

const optionSetReducer = (optionSets = [], action) => {
  switch (action.type) {
    case CREATE_OPTIONSET:
      return [action.payload, ...optionSets];
    case GET_OPTIONSETS:
      return action.payload.reverse();
    case DELETE_OPTIONSET:
      return optionSets.filter((optionSet) => optionSet._id !== action.payload);
    case UPDATE_OPTIONSET:
      return optionSets.map((optionSet) =>
        optionSet._id === action.payload._id ? action.payload : optionSet
      );
    default:
      return optionSets;
  }
};

export default optionSetReducer;
