import { API } from "./api";

export const getServices = () => API.get("/services");

export const getServiceFieldOptions = (module, filter = null) =>
  API.get(`/${module}/${filter}`);

export const getServiceFields = (serviceId, projectId) =>
  API.get(`/services/${serviceId}/project/${projectId}/fields`);

export const createService = (newService) => API.post("/services", newService);

export const updateService = (id, updatedService) =>
  API.patch(`/services/${id}`, updatedService);

export const deleteService = (id) => API.delete(`/services/${id}`);
