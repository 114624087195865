import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import CompanyLayout from "../../../layout/CompanyLayout";
import CompanyProfile from "../Profile/CompanyProfile";
import IndividualProfile from "../Profile/IndividualProfile/IndividualProfile";
import AddProject from "../Projects/AddProject";
import Projects from "../Projects/Projects";
import Billing from "../Billing/Billing";
import Support from "../Support/Support";
import InActiveSubscription from "../Errors/InActiveSubscription";
import FilterPresets from "../Projects/FilterPresets/FilterPresets";
import ProjectsMap from "../Projects/ProjectsMap/ProjectsMap";
import AppearanceSettings from "../AppearanceSettings/AppearanceSettings";
import PartnersPageResponses from "../PartnersPageResponses/PartnersPageResponses";
import Dashboard from "../Dashboard/Dashboard";

const withActiveSubscription = (Component) => {
  return (props) => {
    const isActive =
      props.auth?.user?.plan !== "basic" && props?.auth?.user?.plan !== "pro";
    return isActive ? <Component /> : <InActiveSubscription />;
  };
};

const Main = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (!auth?.isLoggedIn) {
      navigate("login");
    }
  }, [auth]);

  return (
    <CompanyLayout>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<IndividualProfile />} />
        <Route path="/company-profile" element={<CompanyProfile />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/add" element={<AddProject />} />
        <Route path="/projects/edit/:id" element={<AddProject />} />
        <Route path="/projects/map" element={<ProjectsMap />} />
        <Route path="/billing" element={<Billing />} />
        <Route path="/support" element={<Support />} />
        <Route
          path="/appearance-settings"
          element={withActiveSubscription(AppearanceSettings)({ auth })}
        />
        <Route path="/filterpresets" element={<FilterPresets />} />
        <Route path="/partners" element={<PartnersPageResponses />} />
      </Routes>
    </CompanyLayout>
  );
};

export default Main;
