import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  AddressElement,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { Fragment, useState } from "react";
import {
  attachPaymentMethod,
  createSubscription,
  getPaymentMethodsForCustomer,
} from "../../../redux/api/payment.api";
import { useSelector } from "react-redux";
import { failure, success } from "../../../utils/notification";
import { getPriceId } from "../../../utils/subscriptions";
import { RotatingLines } from "react-loader-spinner";

const UpdatePaymentMethodPopup = ({
  open,
  setOpen,
  subscribe = false,
  setPaymentMethods,
  getBillingData,
  plan,
  frequency,
  priceId,
}) => {
  const auth = useSelector((state) => state.authReducer);
  const stripe = useStripe();
  const elements = useElements();
  const paymentElementOptions = {
    layout: {
      type: "tabs",
      defaultCollapsed: false,
    },
    fields: {
      billingDetails: {
        name: "auto",
      },
    },
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      stripe
        .createPaymentMethod({
          type: "card",
          billing_details: {
            name: `${auth?.user?.firstName} ${auth?.user?.lastName}`,
          },
          card: elements.getElement(CardElement),
        })
        .then(async ({ paymentMethod }) => {
          await attachPaymentMethod({
            paymentMethodId: paymentMethod.id,
            customerId: auth?.user?.stripeCustomerId,
            subscriptionId: auth?.user?.stripeSubscriptionId,
          })
            .then((res) => {
              if (subscribe) {
                createSubscription({
                  user: auth?.user?._id,
                  customerId: auth?.user?.stripeCustomerId,
                  plan: plan,
                  priceId: priceId,
                  paymentMethodId: paymentMethod.id,
                  frequency: frequency,
                })
                  .then((res) => {
                    success("Success", "Subscription activated successfully");
                    getBillingData(
                      res?.stripeCustomerId,
                      res?.stripeSubscriptionId
                    );
                    setOpen(false);
                    setLoading(false);
                    window.location.reload();
                  })
                  .catch((err) => {
                    failure("Error", "Error activating subscription");
                  });
              } else {
                success("Success", "Payment method added successfully");
                getPaymentMethodsForCustomer(auth?.user?.stripeCustomerId)
                  .then((res) => {
                    setPaymentMethods(res.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                setOpen(false);
              }
            })
            .catch((err) => {
              console.log("Error attaching payment method: ", err);
              failure("Error", "Error adding payment method");
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log("Error creating payment method: ", err);
          failure("Error", "Error adding payment method");
          setLoading(false);
        });
    } catch (error) {
      console.log("error", error);
      failure("Error", "Error adding payment method");
      setLoading(false);
    }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Add Payment Method
                    </Dialog.Title>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="my-6 mx-4">
                    <CardElement
                      id="payment-element"
                      options={paymentElementOptions}
                    />
                  </div>
                  {/* <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                      >
                        Company Name
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        id="name"
                        name="name"
                        value={company?.name}
                        onChange={handleChange}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        placeholder="Company Name"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="website"
                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                      >
                        Website URL
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        id="website"
                        name="website"
                        value={company?.website}
                        onChange={handleChange}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        placeholder="Website URL"
                      />
                    </div>
                    {!companyExists && (
                      <>
                        <div>
                          <label
                            htmlFor="address"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Address
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            id="address"
                            name="address"
                            value={company?.address}
                            onChange={handleChange}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            placeholder="Address"
                          />
                        </div>
                      </>
                    )}
                  </div> */}
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      disabled={loading}
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      {loading ? (
                        <RotatingLines
                          strokeColor="green"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="24"
                          visible={true}
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UpdatePaymentMethodPopup;
