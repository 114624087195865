import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./App.css";
import Login from "./app/pages/Auth/Login/Login";
import Register from "./app/pages/Auth/RegisterV2/Register";
import Home from "./app/pages/Home/Home";
import Main from "./app/pages/Main/Main";
import PricingPlans from "./app/pages/PricingPlans/PricingPlans";
import { getCurrentUser } from "./redux/actions/auth";
import { useDispatch } from "react-redux";
import Plans from "./app/pages/PricingPlans/Plans";
import AdminLogin from "./app/pages/AdminLogin/AdminLogin";

const LIBRARIES = ["places", "geometry"];

// Get Stripe publishable key

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const options = {
  mode: "payment",
  currency: "usd",
  amount: 100,
  paymentMethodTypes: ["card"],
};

function App() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!location.pathname.includes("access")) {
      if (localStorage.getItem("token")) dispatch(getCurrentUser());
    }
  }, [dispatch]);
  return (
    <Elements stripe={stripePromise} options={options}>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={LIBRARIES}
      >
        <Routes>
          <Route path="/:id/access" element={<AdminLogin />} />
          <Route path="/" element={<Login />} />
          {/* <Route path="/plans" element={<Plans />} /> */}
          <Route path="/pricing" element={<PricingPlans />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<Main />} />
        </Routes>
      </LoadScript>
    </Elements>
  );
}

export default App;
