import { API } from "./api";

export const register = (body) => API.post(`/users/register`, body);
export const updateUser = (id, body) => API.put(`/users/${id}`, body);
export const addCompanyToUser = (id, company) =>
  API.put(`/users/${id}/addCompany/${company}`);
export const removeCompanyFromUser = (id, company) =>
  API.put(`/users/${id}/removeCompany/${company}`);
export const updateUserServices = (id, services) =>
  API.put(`/users/${id}/updateServices/`, services);
export const updateUserWorkTypes = (id, serviceId, workTypes) =>
  API.put(`/users/${id}/service/${serviceId}/updateWorkTypes/`, workTypes);
export const getCompaniesByUserId = (id) => API.get(`/users/${id}/companies`);
export const getServicesByUserId = (id) => API.get(`/users/${id}/services`);
export const getAllFilters = (serviceId) => {
  return API.get(`/services/${serviceId}/filters`);
};
export const updateUserServiceIcon = (data) =>
  API.put("/users/updateServiceIcon", data);
