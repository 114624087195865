import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { failure, success } from "../../../../utils/notification";
import { signUp } from "../../../../redux/api/auth.api";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

const TermsAndConditions = ({
  open,
  setOpen,
  formData,
  setFormData,
  handleChange,
  handleNext,
}) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isScrollBottom, setIsScrollBottom] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false); // This would be set to true when the content has loaded
  const termsRef = useRef(null);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, [open]);

  useEffect(() => {
    if (open)
      setTimeout(() => {
        setContentLoaded(true); // You would set this based on actual content load completion
      }, 3000); // Example delay
  }, [open]);
  useEffect(() => {
    if (contentLoaded && termsRef.current) {
      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = termsRef.current;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight;
        setIsScrollBottom(isAtBottom);
      };

      termsRef.current.addEventListener("scroll", handleScroll);

      handleScroll(); // Perform an initial check

      return () => {
        if (termsRef.current) {
          termsRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [contentLoaded]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const body = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        type: formData.type,
        plan: formData.plan,
        company: formData.type === "business" ? formData?.company : "",
        companyName: formData.type === "business" ? formData?.companyName : "",
        website: formData.type === "business" ? formData?.website : "",
      };

      const registrationResult = await signUp(body);
      if (registrationResult?.status === 200) {
        if (formData?.plan === "basic") {
          success("Success", "You have successfully registered.");
          navigate("/?registration=success");
        } else {
          setFormData({
            ...formData,
            _id: registrationResult?.data?.user?._id,
            stripeCustomerId: registrationResult?.data?.user?.stripeCustomerId,
          });
          setLoading(false);
          handleNext();
        }
      }
    } catch (error) {
      failure(error?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="z-50" onClose={setOpen}>
        <Transition.Child
          enter="ease-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 transform scale-100"
          leaveTo="opacity-0 transform scale-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opacity-0 transform scale-0"
              enterTo="opacity-100 transform scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 transform scale-100"
              leaveTo="opacity-0 transform scale-0"
            >
              <Dialog.Panel className="mt-12 z-50 lg:w-1/2 md:w-1/2 w-4/5 fixed mx-auto rounded-lg bg-white pt-5 pb-4 text-left shadow-xl transition-all h-auto">
                <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="">
                  <div className=" sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="px-3 pb-5 text-xl font-medium border-b-2 border-gray-200 leading-6 text-gray-700"
                    >
                      Terms And Conditions
                    </Dialog.Title>
                    <div
                      className="h-96 overflow-y-scroll terms-content py-10"
                      ref={termsRef}
                    >
                      <div
                        name="termly-embed"
                        data-id="e600bcc3-c8df-42fc-9ab9-b89a15d947e2"
                        data-type="iframe"
                        id="termly-embed"
                      ></div>
                    </div>

                    <form
                      onSubmit={handleSubmit}
                      className="border-t-2 border-gray-200 px-7 pt-3 flex items-center justify-between"
                    >
                      <div>
                        <p className="text-md font-base text-gray-600">
                          Scroll to bottom to continue
                        </p>
                      </div>
                      <button
                        type="submit"
                        disabled={!isScrollBottom || loading}
                        className={`flex justify-center rounded-md px-5 py-2 w-48 text-base font-semibold leading-6 text-white shadow-sm bg-green-600 hover:bg-opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 ${
                          !isScrollBottom || loading ? "opacity-90 cursor-not-allowed" : ""
                        }`}
                      >
                        {loading ? (
                          <RotatingLines
                            strokeColor="green"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="24"
                            visible={true}
                          />
                        ) : (
                          "Accept and Continue"
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default TermsAndConditions;
