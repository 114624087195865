import React, { useEffect, useState } from "react";
import AddAppearanceSetting from "./AddAppearanceSetting";
import {
  deleteAppearanceSetting,
  getAppearanceSettingsForCompany,
} from "../../../redux/api/appearanceSettings.api";
import { baseURL } from "../../../redux/api/api";
import { confirmation, failure } from "../../../utils/notification";
import { useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner";

const AppearanceSettings = () => {
  const auth = useSelector((state) => state.authReducer);

  const [appearanceSettingPopupOpen, setAppearanceSettingPopupOpen] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [appearanceSettings, setAppearanceSettings] = useState([]);

  const getAppearanceSettings = () => {
    if (auth?.user?.company?._id) {
      getAppearanceSettingsForCompany(auth?.user?.company?._id)
        .then((response) => {
          setAppearanceSettings(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    getAppearanceSettings();
  }, [auth?.user?.company?._id]);

  const handleDeleteAppearanceSetting = (id) => {
    confirmation(
      "Delete Appearance Setting",
      "Are you sure you want to delete this appearance setting?"
    ).then((result) => {
      if (result.isConfirmed) {
        deleteAppearanceSetting(id)
          .then(() => {
            setAppearanceSettings((settings) =>
              settings.filter((setting) => setting._id !== id)
            );
          })
          .catch(() => {
            failure("Failed to delete appearance setting");
          });
      }
    });
  };
  return (
    <>
      <AddAppearanceSetting
        open={appearanceSettingPopupOpen}
        setOpen={setAppearanceSettingPopupOpen}
        getAppearanceSettings={getAppearanceSettings}
      />
      <div className="bg-white rounded-lg shadow">
        <div className="border-b border-gray-200 rounded-t-lg bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Appearance Settings
              </h3>
            </div>
            <div className="flex items-center  mt-2 flex-shrink-0">
              <button
                type="button"
                onClick={() => setAppearanceSettingPopupOpen(true)}
                className="relative inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Add New
              </button>
            </div>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6 relative">
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <RotatingLines
                className="absolute inset-0 m-auto"
                color="#000"
                height={50}
                width={50}
              />
            </div>
          ) : appearanceSettings?.length === 0 ? (
            <div className="flex justify-center items-center h-64">
              <span className="text-gray-600">
                No appearance settings found
              </span>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {appearanceSettings.map((setting) => (
                <div
                  className="bg-white rounded-lg shadow-lg p-6 relative"
                  key={setting._id}
                >
                  <div className="flex justify-between items-center mb-4">
                    <div className="flex space-x-2 items-baseline">
                      <h2 className="text-xl font-bold text-gray-800">
                        {setting?.name || "Setting Name"}
                      </h2>
                      {setting?.isDefault && (
                        <span className="text-green-600 font-semibold text-xs">
                          (Default)
                        </span>
                      )}
                    </div>
                    <div className="flex space-x-2">
                      <button
                        className="text-red-600 hover:text-red-700 font-medium transition-colors"
                        onClick={() => {
                          handleDeleteAppearanceSetting(setting._id);
                        }}
                        type="button"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  <div className="mb-4">
                    <h3 className="font-semibold text-gray-700">
                      Map Cluster Colour:
                    </h3>
                    <div className="flex items-center space-x-2">
                      <div
                        className="w-8 h-8 rounded-full border-2 border-gray-300"
                        style={{ backgroundColor: setting.mapClusterColour }}
                      ></div>
                      <span className="text-gray-700">
                        {setting.mapClusterColour}
                      </span>
                    </div>
                  </div>
                  <div className="mb-4">
                    <h3 className="font-semibold text-gray-700">
                      Text Colour:
                    </h3>
                    <div className="flex items-center space-x-2">
                      <div
                        className="w-8 h-8 rounded-full border-2 border-gray-300"
                        style={{ backgroundColor: setting.textColour }}
                      ></div>
                      <span className="text-gray-700">
                        {setting.textColour}
                      </span>
                    </div>
                  </div>
                  <div className="mb-4">
                    <h3 className="font-semibold text-gray-700">
                      Office Marker Icon:
                    </h3>
                    <img
                      src={`${baseURL}s3/images/${setting.officeMarkerIcon}`}
                      alt="Office Marker Icon"
                      className="w-12 h-12 mb-2 border rounded-lg shadow-md"
                    />
                  </div>
                  <div className="flex space-x-2">
                    <h3 className="font-semibold text-gray-700">
                      Service Icons:
                    </h3>
                    <div className="flex space-x-2">
                      {setting.serviceIcons.map((serviceIcon) => (
                        <div
                          key={serviceIcon.serviceId}
                          className="flex flex-col items-center"
                        >
                          <img
                            src={`${baseURL}s3/images/${serviceIcon.icon}`}
                            alt="Service Icon"
                            className="w-10 h-10 border rounded-lg shadow-md"
                          />
                          <span className="text-sm text-gray-600">
                            {serviceIcon.serviceName}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AppearanceSettings;
