import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useState } from "react";
import { createService } from "../../../../../redux/api";
import { failure, success } from "../../../../../utils/notification";
import { createServiceRequest } from "../../../../../redux/api/serviceRequests.api";
import { RotatingLines } from "react-loader-spinner";

const initialData = {
  name: "",
};
const RequestNewService = ({ open, setOpen, onRequestClose }) => {
  const [newService, setNewService] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewService({
      ...newService,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    createServiceRequest(newService)
      .then((res) => {
        setTimeout(() => {
          setNewService(initialData);
          success(
            "Success",
            "Your request for new service has been received. We'll review it and get back to you soon."
          );
          setLoading(false);
          setOpen(false);
        }, 10000);
      })
      .catch((err) => {
        failure("Error", err?.response?.data?.message);
        console.log("Error is", err);
        setLoading(false);
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className=" z-50" onClose={setOpen}>
        <Transition.Child
          enter="ease-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 transform scale-100"
          leaveTo="opacity-0 transform scale-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="w-full fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opacity-0 transform scale-0"
              enterTo="opacity-100 transform scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 transform scale-100"
              leaveTo="opacity-0 transform scale-0"
            >
              <Dialog.Panel className="mt-12 z-50 fixed mx-auto rounded-lg bg-white pt-5 pb-4 text-left shadow-xl transition-all">
                <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => {
                      setOpen(false); // Close the current popup
                      onRequestClose(); // Open the UpdateServicesPopup
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="lg:w-[35rem] md:w-[30rem] w-[22rem]">
                  <div className=" sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="px-3 pb-5 text-xl font-medium border-b-2 border-gray-200 leading-6 text-gray-700"
                    >
                      Request a new Service
                    </Dialog.Title>
                    <div className="">
                      <form onSubmit={handleSubmit} method="POST">
                        <div className="">
                          <div className="px-7 py-4">
                            <div className="py-3 ">
                              <div className="py-0.5 items-center grid grid-cols-12">
                                <div className="lg:col-span-12 md:col-span-12 col-span-12 ">
                                  <label
                                    htmlFor="name"
                                    className="block text-base font-semibold leading-6 text-gray-800"
                                  >
                                    Service Name
                                  </label>
                                </div>
                                <div className="lg:col-span-12 md:col-span-12 col-span-12 ">
                                  <div className="mt-2">
                                    <input
                                      tabIndex={1}
                                      type="text"
                                      name="name"
                                      placeholder="Name"
                                      value={newService.name}
                                      onChange={handleChange}
                                      id="name"
                                      className="w-full p-2 mb-4 border-2 border-green-600 focus:border-green-600 focus:ring-0 rounded-md static"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border-t-2 border-gray-200 px-7 pt-3 flex items-center justify-end">
                          <button
                            type="button"
                            className="mx-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-1.5 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            disabled={loading}
                            className={`w-24 ${
                              loading
                                ? "bg-green-700 hover:bg-green-700 cursor-not-allowed"
                                : ""
                            } flex justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600`}
                          >
                            {loading ? (
                              <div className="flex justify-center items-center">
                                <RotatingLines
                                  strokeColor="white"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  width="24"
                                  visible={true}
                                />
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default RequestNewService;
