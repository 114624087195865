import React, { useEffect, useState } from "react";
import AddOption from "../pages/Projects/AddOption";
const ViewFields = ({
  newOptions,
  setNewOptions,
  fields,
  setFields = null,
  setField = null,
  setFieldId,
  projectData = {},
  setProjectData = null,
  getEquipmentModelsForBrand,
  selectedField,
  setSelectedField,
  selectedBrand,
  setSelectedBrand,
  equipmentBrandsForModel,
  addOptionPopupOpen,
  setAddOptionPopupOpen,
}) => {
  const handleChange = async (e) => {
    if (setProjectData)
      setProjectData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    if (e.target.value === "") return;

    const currentField = fields?.find((field) => field?.name === e.target.name);

    if (currentField?.module === "equipmentBrands") {
      getEquipmentModelsForBrand(e.target.value, currentField);
    }
  };

  const checkIfBrandIsSelected = (field) => {
    if (field?.module === "equipmentModels") {
      const brandField = fields?.find(
        (f) =>
          f?.type === "select" &&
          f?.module === "equipmentBrands" &&
          f?.filter === field?.filter
      );
      if (brandField) {
        return projectData[brandField?.name] === ""
          ? false
          : projectData[brandField?.name] === undefined
          ? false
          : true;
      } else {
        return true;
      }
    }
  };

  return (
    <>
      <AddOption
        open={addOptionPopupOpen}
        setOpen={setAddOptionPopupOpen}
        field={selectedField}
        setFields={setFields}
        newOptions={newOptions}
        setNewOptions={setNewOptions}
        selectedBrand={selectedBrand}
        setSelectedBrand={setSelectedBrand}
        equipmentBrandsForModel={equipmentBrandsForModel}
      />
      <div className="px-4 sm:p-6 py-5 my-5 border-t border-b border-gray-300">
        <div className="grid grid-cols-12 lg:gap-3 md:gap-y-3 md:gap-x-6 gap-3">
          {fields?.length > 0 ? (
            fields?.map((field, index) => (
              <div key={index} className="col-span-12 lg:col-span-6">
                {field?.type === "textarea" ? (
                  <>
                    <label
                      htmlFor={field?.name}
                      className="block font-medium leading-6 text-gray-900"
                    >
                      {field?.label}
                      {field?.required && (
                        <span className="text-red-600"> *</span>
                      )}
                    </label>
                    <textarea
                      rows={4}
                      name={field?.name}
                      value={
                        Object.keys(projectData).length > 0
                          ? projectData[field?.name]
                          : ""
                      }
                      onChange={handleChange}
                      label={field?.label}
                      autoFocus
                      fullWidth
                      required={field?.required}
                      className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                    ></textarea>
                  </>
                ) : field?.type === "select" ? (
                  <>
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor={field?.name}
                        className="block font-medium leading-6 text-gray-900"
                      >
                        {field?.label}
                        {field?.required && (
                          <span className="text-red-600"> *</span>
                        )}
                        {field?.module === "equipmentModels" &&
                          !checkIfBrandIsSelected(field) && (
                            <span className="text-red-500 text-xs">
                              {" "}
                              (Select Brand First)
                            </span>
                          )}
                      </label>
                      {field?.allowOptionsByUsers === true && (
                        <button
                          type="button"
                          className="inline-flex items-center px-2.5 py-1 border border-transparent text-xs font-medium rounded text-green-600 hover:text-white bg-green-100 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                          onClick={() => {
                            setSelectedField(field);
                            setAddOptionPopupOpen(true);
                          }}
                        >
                          Add New
                        </button>
                      )}
                    </div>
                    <div className="mt-2">
                      <select
                        name={field?.name}
                        id={field?.name}
                        value={
                          Object.keys(projectData).length > 0
                            ? projectData[field?.name]
                            : ""
                        }
                        onChange={handleChange}
                        className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                        required={field?.required}
                      >
                        <option value="">
                          Select{" "}
                          {field?.label.replace(/([a-z])([A-Z])/g, "$1 $2")}
                        </option>
                        {field?.options?.map((option, index) => (
                          <option
                            key={index}
                            value={
                              option._id === undefined ? option : option._id
                            }
                          >
                            {option.name === undefined ? option : option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                ) : field?.type === "measurement" ? (
                  <div className="col-span-12 lg:col-span-6">
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor={`${field?.name}_value`}
                        className="block font-medium leading-6 text-gray-900"
                      >
                        {field?.label}
                        {field?.required && (
                          <span className="text-red-600"> *</span>
                        )}
                      </label>
                    </div>
                    <div className="flex items-center gap-3 mt-2">
                      <input
                        type="number"
                        name={`${field?.name}_value`}
                        id={`${field?.name}_value`}
                        value={
                          projectData ? projectData[`${field?.name}_value`] : ""
                        }
                        onChange={handleChange}
                        autoComplete="off"
                        placeholder={field?.label}
                        className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                        required={field?.required}
                      />
                      <select
                        name={`${field?.name}_unit`}
                        id={`${field?.name}_unit`}
                        value={
                          Object.keys(projectData).length > 0
                            ? projectData[`${field?.name}_unit`]
                            : ""
                        }
                        onChange={handleChange}
                        className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                        required={field?.required}
                      >
                        <option value="">
                          Select{" "}
                          {field?.label.replace(/([a-z])([A-Z])/g, "$1 $2")}
                        </option>
                        {field?.options?.map((option, index) => (
                          <option
                            key={index}
                            value={
                              option._id === undefined ? option : option._id
                            }
                          >
                            {option.name === undefined ? option : option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : field?.type === "date" ? (
                  <>
                    <label
                      htmlFor={field?.name}
                      className="block font-medium leading-6 text-gray-900"
                    >
                      {field?.label}
                      {field?.required && (
                        <span className="text-red-600"> *</span>
                      )}
                    </label>
                    <div className="mt-2">
                      <input
                        type={field?.type}
                        name={field?.name}
                        id={field?.name}
                        value={projectData ? projectData[field?.name] : ""}
                        onChange={handleChange}
                        autoComplete="off"
                        placeholder={field?.label}
                        className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                        required={field?.required}
                      />
                    </div>
                  </>
                ) : (
                  <div className="col-span-12 lg:col-span-6">
                    <label
                      htmlFor={field?.name}
                      className="block font-medium leading-6 text-gray-900"
                    >
                      {field?.label}
                      {field?.required && (
                        <span className="text-red-600"> *</span>
                      )}
                    </label>
                    <div className="mt-2">
                      <input
                        type={field?.type}
                        name={field?.name}
                        id={field?.name}
                        value={projectData ? projectData[field?.name] : ""}
                        onChange={handleChange}
                        autoComplete="off"
                        placeholder={field?.label}
                        className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                        required={field?.required}
                      />
                    </div>
                  </div>
                )}
                {setField && (
                  <div style={{ gap: "0.1rem" }}>
                    {/* <EditIcon
                    color="primary"
                    onClick={() => handleUpdate(index)}
                  />
                  <DeleteIcon
                    color="primary"
                    onClick={() => handleDelete(index)}
                  /> */}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="flex justify-center items-center col-span-12">
              <h1 className="text-2xl text-gray-400">
                Please select a service and project type
              </h1>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewFields;
