import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Table from "../../components/Table";
import { getPartnersPageResponse } from "../../../redux/api/partnersPageResponse.api";
import { getCurrentUser } from "../../../redux/actions/auth";

const formatCurrency = (amount) => {
  if (typeof amount !== "number") {
    amount = parseFloat(amount) || 0;
  }
  return `$${amount.toFixed(2)}`;
};

const columns = [
  {
    header: "Date Delivered",
    render: (row) => row?.deliveredDate.split("T")[0],
    sortable: false,
  },
  {
    header: "Name",
    render: (row) => `${row?.firstName} ${row?.lastName}`,
    sortable: false,
  },
  {
    header: "Email",
    render: (row) => row?.customerEmail,
    sortable: false,
  },
  {
    header: "Phone Number",
    render: (row) => row?.phoneNo,
    sortable: false,
  },
  {
    header: "Address",
    render: (row) => row?.address,
    sortable: false,
  },
  {
    header: "Lead Cost",
    render: (row) => formatCurrency(row?.leadCost),
    sortable: false,
  },
];
const PartnersPageResponses = () => {
  const auth = useSelector((state) => state.authReducer);
  // console.log(auth?.user?.company?.advertise);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [count, setCount] = useState(0);

  const pageCount = Math.ceil(count / itemsPerPage);

  useEffect(() => {
    if (auth?.user?.company?._id) {
      setLoading(true);
      getPartnersPageResponse(auth?.user?.company?._id, {
        limit: itemsPerPage,
        skip: itemOffset,
      }).then((res) => {
        setLoading(false);
        setData(res.data.partnersResponses);
        setCount(res.data.totalCount);
      });
    }
  }, [auth?.user?._id, itemOffset, itemsPerPage]);
  return (
    <>
      <div className="bg-white rounded-lg shadow">
        <div className="border-b border-gray-200 rounded-t-lg bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Leads
              </h3>
            </div>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <Table
            pagination={true}
            loading={loading}
            columns={columns}
            pageCount={pageCount}
            setItemOffset={setItemOffset}
            data={data}
            dataCount={count}
            itemsPerPage={itemsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default PartnersPageResponses;
