import { UserCircleIcon, UserIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { BiCloudUpload } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../../../redux/api/api";
import {
  getCompaniesByUserId,
  getServicesByUserId,
  updateUser,
} from "../../../../redux/api/user.api";
import AddCompanyPopup from "./Components/AddCompanyPopup";
import CompanyCard from "./Components/CompanyCard";
import UpdateServicesPopup from "./Components/UpdateServicesPopup";
import UpdateWorkTypesPopup from "./Components/UpdateWorkTypesPopup";
import { RotatingLines } from "react-loader-spinner";
import { failure, success, warning } from "../../../../utils/notification";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const initialData = {
  profileImage: "",
  firstName: "",
  lastName: "",
  title: "",
  email: "",
  phoneNo: "",
};

const IndividualProfile = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);

  const [user, setUser] = useState(initialData);
  const [companies, setCompanies] = useState([]);
  const [services, setServices] = useState([]);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [addCompanyPopupOpen, setAddCompanyPopupOpen] = useState(false);
  const [updateServicesPopupOpen, setUpdateServicesPopupOpen] = useState(false);
  const [updateWorkTypesPopupOpen, setUpdateWorkTypesPopupOpen] =
    useState(false);
  const [serviceToUpdate, setServiceToUpdate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auth?.user) {
      setUser({
        ...user,
        profileImage: auth?.user?.profileImage,
        firstName: auth?.user?.firstName,
        lastName: auth?.user?.lastName,
        title: auth?.user?.title,
        email: auth?.user?.email,
        phoneNo: auth?.user?.phoneNo || "",
      });
      getCompanies();
      getServices();
    }
  }, [auth]);
  const getCompanies = () => {
    getCompaniesByUserId(auth?.user?._id)
      .then((res) => {
        setCompanies(res?.data?.companies);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getServices = () => {
    getServicesByUserId(auth?.user?._id)
      .then((res) => {
        console.log(res);
        setServices(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "profileImage") {
      setUser({ ...user, [name]: e.target.files[0] });
      setProfileImagePreview(URL.createObjectURL(e.target.files[0]));
      return;
    }
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("profileImage", user.profileImage);
    formData.append("firstName", user.firstName);
    formData.append("lastName", user.lastName);
    formData.append("title", user.title);
    formData.append("email", user.email);
    formData.append("phoneNo", user.phoneNo);

    updateUser(auth?.user?._id, formData)
      .then((res) => {
        success("Success", "Profile updated successfully");
        setLoading(false);
      })
      .catch((err) => {
        failure(err?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <>
      <AddCompanyPopup
        open={addCompanyPopupOpen}
        setOpen={setAddCompanyPopupOpen}
        getCompanies={getCompanies}
      />
      <UpdateServicesPopup
        open={updateServicesPopupOpen}
        setOpen={setUpdateServicesPopupOpen}
        userServices={services?.map((service) => ({
          serviceId: service?.serviceId?._id,
          workTypes: service?.workTypes?.map((workType) => workType?._id),
        }))}
        getUserServices={getServices}
      />
      <UpdateWorkTypesPopup
        open={updateWorkTypesPopupOpen}
        setOpen={setUpdateWorkTypesPopupOpen}
        service={serviceToUpdate}
        userServices={services?.map((service) => ({
          serviceId: service?.serviceId?._id,
          workTypes: service?.workTypes?.map((workType) => workType?._id),
        }))}
        getUserServices={getServices}
      />
      <div className="bg-white rounded-lg shadow mb-4">
        <form onSubmit={handleSubmit}>
          <div className="border-b border-gray-200 rounded-t-lg bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-2">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Profile
                </h3>
              </div>
              <div className="ml-4 mt-2 flex-shrink-0">
                <button
                  type="submit"
                  disabled={loading}
                  className={`relative w-24 ${
                    loading
                      ? "bg-green-700 hover:bg-green-700 cursor-not-allowed"
                      : ""
                  } justify-center inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600`}
                >
                  {loading ? (
                    <div className="flex justify-center items-center">
                      <RotatingLines
                        strokeColor="white"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="20"
                        visible={true}
                      />
                    </div>
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <div className="grid sm:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 gap-6">
              <div className="g:col-span-4 md:col-span-4 sm:col-span-12">
                <div className="mx-auto lg:mx-0 md:mx-0 relative w-40 h-40 bg-gray-100 hover:bg-gray-200 rounded-full cursor-pointer group">
                  {user?.profileImage ? (
                    <>
                      <img
                        src={
                          profileImagePreview
                            ? profileImagePreview
                            : `${baseURL}s3/images/${user?.profileImage}`
                        }
                        alt="profileImage"
                        className="object-cover absolute top-0 w-40 h-40 rounded-full"
                      />
                      <input
                        type="file"
                        name="profileImage"
                        id="profileImage"
                        accept="image/*"
                        onChange={handleChange}
                        className="w-40 h-40 opacity-0 cursor-pointer"
                      />
                    </>
                  ) : (
                    <>
                      <input
                        type="file"
                        name="profileImage"
                        id="profileImage"
                        accept="image/*"
                        onChange={handleChange}
                        className="w-40 h-40 opacity-0 cursor-pointer"
                      />
                      <label
                        htmlFor="profileImage"
                        className="w-40 h-40 cursor-pointer flex absolute top-0 items-center justify-center"
                      >
                        <UserIcon className="w-32 h-32 text-gray-400 group-hover:text-gray-500 mx-auto my-10" />
                      </label>
                    </>
                  )}
                </div>
              </div>
              {/* Input for company name */}
              <div className="lg:col-span-8 md:col-span-8 sm:col-span-12">
                <div className="mt-2">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={user?.firstName}
                      onChange={handleChange}
                      className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="First Name"
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={user?.lastName}
                      onChange={handleChange}
                      className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Title
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="title"
                      id="title"
                      value={user?.title}
                      onChange={handleChange}
                      className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Title"
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      value={user?.email}
                      onChange={handleChange}
                      className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label
                    htmlFor="phoneNo"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="phoneNo"
                      id="phoneNo"
                      value={user?.phoneNo}
                      onChange={handleChange}
                      className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Phone Number"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="bg-white rounded-lg shadow mb-4">
        <div className="border-b border-gray-200 rounded-t-lg bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Companies
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <button
                type="button"
                onClick={() => setAddCompanyPopupOpen(true)}
                className="relative inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Add Company
              </button>
            </div>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
            {companies?.map((company, i) => (
              <CompanyCard
                key={i}
                company={company}
                getCompanies={getCompanies}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="bg-white rounded-lg shadow">
        <div className="border-b border-gray-200 rounded-t-lg bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Services
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <button
                type="button"
                onClick={() => setUpdateServicesPopupOpen(true)}
                className="relative inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Edit Services
              </button>
            </div>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <ul
            role="list"
            className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
          >
            {services?.map((service, i) => (
              <li
                key={i}
                className="col-span-1 flex rounded-md shadow-sm cursor-pointer border rounded-r-md border-gray-200 p-4"
                onClick={() => {
                  setUpdateWorkTypesPopupOpen(true);
                  setServiceToUpdate(service);
                }}
              >
                <div
                  className={classNames(
                    service.bgColor,
                    "bg-green-600 flex w-12 h-12 flex-shrink-0 items-center justify-center rounded-full text-xl font-medium text-white"
                  )}
                >
                  {service?.serviceId?.name?.charAt(0)}
                </div>
                <div className="flex flex-1 justify-between truncate bg-white">
                  <div className="flex-1 truncate px-4 py-2 text-sm">
                    <h2 className="font-medium text-gray-900 hover:text-gray-600">
                      {service?.serviceId?.name}
                    </h2>
                    <ul className="flex flex-wrap mt-2">
                      {service?.workTypes?.map((workType, i) => (
                        <li
                          key={i}
                          className="text-xs bg-gray-100 text-gray-600 rounded-full px-2 py-1 mr-2 mb-2"
                        >
                          {workType?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default IndividualProfile;
