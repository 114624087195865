import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../../../redux/actions/auth";
import Input from "../../../components/Input";
import logo from "../../../../assets/images/Logo2.png";
import backgroundImage from "../../../../assets/images/signupbg.png";
import { FaStar } from "react-icons/fa";
import registerImg from "../../../../assets/images/register-bg.png";
import quotes from "../../../../assets/images/Group.png";
import { FiMail } from "react-icons/fi";
import { MdLockOutline } from "react-icons/md";
import Slider from "react-slick";
import { RotatingLines } from "react-loader-spinner";
const reviews = [
  {
    id: 1,
    stars: 5,
    text: "I Increased my close rate by 15% in the first month using Pro-Mapper.",
    name: "Wes",
    location: "Denver",
  },
  {
    id: 2,
    stars: 5,
    text: "My customers love seeing my local projects. Pro-Mapper is amazing!",
    name: "Paula",
    location: "Los Angeles",
  },
  {
    id: 3,
    stars: 5,
    text: "Pro-Mapper is easy to use and provides",
    name: "Justin",
    location: "Ohio",
  },
  {
    id: 4,
    stars: 5,
    text: "I Increased my close rate using Pro-Mapper. Highly recommend it!",
    name: "Wes",
    location: "Denver",
  },
  {
    id: 5,
    stars: 5,
    text: "My customers love seeing my local projects. Pro-Mapper is amazing! ",
    name: "Paula",
    location: "Los Angeles",
  },
];
const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const Login = () => {
  const initialData = { email: "", password: "", role: "company" };

  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(login(formData, navigate, setLoading));
    // setLoading(false);
  };

  useEffect(() => {
    localStorage.removeItem("admin");
  }, []);

  return (
    <div className="grid grid-cols-12">
      {/* <div
        // style={{ backgroundImage: `url(${backgroundImage})` }}
        className="col-span-12 lg:col-span-6 lg:rounded-none rounded-b-[5%]  sm:col-span-12 relative bg-[#0BAC59]"
      > */}
      <div
        // style={{ backgroundImage: `url(${backgroundImage})` }}
        className="bg-[#0BAC59] h-[35rem] lg:h-screen col-span-12 lg:col-span-6 lg:rounded-none rounded-b-[5%]  sm:col-span-12 relative"
      >
        <div className="absolute inset-0 flex items-center justify-center max-w-5xl mx-auto">
          <div className="w-full lg:w-2/3 text-white">
            <div className="flex items-center justify-center flex-col">
              <div className="lg:text-left text-center w-4/5 flex flex-col lg:items-start items-center justify-center">
                <div className="pt-8">
                  <img className="h-16 w-auto" src={logo} alt="Your Company" />
                </div>
                <h1 className="uppercase my-4 text-xl lg:text-2xl font-bold">
                  ACCESS YOUR ACCOUNT TO MANAGE YOUR PROJECTS
                </h1>
              </div>
              <div
                className="h-80 w-full block bg-cover bg-center bg-no-repeat rounded-b-[5%] bg-blend-multiply bg-[#0BAC59]"
                style={{ backgroundImage: `url(${registerImg})` }}
              ></div>
              <div className="w-full slider-container">
                <Slider {...settings}>
                  {reviews.map((review) => (
                    <div key={review.id} className="flex justify-center">
                      <div className="lg:block hidden w-4/5 py-2 gap-7 grid grid-cols-12">
                        <div className="lg:col-span-12 md:col-span-12 col-span-12">
                          <div className="py-3 px-3 w-full mt-3 mx-auto sm:mx-4 bg-white bg-opacity-10 rounded-lg relative border-[0.5px] border-white">
                            <div className="absolute right-0 bottom-0">
                              <img
                                className="w-full object-cover p-3"
                                src={quotes}
                                alt={review.name}
                              />
                            </div>
                            <div className="flex flex-col px-4">
                              <div className="flex mt-2">
                                {Array.from({ length: review.stars }).map(
                                  (_, index) => (
                                    <FaStar
                                      key={index}
                                      className="h-5 w-5 text-yellow-500"
                                    />
                                  )
                                )}
                              </div>
                            </div>
                            <div className="px-4 py-4">
                              <p className="text-white text-lg">
                                {review.text}
                              </p>
                            </div>
                            <div className="px-4 pt-2 relative">
                              <h5 className="text-white text-xl font-bold">
                                {review.name}
                              </h5>
                              <p className="text-white text-lg">
                                {review.location}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            {/* <img className="w-auto h-96" src={loginImg} alt="bg img" /> */}
          </div>
        </div>
      </div>
      <div className="h-auto lg:h-screen col-span-12 lg:col-span-6 md:col-span-12  ">
        <div className=" flex items-center justify-center h-3/4 md:h-[90%] lg:h-screen">
          <div className="lg:relative lg:top-0 my-auto absolute top-1/3 bg-white border border-gray-200 shadow py-16 lg:px-10 px-5 rounded-lg w-[90%] md:w-3/5 lg:w-4/6">
            <div>
              <h2 className="text-center uppercase text-2xl lg:text-3xl font-bold leading-9 tracking-tight text-gray-900">
                Sign In to your Account
              </h2>
            </div>

            <div className="mt-5">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <div className="relative mt-1.5 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <FiMail
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      value={formData.email}
                      onChange={handleChange}
                      id="email"
                      className="mb-3 outline-0 py-3 bg-gray-50 block w-full rounded-md border border-gray-100 focus:border-0 py-1.5 pl-10 text-gray-900 ring-0 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="relative mt-1.5 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MdLockOutline
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={formData.password}
                      onChange={handleChange}
                      id="password"
                      className="mb-3 outline-0 py-3 bg-gray-50 block w-full rounded-md border border-gray-100 focus:border-0 py-1.5 pl-10 text-gray-900 ring-0 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-3 block text-sm leading-6 text-gray-700"
                    >
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm leading-6">
                    <a
                      href="#"
                      className="font-semibold text-green-600 hover:text-green-500"
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className="mt-10 flex flex-col items-center justify-center w-full">
                  <button
                    type="submit"
                    disabled={loading}
                    className="flex justify-center items-center rounded-full w-48 px-10 py-2.5 font-bold text-white text-base bg-gradient-to-r from-[#D88A16] to-[#FFB800]"
                  >
                    {loading ? (
                      <RotatingLines
                        strokeColor="green"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="24"
                        visible={true}
                      />
                    ) : (
                      "Sign in"
                    )}
                  </button>
                  <p className="text-base text-gray-500 text-center mt-2.5">
                    Not a member?{" "}
                    <Link to="/pricing?type=business" className="text-green-600 font-bold">
                      Register Here
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
