import * as api from "../api";
import {
  GET_SERVICES,
  CREATE_SERVICE,
  UPDATE_SERVICE,
  DELETE_SERVICE,
} from "../types/services.types";
import { failure, success } from "../../utils/notification";

export const getServices = () => async (dispatch) => {
  try {
    const { data } = await api.getServices();
    dispatch({ type: GET_SERVICES, payload: data });
  } catch (error) {
    failure(error.message);
  }
};

export const createService = (service) => async (dispatch) => {
  try {
    const { data, status } = await api.createService(service);
    if (status === 200) {
      dispatch({ type: CREATE_SERVICE, payload: data });
      success("Saved");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const updateService = (id, service) => async (dispatch) => {
  try {
    const { data, status } = await api.updateService(id, service);
    if (status === 200) {
      dispatch({ type: UPDATE_SERVICE, payload: data });
      success("Updated");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const deleteService = (id) => async (dispatch) => {
  try {
    const { status } = await api.deleteService(id);
    if (status === 200) {
      dispatch({ type: DELETE_SERVICE, payload: id });
      success("Deleted");
    }
  } catch (error) {
    failure(error.message);
  }
};
