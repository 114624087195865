import { LOGIN, LOGOUT, REGISTER, SET_USER } from "../types/auth.types";

const initialState = {
  user: null,
  token: localStorage.getItem("token"),
  isLoggedIn: localStorage.getItem("token") ? true : false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER:
      // localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        user: action.payload.result,
        token: action.payload.token,
        isLoggedIn: true,
      };
    case LOGIN:
      // localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        user: action.payload.result,
        token: action.payload.token,
        isLoggedIn: true,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
      };
    case LOGOUT:
      // localStorage.removeItem("user");
      localStorage.removeItem("token");
      return {
        ...state,
        user: null,
        token: null,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};

export default authReducer;
