import React from "react";
import accessDeniedImage from "../../../assets/images/access-denied.png";

const InActiveSubscription = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="text-center">
        <img
          src={accessDeniedImage}
          alt="Access Denied"
          className="mx-auto w-80"
        />
        <h1 className="text-2xl font-semibold text-gray-800 mt-4">
          Access denied!
        </h1>
        <p className="text-gray-600 mt-2">
          You do not have permission to access this area of the application.
        </p>
        <p className="mt-1 text-gray-600">
          Check your subscription status on Billing page.
        </p>
      </div>
    </div>
  );
};

export default InActiveSubscription;
